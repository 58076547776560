import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  clearMapActivePoint,
  setMapActivePoint,
  setMapData,
  setMapFilter,
} from "../../../state/mapReducer"
import NetworkMobileList from "./NetworkMobileList/NetworkMobileList"
import NetworkMobileMap from "./NetworkMobileMap/NetworkMobileMap"
import NetworkMobileModal from "./NetworkMobileModal/NetworkMobileModal"
import "./NetworkMobile.css"

const NetworkMobile = ({
  lang,
  utm = "",
  map,
  title,
  setMapData,
  setMapFilter,
  setMapActivePoint,
  clearMapActivePoint,
  strapiMapData,
}) => {
  let [isMap, setIsMap] = useState(false)

  useEffect(() => {
    setMapData(strapiMapData.map_item)
  }, [])
  return (
    <div className="network-mobile">
      {map.active ? (
        <NetworkMobileModal
          toBack={clearMapActivePoint}
          activePoint={map.active}
          lang={lang}
          utm={utm}
        />
      ) : (
        <NetworkMobileList
          title={strapiMapData[`title_${lang}`]}
          pageHead={title}
          description={strapiMapData[`description_${lang}`]}
          filter={map.filter}
          activePoint={map.active}
          lang={lang}
          utm={utm}
          mapItems={map.items}
          changeActivePoint={setMapActivePoint}
          showMap={() => setIsMap(true)}
        />
      )}
      {isMap ? (
        <NetworkMobileMap
          mapItems={map.items}
          filter={map.filter}
          lang={lang}
          changeActivePoint={setMapActivePoint}
          activePoint={map.active}
          changeFilter={setMapFilter}
          closeMap={() => setIsMap(false)}
        />
      ) : null}
    </div>
  )
}

let mapStateToProps = state => ({
  map: state.map,
})

export default connect(mapStateToProps, {
  setMapData,
  setMapFilter,
  setMapActivePoint,
  clearMapActivePoint,
})(NetworkMobile)
