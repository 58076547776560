import React from "react"
import { connect } from "react-redux"
import "./CalcResult.css"
import CalcItem from "./CalcItem/CalcItem"
import productKey from "../../../../../language/productKey"

const CalcResult = ({ dedicated, lang, notAnimation }) => {
  const options = [
    "cpu",
    "ram",
    "motherboard",
    "colocations",
    "disk",
    "disk_additional",
    "raid",
    "network",
    "management",
    "bandwidth",
  ]

  let { currentItem, priceCode } = dedicated
  const getPriceStringify = value =>
    productKey[lang].price[priceCode].replace("$VALUE$", value)
  const getCurrent = item => {
    let current = {}
    options.forEach(i => {
      current[i] = item[i] && item[i].filter(i => i.current)[0]
    })
    return current
  }
  const getPrice = (current, currentItem, priceCode) => {
    const disks = currentItem.disk && currentItem.disk.filter(i => i.current)
    let price = 0
    options.forEach(i => {
      if (i === "disk") {
        disks &&
          disks.forEach(i => {
            price += i[`price_${priceCode}`]
          })
      } else {
        price += current[i] ? current[i][`price_${priceCode}`] : 0
      }
    })
    return price
  }

  const current = currentItem !== {} && getCurrent(currentItem)
  const price = currentItem !== {} && getPrice(current, currentItem, priceCode)
  const disks = currentItem.disk && currentItem.disk.filter(i => i.current)
  let discount = currentItem.discount ? (100 - currentItem.discount) / 100 : 0
  return (
    <>
      {currentItem !== {} && current.cpu && (
        <div className="calc-result">
          <h3 className="calc-result__name">{currentItem.name}</h3>
          <h4 className="calc-result__supmic">{currentItem.supmic}</h4>
          <ul className="calc-result__list">
            <CalcItem
              icon="cpu"
              name={current.cpu.name}
              subname={current.cpu.model}
              notAnimation={notAnimation}
            />
            <CalcItem
              icon="memory"
              name={`${current.ram.amount} ${
                productKey[lang].units[current.ram.unit]
              }`}
              subname={productKey[lang][current.ram.ddr]}
              notAnimation={notAnimation}
            />
            {disks.length > 0 ? (
              disks.map((i, n) => (
                <CalcItem
                  key={n}
                  icon="ssd"
                  name={`${i.amount} ${productKey[lang].units[i.unit]}`}
                  subname={`${i.model} ${productKey[lang][i.type]}`}
                  notAnimation={notAnimation}
                />
              ))
            ) : (
              <CalcItem
                icon="ssd"
                name={productKey[lang].without_disk}
                subname={""}
                error={true}
                notAnimation={notAnimation}
              />
            )}
            {current.disk_additional && (
              <CalcItem
                icon="ssd"
                name={`${current.disk_additional.amount} ${
                  productKey[lang].units[current.disk_additional.unit]
                }`}
                subname={`${current.disk_additional.model} ${
                  productKey[lang][current.disk_additional.type]
                }`}
                notAnimation={notAnimation}
              />
            )}
            <CalcItem
              icon="raid"
              name={
                current.raid.type && current.raid.model
                  ? current.raid.type
                  : productKey[lang].without_raid
              }
              subname={current.raid.model}
              notAnimation={notAnimation}
            />
            <CalcItem
              icon="admin"
              name={current.management[`name_${lang}`]}
              subname={current.management.day}
              notAnimation={notAnimation}
            />
            <CalcItem
              icon="network_card"
              name={`${current.network.amount} ${
                productKey[lang].units[current.network.unit]
              }`}
              subname={current.network.name}
              notAnimation={notAnimation}
            />
            <CalcItem
              icon="traffic"
              name={`${current.bandwidth.speed} ${
                productKey[lang].units[current.bandwidth.unit]
              }`}
              subname={""}
              notAnimation={notAnimation}
            />
            <CalcItem
              icon="country"
              name={productKey[lang].country[priceCode]}
              subname={""}
              notAnimation={notAnimation}
            />
          </ul>
          <p className="calc-result__price">
            {getPriceStringify(discount ? price * discount : price)}
            {productKey[lang].per_month}
          </p>
        </div>
      )}
    </>
  )
}

let mapStateToProps = state => ({ dedicated: state.dedicated })

export default connect(mapStateToProps)(CalcResult)
