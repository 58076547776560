import React from "react";
import GlobalStyle from "../GlobalStyle/GlobalStyle";
import Utm from "../Utm/Utm";
import ScrollTop from "../ScrollTop/ScrollTop";
import LayoutPage from "../LayoutPage/LayoutPage";
import BlogListFilter from "../Blog/BlogList/BlogListFilter/BlogListFilter";
import BlogListPagination from "../Blog/BlogList/BlogListPagination/BlogListPagination";
import BlogRowGridItem from "../PageContent/BlogRow/BlogRowGrid/BlogRowGridItem";
import Seo from "../Seo/Seo";
import Hero from "../Header/Hero/Hero";

const BlogListTemplate = ({ lang, slug, data, utm = "", params, navigate, seo, title, hero, isSubscribe }) => {

  const convertSearchQuery = (query) => {
    return query.split('-').join(' ').toLowerCase()
  }
  const searchFunc = (obj, searchQuery) => {
    for (let key in obj) {
      if ( obj.hasOwnProperty(key) && typeof(obj[key]) !== "object") {
        if (obj[key].toString().toLowerCase().match(convertSearchQuery(searchQuery))) {
          return true
        }
      }
    }
  }

  const pageLimit = 9; //count of blogs of page
  const filterBlog = (blogs, params) => {
    let filteredBlog = []
    filteredBlog = blogs.filter(
      (blog) =>
        (params.get("is-top") === "true" ? blog.node.is_top : true) &&
        (params.get("category")
          ? params.get("category") === blog.node?.category?.slug
          : true)

    )
    if (!params.get("search")) {
      return filteredBlog;
    } else {
      return filteredBlog.filter( i => searchFunc(i.node, params.get("search")))
    }

  }

  const blogs = filterBlog(data.allStrapiBlog.edges, params);

  const currentPage = params.get("page") ? Number(params.get("page")) : 1;

  return (
    <>
      <GlobalStyle />
      <Utm />
      <ScrollTop lang={lang} />
      <LayoutPage lang={lang} slug={slug} utm={utm} data={data} type={true} isSubscribe={isSubscribe}>
        <Seo
          lang={lang}
          seo={seo}
          slug={slug}
          defaultTitle={title}
          data={data}
        />
        {hero && <Hero lang={lang} hero={hero} utm={utm} />}
        <BlogListFilter
          categories={data.allStrapiBlogCategory.edges}
          lang={lang}
          params={params}
          navigate={navigate}
          blogs={blogs}
        />
        <div className="container">
          <div className="blog-row-grid">
            {blogs
              .slice(
                (currentPage - 1) * pageLimit,
                (currentPage - 1) * pageLimit + pageLimit
              )
              .map((i) => (
                <BlogRowGridItem key={i.node.slug} data={i.node} lang={lang} />
              ))}
          </div>
        </div>

        <BlogListPagination
          params={params}
          blogs={blogs}
          currentPage={currentPage}
          pageLimit={pageLimit}
        />
      </LayoutPage>
    </>
  );
};

export default BlogListTemplate;
