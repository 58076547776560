import React from "react";
import "./CItem.css";
import COption from "./COption/COption";
import productKey from "../../../../../language/productKey";

const CItem = ({ item, lang, utm = "" }) => {
  const getPriceStringify = (value) =>
    productKey[lang].price.us.replace("$VALUE$", value);

  let forHour = item.price / 672;
  return (
    <li className="c-group__item c-item">
      <h3>{item.name}</h3>
      <ul className="c-item__feature-list">
        <COption icon="cpu" name={item.cpu_name} subname="" />
        <COption
          icon="memory"
          name={`${item.ram_amount} ${productKey[lang].units[item.ram_unit]}`}
          subname={item.ram_name}
        />
        <COption
          icon="ssd"
          name={`${item.disk_amount} ${productKey[lang].units[item.disk_unit]}`}
          subname={`${item.disk_model} ${productKey[lang][item.disk_type]}`}
        />
        <COption
          icon="traffic"
          name={`${item.bandwidth_amount} ${
            productKey[lang].units[item.bandwidth_unit]
          }`}
          subname={item[`bandwidth_name_${lang}`]}
        />
      </ul>
      <div className="c-item__price">
        {getPriceStringify(item.price)}
        {productKey[lang].per_month}
      </div>
      <div className="c-item__sub-price">
        ≈{getPriceStringify(forHour.toFixed(4))}
        {productKey[lang].per_hour}
      </div>
      <a
        href={
          item.product_url.indexOf("?") < 0
            ? item.product_url + utm
            : item.product_url + utm.replace("?", "&")
        }
        className="c-item__button"
        target="_blank"
        rel="noreferrer noopener"
      >
        {productKey[lang].get}
      </a>
    </li>
  );
};

export default CItem;
