import React from "react"
import "./MapFilter.css"
import mapKey from "../../../language/mapKey"

const MapFilter = ({ filter, changeFilter, lang }) => {
  return (
    <>
      {filter.options.length > 1 ? (
        <ul className="map-filter">
          <li
            className="map-filter__item map-filter__item--all "
            style={{
              "--filter-active":
                filter.options.findIndex(i => i === filter.value) + 1,
            }}
          >
            <button
              className="map-filter__button"
              onClick={() => changeFilter(false)}
            >
              {mapKey[lang].all}
            </button>
          </li>
          {filter.options.map(i => (
            <li key={i} className="map-filter__item">
              <button
                className="map-filter__button"
                onClick={() => changeFilter(i)}
              >
                {mapKey[lang][i]}
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </>
  )
}

export default MapFilter
