import React from "react"
import BreadcrumbsNesting from "../../Breadcrumbs/BreadcrumbsNesting"

const WikiBreadcrumbs = ({ lang, currentSlug, utm = "", data }) => {
  const { allStrapiWiki } = data
  const getWikiSlug = (item, newSlug) => {
    newSlug = `/${item.slug}${newSlug}`
    return item.head
      ? getWikiSlug(
          allStrapiWiki.edges.filter(i => i.node.slug === item.head.slug)[0]
            .node,
          newSlug
        )
      : newSlug
  }
  const getWikiNestingList = (item, newList) => {
    newList = [
      {
        name_ru: item.title_ru,
        name_en: item.title_en,
        slug: `wiki${getWikiSlug(item, "")}`,
        current: item.slug === currentSlug,
      },
      ...newList,
    ]
    return item.head
      ? getWikiNestingList(
          allStrapiWiki.edges.filter(i => i.node.slug === item.head.slug)[0]
            .node,
          newList
        )
      : newList
  }

  return (
    <BreadcrumbsNesting
      lang={lang}
      utm={utm}
      nestingList={getWikiNestingList(
        allStrapiWiki.edges.filter(i => i.node.slug === currentSlug)[0].node,
        []
      )}
    />
  )
}

export default WikiBreadcrumbs
