import React, {useEffect} from "react"
import Icon from "../components/Sprite/Icon";
import Sprite from "../components/Sprite/Sprite";

const Icons = () => {

  let iconsList = [
    "servers",
    "vds_vps",
    "colocation",
    "static_cdn",
    "video_cdn",
    "domains",
    "admin",
    "ip_transit",
    "dns",
    "speedy",
    "flexibility",
    "stability",
    "snapshots",
    "root",
    "panel",
    "support",
    "antihotlink",
    "variety",
    "ssl",
    "private_network",
    "private_cloud",
    "volumes",
    "api",
  ].sort()

  let technologies = [
    "docker_logo",
    "linux_logo",
    "confluence_logo",
    "gitlab_logo",
    "jira_logo",
    "kubernetes_logo",
    "terraform_logo",
    "ansible_logo",
  ].sort()

  let newArr = [
    "channel_new",
    "cloud_new",
    "cpu_new",
    "custom_new",
    "support_new",
    "virtual_new",
    "api_new",
    "anycast_ip_new",
    "backup_new",
    "firewall_new",
    "floating_ip_new",
    "hdd_new",
    "statistics_new",
    "internal_networks_new",
    "anycast_dns",
    "caching_rules",
    "cdn_storage",
    "costs_and_bills",
    "encrypt",
    "instant_setup",
    "integration_cms",
    "manage_cdn",
    "optimization",
    "origin_protection",
    "origin_shield",
    "protection_hotlink",
    "pure_cache",
    "reliable_api",
    "support_ssl",
    "support_24",
    "uniform_price",
    "start_speed",
    "ssl_certificate",
    "speed_control",
    "speed",
    "reliable_network",
    "reliability",
    "protection_levels",
    "format_support",
    "cdn_cloud",
    "balance",
    "api_control_panel",
    "autonomous_cod",
    "traffic_charge",
    "availability",
    "professional_support",
    "integration_cdn",
    "openstack_swift",
    "protocol_s3",
    "fast_reading_cod",
    "network_infrastructure",
    "datacenter_channel",
    "private_racks",
    "local_networks",
    "client_subnet",
  ]

  let gradient = [
    "graph_gradient",
    "domains_gradient",
    "customers_gradient",
    "dns_gradient",
    "storage_gradient",
    "all_world_gradient",
    "clients_gradient",
    "global_cdn_gradient",
    "statistics_gradient",
    "piggy_bank_gradient",
    "calendar_gradient",
    "cost_gradient",
    "reorganization_gradient",
    "technical_solutions_gradient",
    "product_company_gradient",
  ].sort()

  return (
    <div>

      <ul style={{display: "flex", flexWrap: "wrap", padding: "20px"}}>
        {technologies.map((item, n) =>
          <li  key={n} style={{width: "calc(100% / 6)", marginBottom: "20px", marginRight: "10px"}}>
            <Icon height={34} width={34} iconId={item}/> - {item}
          </li>
        )}
      </ul>
      <hr/>
      <ul style={{display: "flex", flexWrap: "wrap", padding: "20px"}}>
        {gradient.map((item, n) =>
          <li  key={n} style={{width: "calc(100% / 6)", marginBottom: "20px", marginRight: "10px"}}>
            <Icon height={34} width={34} iconId={item}/> - {item}
          </li>
        )}
      </ul>
      <hr/>
      <ul style={{display: "flex", flexWrap: "wrap", padding: "20px"}}>
        {newArr.map((item, n) =>
          <li  key={n} style={{width: "calc(100% / 6)", marginBottom: "20px", marginRight: "10px"}}>
            <Icon height={34} width={34} iconId={item}/> - {item}
          </li>
        )}
      </ul>
      <hr/>
      <ul style={{display: "flex", flexWrap: "wrap", padding: "20px"}}>
        {iconsList.map((item, n) =>
          <li key={n} style={{width: "calc(100% / 6)", marginBottom: "20px", marginRight: "10px"}}>
            <Icon height={34} width={34} iconId={item}/> - {item}
          </li>
        )}
      </ul>

      <Sprite/>
    </div>
  )
}

export default Icons
