import React from "react"
import Icon from "../Sprite/Icon"
import { Link } from "gatsby"
import Responsive from "../Responsive/Responsive"
import "./Features.css"

const Feature = ({
  title,
  description,
  iconId,
  link = "",
  utm = "",
  arrow = false,
}) => {
  return (
    <article className={"feature"}>
      {link ? (
        <Link className={"title feature__link"} to={link + utm}>
          {title}
          <Icon
            iconId={iconId}
            classes={"feature__icon"}
            width={48}
            height={48}
          />
          <Icon
            iconId="arrow"
            classes={"feature__link-icon"}
            width={24}
            height={24}
          />
        </Link>
      ) : (
        <h3 className={"title feature__title"}>
          {title}
          <Icon
            iconId={iconId}
            classes={"feature__icon"}
            width={48}
            height={48}
          />
        </h3>
      )}
      <Responsive options={["desktop", "tab"]}>
        <p className={"text feature__description"}>{description}</p>
      </Responsive>
    </article>
  )
}

export default Feature
