import React from 'react';
import './InfoBlock.css';
import ReactMarkdown from "react-markdown";
import Img from "gatsby-image";

const InfoBlock = ({body, lang}) => {
  return (
   <div className="info-block">
     <div className="container">
       <h2 className="info-block__title">
         {body[`title_${lang}`]}
       </h2>
       <div className="info-block__body">
         <div className="info-block__content">
           <ReactMarkdown
             source={body[`content_${lang}`]}
           />
         </div>
         <Img className="info-block__image" fluid={body.image.childImageSharp.fluid} />
       </div>
     </div>
   </div>
  )
};

export default InfoBlock;