import React from "react";
import Img from "gatsby-image";
import Link from "gatsby-link";
import blogKey from "../../../../language/blogKey";

const BlogRowSliderItem = ({ data, lang }) => {
  return (
    <div className="blog-row-slide__body">
      <div className="blog-row-slide__body-img">
        <Img
          className="blog-row-slide__img"
          fluid={data.main_image.childImageSharp.fluid}
        />
      </div>
      <div className="blog-row-slide__body-description">
        <h3 className="blog-row-slide__headline">{data[`title_${lang}`]}</h3>
        <p className="blog-row-slide__description">
          {data[`preview_text_${lang}`]}
        </p>
        <p className="blog-row-slide__date">{data.create_date}</p>
        <Link
          className="blog-row-slide__link"
          to={`/${lang}/blog/${data.category.slug}/${data.slug}`}
        >
          {blogKey[lang].more}
        </Link>
      </div>
    </div>
  );
};

export default BlogRowSliderItem;
