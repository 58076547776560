export default {
  ru: {
    all: "Все",
    host: "Хостинг",
    cdn: "CDN",
    view_on_map: "Смотреть на карте",
  },
  en: {
    all: "All",
    host: "Hosting",
    cdn: "CDN",
    view_on_map: "View on the map",
  },
}
