import React from "react";
// import Icon from "../../../Sprite/Icon";

import "./BlogListFilter.css";
import blogKey from "../../../../language/blogKey";
import BlogSearch from "../BlogSearch/BlogSearch";

const BlogListFilter = ({ params, lang, navigate, categories, blogs }) => {

  const onChangeCategory = (e) => {
    params.set("category", e.target.value);
    params.delete("page");
    params.delete("search");
    navigate("?" + params.toString());
  };

  const onCleaCategory = () => {
    params.delete("category");
    params.delete("page");
    params.delete("search");
    navigate("?" + params.toString());
  };

  // const onChangeIsTop = (value) => {
  //   params.set("is-top", value);
  //   params.delete("page");
  //   navigate("?" + params.toString());
  // };

  return (
    <div className="container">
      <div className="body__blog-filter">
        <h3 className="blog-filter__headline">
          {params.get("category")
            ? categories.filter(
                (i) => i.node.slug === params.get("category")
              )[0].node[`title_${lang}`]
            : blogKey[lang].all_posts}
        </h3>
        <div className="blog-filter__continer">
          <label className="blog-list__label">
            <input
              name="category"
              type="radio"
              value="none"
              checked={!params.get("category")}
              onChange={onCleaCategory}
              className="blog-list__radio"
            />
            <span className="blog-list__ui-radio"/>
            {blogKey[lang].all_posts}
          </label>
          {categories.map((i) => (
            <label key={i.node.slug} className="blog-list__label">
              <input
                name="category"
                type="radio"
                value={i.node.slug}
                checked={i.node.slug === params.get("category")}
                onChange={onChangeCategory}
                className="blog-list__radio"
              />
              <span className="blog-list__ui-radio"/>
              {i.node[`title_${lang}`]}
            </label>
          ))}
          {/*<label className="blog-list__label">*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    name="is-top"*/}
          {/*    defaultChecked={params.get("is-top") === "true"}*/}
          {/*    onChange={() => onChangeIsTop(params.get("is-top") !== "true")}*/}
          {/*    className="blog-list__checkbox"*/}
          {/*  />*/}
          {/*  <span className="blog-list__ui-check">*/}
          {/*    <Icon*/}
          {/*      width={24}*/}
          {/*      height={24}*/}
          {/*      iconId="check"*/}
          {/*      classes="blog-list__ui-check-icon"*/}
          {/*    />*/}
          {/*  </span>*/}
          {/*  {blogKey[lang].in_top}*/}
          {/*</label>*/}
          <BlogSearch params={params} navigate={navigate} lang={lang}/>
        </div>
      </div>
      {params.get("search") &&
      <div>
        {blogKey[lang].search_request} : "{params.get("search")}"
      </div>}
      {!blogs.length && params.get("search")
        ? <div className="no-results">{blogKey[lang].search_results}</div>
        : null
      }
    </div>
  );
};

export default BlogListFilter;
