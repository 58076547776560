import React, {useEffect, useState} from "react"
import './CookieNotice.css'
import ReactMarkdown from "react-markdown";

const CookieNotice = ({lang, data}) => {
  const {strapiCookieNotice} = data
  const [showNotice, setShowNotice] = useState(false)

  useEffect(() => {
    let notice = localStorage.getItem('_notice')
    if (!notice) {
      setShowNotice(true)
    }
  }, [])

  const confirmCookies = () => {
    localStorage.setItem('_notice', 'confirm')
    setShowNotice(false)
  }

  function LinkRenderer(props) {
    return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
  }

  return (
    <>
      {showNotice &&
        <div className="cookie-notice">
          <div className="cookie-notice__body">
            <div className="cookie-notice__description">
              <ReactMarkdown
                source={strapiCookieNotice[`description_${lang}`]}
                renderers={{link: LinkRenderer}}
              />
            </div>
            <button className="btn btn--red cookie-notice__btn"
                    onClick={confirmCookies}
            >
              {strapiCookieNotice[`button_text_${lang}`]}
            </button>
          </div>

        </div>
      }
    </>
  )
}

export default CookieNotice
