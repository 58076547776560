const isNavigatorLanguage = langKey =>
  window &&
  window.navigator &&
  window.navigator.language &&
  window.navigator.language.indexOf(langKey) === 0

export const getUserLanguageURL = simpleLanguages => {
  let language = ""
  simpleLanguages.forEach(lang => {
    if (isNavigatorLanguage(lang.slug)) language = lang.slug
    lang.related_languages
      .split(",")
      .forEach(
        relLang => isNavigatorLanguage(relLang) && (language = lang.slug)
      )
  })
  return `/${
    language ||
    simpleLanguages.filter(lang => lang.related_languages === "any")[0].slug
  }/`
}
