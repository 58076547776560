import React from 'react';
import './ContactDepartment.css';
import Img from "gatsby-image";
import {Link} from "gatsby";

const ContactDepartment = ({lang, body}) => {
  return (
    <div className={`contact-department 
   ${body.style ? "contact-department--" + body.style : ""}
   ${body.theme ? "contact-department--" + body.theme : ""}
   `}>
      {body.theme !== "column"
        ? <div className="container">
          <div className="contact-department__text-block">
            <h2 className="contact-department__title">{body[`title_${lang}`]}</h2>
            {body[`description_${lang}`] &&
            <p className="contact-department__description">{body[`description_${lang}`]}</p>
            }
            <div className="contact-department__btn-box">
              {(body[`button_text_${lang}`] && body[`button_url_${lang}`] && (
                <a
                  className="btn btn--red contact-department__btn"
                  href="https://advancedhosting.com/en/contact-us"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {body[`button_text_${lang}`]}
                </a>
              )) ||
              (body[`button_text_${lang}`] && body.to_page?.slug && (
                <Link
                  className="btn btn--red contact-department__btn"
                  to={`/${lang}/${body.to_page.slug}`}
                >
                  {body[`button_text_${lang}`]}
                </Link>
              ))}

              {(body[`second_button_text_${lang}`] && body[`second_button_url_${lang}`] && (
                <a
                  className="btn btn--red contact-department__second-btn"
                  href={body[`second_button_url_${lang}`]}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {body[`second_button_text_${lang}`]}
                </a>
              )) ||
              (body[`second_button_text_${lang}`] && body.to_second_page?.slug && (
                <Link
                  className="btn btn--red contact-department__second-btn"
                  to={`/${lang}/${body.to_second_page.slug}`}
                >
                  {body[`second_button_text_${lang}`]}
                </Link>
              ))}
            </div>

          </div>

          {body.image &&
          <div className="contact-department__image-block">
            <Img className="contact-department__image" fluid={body.image.childImageSharp.fluid}/>
          </div>
          }
        </div>
        : <div className="container">
          {body[`title_${lang}`] &&
            <h2 className="contact-department__title">{body[`title_${lang}`]}</h2>
          }
          {body[`description_${lang}`] &&
          <p className="contact-department__description">{body[`description_${lang}`]}</p>
          }
          {body.image &&
          <div className="contact-department__image-block">
            <Img className="contact-department__image" fluid={body.image.childImageSharp.fluid}/>
          </div>
          }
          <div className="contact-department__btn-box">
            {(body[`button_text_${lang}`] && body[`button_url_${lang}`] && (
              <a
                className="btn btn--red contact-department__btn"
                href="https://advancedhosting.com/en/contact-us"
                target="_blank"
                rel="noreferrer noopener"
              >
                {body[`button_text_${lang}`]}
              </a>
            )) ||
            (body[`button_text_${lang}`] && body.to_page?.slug && (
              <Link
                className="btn btn--red contact-department__btn"
                to={`/${lang}/${body.to_page.slug}`}
              >
                {body[`button_text_${lang}`]}
              </Link>
            ))}

            {(body[`second_button_text_${lang}`] && body[`second_button_url_${lang}`] && (
              <a
                className="btn btn--red contact-department__second-btn"
                href={body[`second_button_url_${lang}`]}
                target="_blank"
                rel="noreferrer noopener"
              >
                {body[`button_text_${lang}`]}
              </a>
            )) ||
            (body[`second_button_text_${lang}`] && body.to_second_page?.slug && (
              <Link
                className="btn btn--red contact-department__second-btn"
                to={`/${lang}/${body.to_second_page.slug}`}
              >
                {body[`second_button_text_${lang}`]}
              </Link>
            ))}
          </div>

        </div>

      }


    </div>
  )
};

export default ContactDepartment;