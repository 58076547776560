import React from "react"
import "./WikiBody.css"
import ReactMarkdown from "react-markdown"
import ContentImage from "../../ContentImage/ContentImage"

const WikiBody = ({ title, body, utm = "", data }) => {
  const Image = ({ alt, src }) => (
    <ContentImage alt={alt} src={src} allFile={data.allFile} />
  )
  const renderers = {
    image: Image,
  }
  return (
    <div className="wiki-body">
      <h1 className="wiki-body__title">{title}</h1>
      <div className="wiki-body__content">
        <ReactMarkdown
          source={body}
          renderers={renderers}
          transformLinkUri={url =>
            url &&
            (url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&"))
          }
        />
      </div>
    </div>
  )
}

export default WikiBody
