import React from "react"
import { graphql } from "gatsby"
import WikiDefault from "../components/PageTemplates/WikiDefault"

const Page = props => {
  return (
    <WikiDefault
      slug={props.pageContext.slug}
      lang={props.pageContext.lang}
      seo={props.data.strapiWiki.seo}
      title={props.data.strapiWiki.title_en}
      currentSlug={props.data.strapiWiki.slug}
      body={props.data.strapiWiki.body_en}
      data={{ ...props.data, ...props.pageContext.metaData }}
    />
  )
}

export default Page
export const pageQuery = graphql`
  query($slug: String!) {
    strapiWiki(slug: { eq: $slug }) {
      slug
      title_en
      body_en
      seo {
        head_title_en
        link_canonical_en
        meta_descripiton_en
        meta_robots_en
      }
    }
    allFile {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiWiki(filter: { published: { ne: false } }) {
      edges {
        node {
          slug
          published
          title_ru
          title_en
          position
          childs {
            slug
            published
          }
          head {
            slug
            published
          }
        }
      }
    }
  }
`
