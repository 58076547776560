import React from "react";
import Icon from "../Sprite/Icon";
import "./Partners.css";

const Partners = ({ title, items }) => {
  return (
    <div className="partners container">
      <p className="title">{title}</p>
      <div className="partners__logo-box">
        {items.map((i) => (
          <a
            className="partners__link"
            key={i.link_url}
            href={i.link_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i.logo_img ? (
              <img
                className="partners__logo"
                src={i.logo_img.childImageSharp.original.src}
                alt={i.link_url}
              />
            ) : (
              <Icon classes="partners__logo" iconId={i.icon_id} />
            )}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Partners;
