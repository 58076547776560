import React from "react"
import "./LightMap.css"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

mapboxgl.accessToken =
  "pk.eyJ1IjoiZnJvbnRib25kIiwiYSI6ImNqMHc5bjdveTAwMDYzM21vNnRpZmtnNHEifQ.DesrH_FjshLanftsGwwm8w"

class LightMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lng: 5,
      lat: 34,
      zoom: 2,
      colors: {
        cdn: "#4db6ac",
        host: "#e54949",
        mix: "#7391a0",
      },
      style: {
        ru: "mapbox://styles/frontbond/cj1hlf124002r2rmtpzz1btov",
        en: "mapbox://styles/frontbond/cj3vjjw8d075j2smjuubz48to",
      },
    }
  }

  componentDidMount() {
    this.mapStore = function () {
      return {
        type: "FeatureCollection",
        features: this.props.filter.value
          ? this.props.mapItems
              .filter(item => item.type === this.props.filter.value)
              .map(item => ({
                type: "Feature",
                properties: {
                  id: item.id,
                  title: item[`title_${this.props.lang}`],
                  address: item[`address_${this.props.lang}`],
                  type: item.type,
                },
                geometry: {
                  type: "Point",
                  coordinates: item.coordinates,
                },
              }))
          : this.props.mapItems.map(item => ({
              type: "Feature",
              properties: {
                id: item.id,
                title: item[`title_${this.props.lang}`],
                address: item[`address_${this.props.lang}`],
                type: item.type,
              },
              geometry: {
                type: "Point",
                coordinates: item.coordinates,
              },
            })),
      }
    }.bind(this)

    this.popUp = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 27,
    })

    this.colorByType = [
      "case",
      ["==", ["get", "type"], "cdn"],
      this.state.colors.cdn,
      ["==", ["get", "type"], "host"],
      this.state.colors.host,
      this.state.colors.host,
    ]

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: this.state.style[this.props.lang],

      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      touchPitch: window ? window.innerWidth > 1024 : false,
      dragPan: window ? window.innerWidth > 1024 : false,
    })

    this.map.on(
      "move",
      function () {
        this.setState({
          lng: this.map.getCenter().lng.toFixed(4),
          lat: this.map.getCenter().lat.toFixed(4),
          zoom: this.map.getZoom().toFixed(2),
        })
      }.bind(this)
    )

    this.map.scrollZoom.disable()
    this.map.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      "bottom-right"
    )

    this.map.on(
      "load",
      function () {
        this.map.addSource("mapData", {
          type: "geojson",
          data: this.mapStore(),
          cluster: true,
          clusterMaxZoom: 12,
          clusterRadius: 18,
          clusterProperties: {
            cdn: ["+", ["case", ["==", ["get", "type"], "cdn"], 1, 0]],
            host: ["+", ["case", ["==", ["get", "type"], "host"], 1, 0]],
          },
        })

        this.map.addLayer({
          id: "mapOne",
          type: "circle",
          source: "mapData",
          filter: ["!has", "point_count"],
          paint: {
            "circle-color": this.colorByType,
            "circle-radius": 12.5,
            "circle-stroke-width": 1,
            "circle-stroke-color": this.colorByType,
          },
        })

        this.map.addLayer({
          id: "mapGroup",
          type: "circle",
          source: "mapData",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "case",
              [">", ["get", "cdn"], ["get", "host"]],
              this.state.colors.cdn,
              ["<", ["get", "cdn"], ["get", "host"]],
              this.state.colors.host,
              ["==", ["get", "cdn"], ["get", "host"]],
              this.state.colors.mix,
              this.state.colors.mix,
            ],
            "circle-radius": 22.5,
            "circle-stroke-width": 0,
          },
        })

        this.map.addLayer({
          id: "mapCount",
          type: "symbol",
          source: "mapData",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count}",
            "text-font": ["Open Sans Semibold"],
            "text-size": 16,
          },
          paint: {
            "text-color": "#fff",
          },
        })

        //preparing special layers
        this.map.addLayer({
          id: "mapOneActive",
          type: "circle",
          source: "mapData",
          filter: ["==", "title", ""],
          paint: {
            "circle-color": "#fff",
            "circle-radius": 12.5,
            "circle-stroke-width": 1,
            "circle-stroke-color": this.colorByType,
          },
        })
        this.map.addLayer({
          id: "mapOneActiveDot",
          type: "circle",
          source: "mapData",
          filter: ["==", "title", ""],
          paint: {
            "circle-color": this.colorByType,
            "circle-radius": 9.5,
          },
        })
        //=======

        this.map.on(
          "mouseenter",
          "mapOne",
          function (e) {
            this.map.getCanvas().style.cursor = "pointer"

            this.map.setFilter("mapOneActive", [
              "==",
              "id",
              e.features[0].properties.id,
            ])
            this.map.setFilter("mapOneActiveDot", [
              "==",
              "id",
              e.features[0].properties.id,
            ])
            this.map.setPaintProperty("mapOneActive", "circle-radius", 15)

            this.popUp
              .setLngLat(e.features[0].geometry.coordinates)
              .setHTML(
                `<h3>
              ${e.features[0].properties.title}
              </h3>
              <h4>
              ${e.features[0].properties.address}
              </h4>`
              )
              .addTo(this.map)
          }.bind(this)
        )
        this.map.on(
          "mousemove",
          "mapOne",
          function (e) {
            this.map.setFilter("mapOneActive", [
              "==",
              "id",
              e.features[0].properties.id,
            ])
            this.map.setFilter("mapOneActiveDot", [
              "==",
              "id",
              e.features[0].properties.id,
            ])
            this.map.setPaintProperty("mapOneActive", "circle-radius", 15)

            this.popUp.setLngLat(e.features[0].geometry.coordinates).setHTML(
              `<h3>
              ${e.features[0].properties.title}
              </h3>
              <h4>
              ${e.features[0].properties.address}
              </h4>`
            )
          }.bind(this)
        )
        this.map.on(
          "mouseleave",
          "mapOne",
          function () {
            this.map.getCanvas().style.cursor = ""
            this.map.setFilter("mapOneActive", ["==", "title", ""])
            this.map.setFilter("mapOneActiveDot", ["==", "title", ""])
            this.map.setPaintProperty("mapOneActive", "circle-radius", 12.5)

            this.popUp.remove()
          }.bind(this)
        )

        this.map.on(
          "mouseenter",
          "mapGroup",
          function (e) {
            this.map.getCanvas().style.cursor = "zoom-in"
          }.bind(this)
        )
        this.map.on(
          "mouseleave",
          "mapGroup",
          function () {
            this.map.getCanvas().style.cursor = ""
          }.bind(this)
        )
        this.map.on(
          "click",
          "mapGroup",
          function (e) {
            this.map.flyTo({
              center: [e.lngLat.lng, e.lngLat.lat],
              zoom: (Math.floor(this.map.getZoom() / 5) + 1) * 5,
              speed: 3.6,
              curve: 1,
              easing: function (t) {
                return t
              },

              essential: true,
            })
          }.bind(this)
        )

        this.map.touchPitch.disable()
      }.bind(this)
    )

    this.resize = function () {
      if (window) {
        if (window.innerWidth > 1024) {
          this.map.dragPan.enable()
        } else {
          this.map.dragPan.disable()
        }
      }
    }.bind(this)

    window && window.addEventListener("resize", this.resize)
  }

  componentDidUpdate(prevProps) {
    if (this.map.loaded()) {
      this.map.getSource("mapData") &&
        this.map.getSource("mapData").setData(this.mapStore())
    } else {
      this.map.on(
        "load",
        function () {
          this.map.getSource("mapData") &&
            this.map.getSource("mapData").setData(this.mapStore())
        }.bind(this)
      )
    }
  }

  componentWillUnmount() {
    window && window.removeEventListener("resize", this.resize)
  }

  render() {
    return (
      <div className="light-map">
        <div
          ref={el => (this.mapContainer = el)}
          className="light-map-container"
        />
      </div>
    )
  }
}

export default LightMap
