import React from "react"

const Icon = ({ iconId, classes, width, height }) => {
  return (
    <svg className={classes} width={width} height={height}>
      <use xlinkHref={`#icon_${iconId}`} />
    </svg>
  )
}

export default Icon
