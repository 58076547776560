import React, { useEffect, useState } from "react"
import "./ContentMenu.css"
import Icon from "../../Sprite/Icon"
import { lockScroll, unlockScroll } from "../../../methods/scroll"

const ContentMenu = ({ navlist }) => {
  let [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    unlockScroll()
  }, [])

  useEffect(() => {
    if (isOpen) {
      lockScroll()
      window.document
        .querySelectorAll(`.content-menu__content a`)
        .forEach(i => {
          i.addEventListener("click", () => {
            setTimeout(() => {
              unlockScroll()
              setIsOpen(false)
            }, 0)
          })
        })
    } else {
      unlockScroll()
    }
  }, [isOpen])

  return (
    <div className="content-menu">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`content-menu__button ${
          isOpen ? "content-menu__button--open" : ""
        }`}
      >
        {isOpen ? (
          <Icon
            iconId={"submenu_open"}
            classes={`content-menu__button-icon
               content-menu__button-icon--open`}
            width={24}
            height={24}
          />
        ) : (
          <Icon
            iconId={"submenu"}
            classes={"content-menu__button-icon"}
            width={24}
            height={24}
          />
        )}
      </button>
      {isOpen && (
        <>
          <div className="content-menu__body">
            <div className="content-menu__content">{navlist}</div>
          </div>
          <div
            className="content-menu__overlay"
            onClick={() => setIsOpen(false)}
          ></div>
        </>
      )}
    </div>
  )
}

export default ContentMenu
