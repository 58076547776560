import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setMapData } from "../../../state/mapReducer"
import LightMap from "../../NetworkMap/LightMap/LightMap"
import "./LightMapBox.css"
import { graphql, useStaticQuery } from "gatsby"

const LightMapBox = ({ lang, body, map, setMapData }) => {
  const { strapiMapData } = useStaticQuery(graphql`
    query {
      strapiMapData {
        map_item {
          address_en
          address_ru
          coordinates
          description_en
          description_ru
          title_en
          title_ru
          type
        }
      }
    }
  `)
  useEffect(() => {
    setMapData(strapiMapData.map_item)
  }, [])

  let filter = {
    value:
      body.filter_point === "all" || body.filter_point === null
        ? false
        : body.filter_point,
  }

  return (
    <div className="light-map-box">
      {body[`title_${lang}`] && (
        <div className="container">
          <h2 className="section-title light-map-box__title">
            {body[`title_${lang}`]}
          </h2>
        </div>
      )}
      <LightMap mapItems={map.items} filter={filter} lang={lang} />
    </div>
  )
}

let mapStateToProps = state => ({
  map: state.map,
})

export default connect(mapStateToProps, {
  setMapData,
})(LightMapBox)
