import React from "react";
import Img from "gatsby-image";

const HeroImage = ({ image }) => {
  return (
    <Img className="hero__img-fluid" fluid={image.childImageSharp.fluid} />
  );
};

export default HeroImage;
