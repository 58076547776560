import React from "react"
import "./CalcGroup.css"
import Icon from "../../../../Sprite/Icon"

const CalcGroup = ({
  title,
  code,
  isCheckbox,
  change,
  list,
  nameGetter,
  subNameGetter,
  priceGetter,
  error,
  table,
  title2,
}) => {
  return (
    <div
      className={`d-calc__group calc-group ${
        table ? "calc-group--table" : ""
      } ${title ? "" : "calc-group--light"}`}
    >
      <h3
        className={`calc-group__title ${
          error ? "calc-group__title--error" : ""
        }`}
      >
        {table ? (
          <>
            <span className="calc-group__title-head">{title}</span>
            <span>{title2}</span>
          </>
        ) : (
          title
        )}
      </h3>
      <ul className="calc-group__list">
        {list &&
          list.map((i, n) => (
            <li className="calc-group__item" key={n}>
              <label
                className={`calc-group__label ${
                  i.disabled && !i.current ? "calc-group__label--disable" : ""
                }`}
              >
                {isCheckbox ? (
                  <>
                    <input
                      type="checkbox"
                      checked={!!i.current}
                      name={code}
                      disabled={i.disabled && !i.current}
                      onChange={() => change(n)}
                      className="calc-group__input-check"
                    />
                    <span className="calc-group__ui-check">
                      <Icon
                        width={24}
                        height={24}
                        iconId="check"
                        classes="calc-group__ui-check-icon"
                      />
                    </span>
                  </>
                ) : (
                  <>
                    <input
                      type="radio"
                      checked={!!i.current}
                      name={code}
                      disabled={i.disabled && !i.current}
                      onChange={() => change(n)}
                      className="calc-group__input-radio"
                    />
                    <span className="calc-group__ui-radio"></span>
                  </>
                )}
                <span className="calc-group__name">{nameGetter(i)}</span>
                <span className="calc-group__sub-name">{subNameGetter(i)}</span>
                <span className="calc-group__price">{priceGetter(i)}</span>
              </label>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default CalcGroup
