import React from "react"
import { Link } from "gatsby"

const MenuList = ({
  lang,
  slug,
  head,
  utm = "",
  pages,
  subcategories,
  classes,
  name,
}) => {
  return (
    <>
      {pages && pages.length > 1 && (
        <div className={`${classes}__item`}>
          <h4 className={`${classes}_category`}>{name}</h4>
          <ul>
            {pages.map(page => (
              <li key={page.slug}>
                {page.template === "sub_pages" ? (
                  head === page.slug ? (
                    <span className={`${classes}__page-current`}>
                      {page[`title_${lang}`]}
                    </span>
                  ) : (
                    <Link
                      to={`/${lang}/${page.slug}/${page.current_sub_page.slug}/${utm}`}
                      className={`${classes}__link`}
                    >
                      {page[`title_${lang}`]}
                    </Link>
                  )
                ) : page.slug === slug ? (
                  <span className={`${classes}__page-current`}>
                    {page[`title_${lang}`]}
                  </span>
                ) : (
                  <Link
                    to={`/${lang}/${page.slug}${utm}`}
                    className={`${classes}__link`}
                  >
                    {page[`title_${lang}`]}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {subcategories && subcategories.length > 0 && (
        <div className={`${classes}__subcategory`}>
          {subcategories.map(subcategory => (
            <div
              key={subcategory[`name_${lang}`]}
              className={`${classes}__item`}
            >
              <h4 className={`${classes}_category`}>
                {subcategory[`name_${lang}`]}
              </h4>
              <ul>
                {subcategory.pages.map(page => (
                  <li key={page.slug}>
                    {page.template === "sub_pages" ? (
                      head === page.slug ? (
                        <span className={`${classes}__page-current`}>
                          {page[`title_${lang}`]}
                        </span>
                      ) : (
                        <Link
                          to={`/${lang}/${page.slug}/${page.current_sub_page.slug}/${utm}`}
                          className={`${classes}__link`}
                        >
                          {page[`title_${lang}`]}
                        </Link>
                      )
                    ) : page.slug === slug ? (
                      <span className={`${classes}__page-current`}>
                        {page[`title_${lang}`]}
                      </span>
                    ) : (
                      <Link
                        to={`/${lang}/${page.slug}${utm}`}
                        className={`${classes}__link`}
                      >
                        {page[`title_${lang}`]}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
      {pages && pages.length === 1 && (
        <div className={`${classes}__item`}>
          <h4 className={`${classes}_category`}>
            {pages[0].template === "sub_pages" ? (
              head === pages[0].slug ? (
                <span className={`${classes}__category-current`}>{name}</span>
              ) : (
                <Link
                  className={`${classes}_category-link`}
                  to={`/${lang}/${pages[0].slug}/${pages[0].current_sub_page.slug}/${utm}`}
                >
                  {name}
                </Link>
              )
            ) : pages[0].slug === slug ? (
              <span className={`${classes}__category-current`}>{name}</span>
            ) : (
              <Link
                className={`${classes}_category-link`}
                to={`/${lang}/${pages[0].slug}`}
              >
                {name}
              </Link>
            )}
          </h4>
        </div>
      )}
    </>
  )
}

export default MenuList
