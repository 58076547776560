import React from "react"
import "./CloudServer.css"
import CGroup from "./CGroup/CGroup"
import { graphql, useStaticQuery } from "gatsby"

const CloudServer = ({ lang, utm = "", body }) => {
  const { strapiProductCloudServer } = useStaticQuery(graphql`
    query {
      strapiProductCloudServer {
        group_1_title_en
        group_1_title_ru
        group_1_color
        group_1_show
        group_1_list {
          name
          cpu_name
          ram_amount
          ram_unit
          ram_name
          disk_amount
          disk_unit
          disk_model
          disk_type
          bandwidth_amount
          bandwidth_unit
          bandwidth_name_en
          bandwidth_name_ru
          price
          product_url
        }

        group_2_title_ru
        group_2_title_en
        group_2_color
        group_2_show
        group_2_list {
          name
          cpu_name
          ram_amount
          ram_unit
          ram_name
          disk_amount
          disk_unit
          disk_model
          disk_type
          bandwidth_amount
          bandwidth_unit
          bandwidth_name_ru
          bandwidth_name_en
          price
          product_url
        }
        group_3_title_ru
        group_3_title_en
        group_3_color
        group_3_show
        group_3_list {
          name
          cpu_name
          ram_amount
          ram_unit
          ram_name
          disk_amount
          disk_unit
          disk_model
          disk_type
          bandwidth_amount
          bandwidth_unit
          bandwidth_name_ru
          bandwidth_name_en
          price
          product_url
        }
      }
    }
  `)
  return (
    <div className="container cloud-server">
      <CGroup
        title={strapiProductCloudServer[`group_1_title_${lang}`]}
        color={strapiProductCloudServer.group_1_color}
        show={strapiProductCloudServer.group_1_show}
        list={strapiProductCloudServer.group_1_list}
        lang={lang}
        utm={utm}
      />
      <CGroup
        title={strapiProductCloudServer[`group_2_title_${lang}`]}
        color={strapiProductCloudServer.group_2_color}
        show={strapiProductCloudServer.group_2_show}
        list={strapiProductCloudServer.group_2_list}
        lang={lang}
        utm={utm}
      />
      <CGroup
        title={strapiProductCloudServer[`group_3_title_${lang}`]}
        color={strapiProductCloudServer.group_3_color}
        show={strapiProductCloudServer.group_3_show}
        list={strapiProductCloudServer.group_3_list}
        lang={lang}
        utm={utm}
      />
    </div>
  )
}

export default CloudServer
