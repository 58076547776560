import React from "react"
import LargeMap from "../../LargeMap/LargeMap"
import MapFilter from "../../MapFilter/MapFilter"
import "./NetworkMobileMap.css"
import Icon from "../../../Sprite/Icon"

const NetworkMobileMap = ({
  mapItems,
  filter,
  lang,
  changeActivePoint,
  activePoint,
  changeFilter,
  closeMap,
}) => {
  return (
    <div className="nm-map">
      <div className="nm-map__ui">
        <MapFilter filter={filter} changeFilter={changeFilter} lang={lang} />
        <button className="nm-map__button" onClick={() => closeMap()}>
          <Icon iconId="close_big" classes="nm-map__button-icon" />
        </button>
      </div>

      <LargeMap
        mapItems={mapItems}
        filter={filter}
        lang={lang}
        changeActivePoint={changeActivePoint}
        activePoint={activePoint}
      />
    </div>
  )
}

export default NetworkMobileMap
