import React from "react";
import "./SubPagesNav.css";
import { Link } from "gatsby";

const SubPagesNav = ({ lang, slug, head, utm = "", data }) => {
  const { allStrapiPage } = data;
  let allSubPage = {
    edges: allStrapiPage.edges.filter(
      (page) => page.node.template === "sub_pages"
    ),
  };

  let navList = allSubPage.edges
    .filter((i) => i.node.slug === head)[0]
    .node.content.filter((i) => i.type === "sub_pages")[0].pages;
  return (
    <ul className="subpage-nav">
      {navList.map(
        (item) =>
          item.page.published && (
            <li className="subpage-nav__item" key={item.page.slug}>
              {slug === item.page.slug ? (
                <span className="subpage-nav__current">
                  {item[`name_${lang}`]}
                </span>
              ) : (
                <Link
                  className="subpage-nav__link"
                  to={`/${lang}/${head}/${item.page.slug}/${utm}`}
                >
                  {item[`name_${lang}`]}
                </Link>
              )}
            </li>
          )
      )}
    </ul>
  );
};

export default SubPagesNav;
