import React from 'react';
import Icon from "../../Sprite/Icon";
import "./CallbackForm.css"

const CallbackForm = ({body, lang}) => {
  return (
    <div className="callback-form">
      <h2 className="section-title callback-form__title">
        {body[`title_${lang}`]}
      </h2>
      {body[`description_${lang}`] &&
        <p className="callback-form__description">
          {body[`description_${lang}`]}
        </p>
      }
      <a
         href={
             body[`button_url_${lang}`].indexOf("?") < 0
                 ? body[`button_url_${lang}`]
                 : body[`button_url_${lang}`]
         }
         className={`btn btn--green btn--clear ${body.icon ? "btn--icon" : ""}`}
         target="_blank"
         rel="noopener noreferrer">
        {body.icon &&
          <Icon classes="btn-icon" iconId="paper_plane"/>
        }
        {body[`button_text_${lang}`]}
      </a>
    </div>
  )
};

export default CallbackForm;