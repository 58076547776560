import React from "react"
import PageContent from "../../PageContent/PageContent"
import WikiNav from "../WikiNav/WikiNav"
import "./WikiLayout.css"
import Responsive from "../../Responsive/Responsive"
import ContentNav from "../../ContentNav/ContentNav"

const WikiLayout = ({ lang, slug, content, utm = "", data }) => {
  return (
    <div className="wiki-layout container">
      <Responsive options={["desktop"]}>
        <nav className="wiki-layout__nav">
          <WikiNav currentSlug={null} lang={lang} utm={utm} data={data} />
        </nav>
        <div className="wiki-layout__content">
          {content && (
            <PageContent
              content={content}
              lang={lang}
              slug={slug}
              utm={utm}
              data={data}
            />
          )}
        </div>
      </Responsive>
      <Responsive options={["tab", "mobile"]}>
        <ContentNav
          navlist={
            <WikiNav currentSlug={null} lang={lang} utm={utm} data={data} />
          }
          content={
            content && (
              <PageContent
                content={content}
                lang={lang}
                slug={slug}
                utm={utm}
                data={data}
              />
            )
          }
        />
      </Responsive>
    </div>
  )
}

export default WikiLayout
