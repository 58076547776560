import React from "react"
import { connect } from "react-redux"
import productKey from "../../../../language/productKey"
import BreadcrumbsNesting from "../../../Breadcrumbs/BreadcrumbsNesting"

const DBreadcrumbs = ({ dedicated, current, lang, slug }) => {
  let nestingList = [
    {
      name_ru: productKey.ru.breadcrumbs[0],
      name_en: productKey.en.breadcrumbs[0],
      slug: slug,
      state: dedicated.currentItem,
    },
    {
      name_ru: productKey.ru.breadcrumbs[1],
      name_en: productKey.en.breadcrumbs[1],
      slug: `${slug}/step-2`,
      current: current === "calc",
      state: dedicated.currentItem,
    },
    {
      name_ru: productKey.ru.breadcrumbs[2],
      name_en: productKey.en.breadcrumbs[2],
      slug: `${slug}/step-3`,
      state: dedicated.currentItem,
      current: current === "checkout",
    },
  ]

  return <BreadcrumbsNesting lang={lang} nestingList={nestingList} />
}

let mapStateToProps = state => ({ dedicated: state.dedicated })

export default connect(mapStateToProps)(DBreadcrumbs)
