export default {
  ru: {
    all_posts: "Все статьи",
    top: "Топ",
    in_top: "В топе",
    author: "Автор",
    show_all: "Смотреть все",
    more: "Подронее",
    search: "Поиск",
    search_request: "Поисковой запрос",
    search_results: "Нет результата"
  },
  en: {
    all_posts: "All post",
    top: "Top",
    in_top: "In top",
    author: "Author",
    show_all: "Show all",
    more: "More",
    search: "Search",
    search_request: "Search request",
    search_results: "No results"
  },
};
