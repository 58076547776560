import React, { useState } from "react"
import "./FormSubscribe.css"
import Input from "../../UI/Input/Input"
import Button from "../../UI/Button/Button"
import { connect } from "react-redux"
import { changeEmail, changeName } from "../../../state/subscribeReducer"
import Animation from "../../Animation/Animation"
import Responsive from "../../Responsive/Responsive"
import { addContact, getContactByEmail } from "../../../api/subscribe"
import Preloader from "../../Preloader/Preloader"

const FormSubscribe = ({
  name,
  email,
  changeName,
  changeEmail,
  label_name,
  error_name,
  label_email,
  error_email,
  button_text,
  form_title,
  isPlay,
  api_key,
  api_group,
  message_success_subscribe,
  message_subscribe_was_already,
}) => {
  let [isName, setIsName] = useState(true)
  let [isEmail, setIsEmail] = useState(true)
  let [isDone, setIsDone] = useState(false)
  let [message, setMessage] = useState(null)

  const onSubscriber = async () => {
    if (name !== "" && email !== "" && email.indexOf("@") > 0) {
      let contact = await getContactByEmail(email, api_key)
      setIsDone(true)
      changeName("")
      changeEmail("")
      if (contact.length > 0) {
        setMessage(message_subscribe_was_already)
      } else {
        let addedContact = await addContact(email, name, api_group, api_key)
        if (addedContact.id)
          setMessage(message_success_subscribe.replace("$EMAIL$", email))
      }
    }
    setIsName(name !== "")
    setIsEmail(email !== "" && email.indexOf("@") > 0)
  }

  return (
    <form
      className={`form-subscribe ${isDone ? "form-subscribe--done" : ""}`}
      action=""
      onSubmit={e => e.preventDefault()}
    >
      {isDone ? (
        message ? (
          <h4 className="title form-subscribe__title">{message}</h4>
        ) : (
          <Preloader />
        )
      ) : (
        <>
          <h4 className="title form-subscribe__title">{form_title}</h4>
          <Input
            placeholder={label_name}
            errorText={error_name}
            onChangeValue={changeName}
            value={name}
            isError={!isName}
            classes="form-subscribe__input"
          />
          <Input
            placeholder={label_email}
            errorText={error_email}
            onChangeValue={changeEmail}
            value={email}
            isError={!isEmail}
            classes="form-subscribe__input"
          />
        </>
      )}
      <Responsive options={["mobile"]}>
        <div>
          <Animation isAnim={isPlay} illustration_id="mail" />
        </div>
      </Responsive>
      {!isDone && (
        <Button
          text={button_text}
          classes={"btn--red form-subscribe__btn"}
          onPress={onSubscriber}
        />
      )}
    </form>
  )
}

let mapStateToProps = state => ({
  name: state.subscribe.name,
  email: state.subscribe.email,
})

export default connect(mapStateToProps, { changeName, changeEmail })(
  FormSubscribe
)
