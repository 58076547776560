import React from "react"
import Feature from "./Feature"
import "./Features.css"

const PagesFeatures = ({ body, lang, utm = "" }) => {
  return (
    <div className={`features features--page ${
      body.theme ? "features-container--" + body.theme : ""
    }`}>
      <div className="container">
        <h2 className="section-title features__title">
          {body[`title_${lang}`]}
        </h2>
        { body[`description_${lang}`] &&
          <p className="text features__description">
            {body[`description_${lang}`]}
          </p>
        }
        <div
          className={`features-container ${
            body.theme ? "features-container--" + body.theme : ""
          }`}
        >
          {body.feature_item.map((item, n) => (
            <Feature
              key={n}
              utm={utm}
              title={item[`name_${lang}`]}
              description={item[`description_${lang}`]}
              iconId={item.icon}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default PagesFeatures
