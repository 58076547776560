import React from "react"
import { connect } from "react-redux"
import "./DSwitcher.css"
import productKey from "../../../../language/productKey"
import { setPriceCode } from "../../../../state/dedicatedReducer"

const DSwitcher = ({ priceCode, lang, setPriceCode }) => {
  const options = [
    {
      name: productKey[lang].country.eu,
      code: "eu",
      value: priceCode === "eu",
    },
    {
      name: productKey[lang].country.us,
      code: "us",
      value: priceCode === "us",
    },
  ]

  return (
    <ul
      className="d-switcher"
      style={{
        "--d-switcher-active": options.findIndex(i => i.value),
      }}
    >
      {options.map(i => (
        <li key={i.code} className="d-switcher__item">
          <button
            onClick={() => setPriceCode(i.code)}
            className="d-switcher__button"
          >
            {i.name}
          </button>
        </li>
      ))}
    </ul>
  )
}

let mapStateToProps = state => ({ priceCode: state.dedicated.priceCode })

export default connect(mapStateToProps, { setPriceCode })(DSwitcher)
