import React from 'react';
import './Stages.css';
import ReactMarkdown from "react-markdown";

const Stages = ({body, lang}) => {
  return (
   <div className="stages">
     <div className="container">
        <h2 className="stages__title text--center">
          {body[`title_${lang}`]}
        </h2>
       <div className="stages__body">
         {body.stages_item.map((item, n) =>
         <div key={n} className="stages__item">
           <h3 className="stages__item-title">
             <span className="stages__item-counter">{n + 1}</span>
             {item.title_ru}
           </h3>
           {item.description_ru &&
             <p className="stages__item-description">
               {item.description_ru}
             </p>
           }
           <div className="stages__item-list">
             <ReactMarkdown
               source={item[`content_${lang}`]}
             />
           </div>

         </div>
         )}
       </div>
     </div>

   </div>
  )
};

export default Stages;