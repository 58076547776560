import React, { useEffect } from "react"
import "./NetworkMap.css"
import LargeMap from "./LargeMap/LargeMap"
import { connect } from "react-redux"
import {
  clearMapActivePoint,
  setMapActivePoint,
  setMapData,
  setMapFilter,
} from "../../state/mapReducer"
import MapFilter from "./MapFilter/MapFilter"
import MapModal from "./MapModal/MapModal"
import MapUI from "./MapUI/MapUI"
import MapFooter from "./MapFooter/MapFooter"

const NetworkMap = ({
  lang,
  utm = "",
  map,
  setMapData,
  setMapFilter,
  setMapActivePoint,
  clearMapActivePoint,
  strapiMapData,
}) => {
  useEffect(() => {
    setMapData(strapiMapData.map_item)
  }, [])

  return (
    <div className="network-map">
      <MapUI>
        <MapFilter
          filter={map.filter}
          changeFilter={setMapFilter}
          lang={lang}
        />
        <MapModal
          title={strapiMapData[`title_${lang}`]}
          description={strapiMapData[`description_${lang}`]}
          lang={lang}
          utm={utm}
          mapItems={map.items}
          filter={map.filter}
          activePoint={map.active}
          changeActivePoint={setMapActivePoint}
          toBack={clearMapActivePoint}
        />
      </MapUI>

      <LargeMap
        mapItems={map.items}
        filter={map.filter}
        lang={lang}
        changeActivePoint={setMapActivePoint}
        activePoint={map.active}
      />
      <MapFooter />
    </div>
  )
}

let mapStateToProps = state => ({
  map: state.map,
})

export default connect(mapStateToProps, {
  setMapData,
  setMapFilter,
  setMapActivePoint,
  clearMapActivePoint,
})(NetworkMap)
