import React from "react"
import "./CGroup.css"
import { useState } from "react"
import productKey from "../../../../language/productKey"
import CItem from "./CItem/CItem"
import CItemSlider from "./CItem/CItemSlider"
import Swiper from "react-id-swiper"
import Responsive from "../../../Responsive/Responsive"

const CGroup = ({ title, color, show, list, lang, utm = "" }) => {
  let [limit, setLimit] = useState(show)

  const paramsMobile = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    spaceBetween: 0,
    loop: false,
  }
  const paramsTab = {
    slidesPerView: 2,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    spaceBetween: 0,
    loop: false,
  }

  return (
    <div className={`container c-group c-group--${color}`}>
      <h2 className="c-group__title">{title}</h2>
      <Responsive options={["mobile"]}>
        <div className="c-group--slider">
          <Swiper {...paramsMobile}>
            {list.map((item, n) => (
              <div key={n} className="c-item--slider">
                <CItemSlider item={item} lang={lang} utm={utm} />
              </div>
            ))}
          </Swiper>
        </div>
      </Responsive>
      <Responsive options={["tab"]}>
        <div className="c-group--slider">
          <Swiper {...paramsTab}>
            {list.map((item, n) => (
              <div key={n} className="c-item--slider">
                <CItemSlider item={item} lang={lang} utm={utm} />
              </div>
            ))}
          </Swiper>
        </div>
      </Responsive>
      <Responsive options={["desktop"]}>
        <ul className="c-group__list">
          {list.map((item, n) =>
            !limit || n < limit ? (
              <CItem key={n} item={item} lang={lang} utm={utm} />
            ) : null
          )}
        </ul>

        {limit !== 0 && (
          <div className="c-group__button-row">
            <button
              className="c-group__button-more"
              onClick={() => setLimit(0)}
            >
              {list.length - limit === 1
                ? productKey[lang].more_one
                : productKey[lang].more.replace("$VALUE$", list.length - limit)}
              <span className="c-group__button-dots"></span>
              <span className="c-group__button-dots"></span>
              <span className="c-group__button-dots"></span>
            </button>
          </div>
        )}
      </Responsive>
    </div>
  )
}

export default CGroup
