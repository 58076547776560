import React from "react";
import Img from "gatsby-image";
import Link from "gatsby-link";
import blogKey from "../../../../language/blogKey";

const BlogRowGridItem = ({ data, lang }) => {
  return (
    <Link
      to={`/${lang}/blog/${data?.category.slug}/${data.slug}`}
      className="body__grid-item"
    >
      <div className="grid-item__body-img">
        {data.is_top && (
          <p className="body__grid-item__top">{blogKey[lang].top}</p>
        )}
        <Img
          className="grid-item__img"
          fluid={data.main_image.childImageSharp.fluid}
        />
      </div>
      <div className="grid-item__body-description">
        <p className="grid-item__date">{data.create_date}</p>
        <h3 className="grid-item__headline">{data[`title_${lang}`]}</h3>
        <p className="grid-item__description">{data[`preview_text_${lang}`]}</p>
      </div>
    </Link>
  );
};

export default BlogRowGridItem;
