import React from "react";
import { graphql } from "gatsby";
import PageDefault from "../components/PageTemplates/PageDefault";
import NetworkMap from "../components/PageTemplates/NetworkMapTemplate";
import WikiListTemplate from "../components/PageTemplates/WikiListTemplate";
import BlogListTemplate from "../components/PageTemplates/BlogListTemplate";

const Page = (props) => {
  let getPageTemplate = () => {
    switch (props.data.strapiPage.template) {
      case "default": {
        return (
          <PageDefault
            slug={props.pageContext.slug}
            lang={props.pageContext.lang}
            title={props.data.strapiPage.title_en}
            seo={props.data.strapiPage.seo}
            hero={props.data.strapiPage.hero}
            content={props.data.strapiPage.content}
            isContactLine={props.data.strapiPage.is_contact_line}
            isSubscribe={props.data.strapiPage.is_subscribe}
            data={{ ...props.data, ...props.pageContext.metaData }}
          />
        );
      }
      case "network_map": {
        return (
          <NetworkMap
            slug={props.pageContext.slug}
            lang={props.pageContext.lang}
            title={props.data.strapiPage.title_en}
            seo={props.data.strapiPage.seo}
            data={{ ...props.data, ...props.pageContext.metaData }}
          />
        );
      }
      case "blog": {
        return (
          // <BlogListTemplate
          //   slug={props.pageContext.slug}
          //   lang={props.pageContext.lang}
          //   title={props.data.strapiPage.title_ru}
          //   seo={props.data.strapiPage.seo}
          //   data={{ ...props.data, ...props.pageContext.metaData }}
          // />
          <PageDefault
            slug={props.pageContext.slug}
            lang={props.pageContext.lang}
            title={props.data.strapiPage.title_en}
            seo={props.data.strapiPage.seo}
            hero={props.data.strapiPage.hero}
            content={props.data.strapiPage.content}
            isContactLine={props.data.strapiPage.is_contact_line}
            isSubscribe={props.data.strapiPage.is_subscribe}
            data={{ ...props.data, ...props.pageContext.metaData }}
          />
        );
      }
      case "blog_list": {
        let params = new URLSearchParams(props.location.search);
        return (
          <BlogListTemplate
            navigate={props.navigate}
            params={params}
            slug={props.pageContext.slug}
            lang={props.pageContext.lang}
            data={{ ...props.data, ...props.pageContext.metaData }}
            title={props.data.strapiPage.title_ru}
            hero={props.data.strapiPage.hero}
            seo={props.data.strapiPage.seo}
            isSubscribe={props.data.strapiPage.is_subscribe}
          />
        );
      }
      case "wiki": {
        return (
          <WikiListTemplate
            slug={props.pageContext.slug}
            lang={props.pageContext.lang}
            title={props.data.strapiPage.title_ru}
            content={props.data.strapiPage.content}
            seo={props.data.strapiPage.seo}
            data={{ ...props.data, ...props.pageContext.metaData }}
            isSubscribe={props.data.strapiPage.is_subscribe}
          />
        );
      }
      default: {
        return (
          <PageDefault
            slug={props.pageContext.slug}
            lang={props.pageContext.lang}
            title={props.data.strapiPage.title_en}
            seo={props.data.strapiPage.seo}
            hero={props.data.strapiPage.hero}
            content={props.data.strapiPage.content}
            isContactLine={props.data.strapiPage.is_contact_line}
            isSubscribe={props.data.strapiPage.is_subscribe}
            data={{ ...props.data, ...props.pageContext.metaData }}
          />
        );
      }
    }
  };
  return <>{getPageTemplate()}</>;
};

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      title_en
      template
      is_contact_line
      is_subscribe
      seo {
        head_title_en
        link_canonical_en
        meta_descripiton_en
        meta_robots_en
      }
      hero {
        button_text_en
        button_url_en
        second_button_text_en
        second_button_url_en
        description_en
        title_en
        to_page {
          slug
        }
        to_second_page {
          slug
        }
        illustration {
          illustration_id
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        style
      }
      content {
        type
        title_en
        content_en
        description_en
        filter_point
        theme
        icon
        coverage_map_item {
          title_en
          content_en
        }
        faq_item {
          answer_en
          question_en
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        feature_item {
          name_en
          description_en
          icon
        }
        stages_item {
          title_en
          description_en
          content_en
        }
        nav_list {
          nav_content_en
          nav_title_en
          nested
        }
        left_column {
          description_en
          icon
          id
          name_en
        }
        right_column {
          description_en
          icon
          id
          name_en
        }
        about_item {
          name_en
          description_en
          icon
        }
        column
        contact_list_item {
          info_en
          name_en
          icon
        }
        before_text_en
        after_text_en
        label_check_agree_en
        label_check_privat_en
        label_email_en
        label_message_en
        label_name_en
        error_name_en
        label_last_name_en
        error_last_name_en
        error_message_en
        error_email_en
        error_check_en
        button_text_en
        button_url_en
        to_page {
          slug
        }
        second_button_text_en
        second_button_url_en
        to_second_page {
          slug
        }
        re_captcha
        re_captcha_sitekey
        product
        success_title_en
        success_text_en
        success_postscript_en
        service
        style
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        reviews_item {
          description_en
          name_en
          text_en
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        blogs {
          main_image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          is_top
          create_date(formatString: "DD MM YYYY")
          preview_text_en
          preview_text_ru
          slug
          title_en
          title_ru
          category
        }
        category {
          id
          slug
          title_en
          title_ru
        }
      }
    }
    allFile {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiBlog(filter: { category: { slug: { ne: null } }, published: { ne: false } }) {
      edges {
        node {
          slug
          title_en
          title_ru
          main_image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          is_top
          preview_text_en
          preview_text_ru
          author_en
          author_ru
          create_date(formatString: "DD MM YYYY")
          category {
            slug
          }
        }
      }
    }
    allStrapiWiki(filter: { published: { ne: false } }) {
      edges {
        node {
          slug
          published
          title_ru
          title_en
          position
          childs {
            slug
            published
          }
          head {
            slug
            published
          }
        }
      }
    }
    strapiBlog(slug: { eq: $slug }) {
      create_date
      blog_article_en
      main_image {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      preview_text_en
      title_en
      slug
      seo {
        head_title_en
        link_canonical_en
        meta_descripiton_en
        meta_robots_en
      }
    }
    allStrapiBlogCategory {
      edges {
        node {
          slug
          title_en
        }
      }
    }
  }
`;
