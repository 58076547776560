import React from "react"
import Feature from "./Feature"
import "./Features.css"

const Features = ({ title, items, utm = "" }) => {
  return (
    <div className={"features"}>
      <div className={"container"}>
        <h2 className="section-title text--center">{title}</h2>
        <div className={"features-container"}>
          {items.map(i => (
            <Feature
              key={i.iconId}
              utm={utm}
              title={i.name}
              description={i.description}
              iconId={i.iconId}
              link={i.link}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Features
