import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const Seo = ({ seo, lang, slug, defaultTitle, data, head= null }) => {
  let [lazy, setLazy] = useState(false);
  const {
    allSite,
    strapiLanguage: { simple_language },
  } = data;
  const {
    edges: [
      {
        node: {
          siteMetadata: { siteUrl },
        },
      },
    ],
  } = allSite;

  const langLocal = {
    ru: "ru_RU",
    en: "en_US",
  };

  const ogImage = useStaticQuery(
    graphql`
      query {
        imageSharp(resolutions: { originalName: { eq: "og_default.jpg" } }) {
          original {
            src
          }
        }
      }
    `
  );

  setTimeout(() => {
    setLazy(true);
  }, 1000);
  return (
    <Helmet>
      {seo && seo[`head_title_${lang}`] ? (
        <title>{seo[`head_title_${lang}`]}</title>
      ) : (
        <title>{defaultTitle}</title>
      )}
      {seo && seo[`meta_descripiton_${lang}`] && (
        <meta name="description" content={seo[`meta_descripiton_${lang}`]} />
      )}
      {/* {seo && seo[`meta_robots_${lang}`] && (
        <meta name="robots" content={seo[`meta_robots_${lang}`]} />
      )} */}
      {seo && seo[`link_canonical_${lang}`] ? (
        <link rel="canonical" href={seo[`link_canonical_${lang}`]} />
      ) : slug === "" ? (
        <link rel="canonical" href={`${siteUrl}/${lang}/`} />
      ) : (
        <link rel="canonical" href={`${siteUrl}/${lang}/${head ? head + '/' +  slug : slug}/`} />
      )}
      {simple_language.map(
        (language) =>
          language.slug !== lang &&
          (slug === "" ? (
            <link
              key={language.slug}
              rel="alternate"
              href={`${siteUrl}/${language.slug}/`}
              hreflang={language.slug}
            />
          ) : (
            <link
              key={language.slug}
              rel="alternate"
              href={`${siteUrl}/${language.slug}/${head ? head + '/' +  slug : slug}/`}
              hreflang={language.slug}
            />
          ))
      )}
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300&display=swap"
        rel="stylesheet"
      />
      <meta property="og:locale" content={langLocal['en_US']} />
      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content={(seo && seo[`head_title_${lang}`]) || defaultTitle}
      />
      <meta
        property="og:description"
        content={(seo && seo[`meta_descripiton_${lang}`]) || ""}
      />
      <meta
        property="og:url"
        content={
          slug === "" ? `${siteUrl}/${lang}/` : `${siteUrl}/${lang}/${head ? head + '/' +  slug : slug}/`
        }
      />
      <meta
        property="og:image"
        content={siteUrl + ogImage.imageSharp.original.src}
      />
      <meta property="og:site_name" content="Advanced Hosting" />
    </Helmet>
  );
};

export default Seo;
