const CHANGE_NAME = "CHANGE-NAME"
const CHANGE_EMAIL = "CHANGE-EMAIL"

const initState = {
  name: "",
  email: "",
}

const subscribeReducer = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_NAME: {
      return {
        ...state,
        name: action.name,
      }
    }
    case CHANGE_EMAIL: {
      return {
        ...state,
        email: action.email,
      }
    }
    default: {
      return state
    }
  }
}

export const changeName = name => ({ type: CHANGE_NAME, name })
export const changeEmail = email => ({ type: CHANGE_EMAIL, email })

export default subscribeReducer
