import React from "react";
import BlogRowGridItem from "./BlogRowGridItem";
import "./BlogRowGrid.css";

const BlogRowGrid = ({ blogs, lang }) => {
  return (
    <div className="blog-row-grid">
      {blogs.map((i) => (
        <BlogRowGridItem key={i.slug} data={i} lang={lang} />
      ))}
    </div>
  );
};

export default BlogRowGrid;
