import React from "react"
import "./Breadcrumbs.css"
import { Link } from "gatsby"

const Breadcrumbs = ({ lang, slug, head, utm = "", data }) => {
  const { breadcrumbs } = data
  return (
    <div className="breadcrumbs">
      {breadcrumbs.is && (
        <div className="container breadcrumbs-container">
          <p className="breadcrumbs__title">
            {breadcrumbs.root[`name_${lang}`]}
          </p>
          {(breadcrumbs.pages.length > 1 || breadcrumbs.page.subcategory) && (
            <ul className="breadcrumbs__list">
              {breadcrumbs.pages.map(page => (
                <li key={page.slug} className="breadcrumbs__item">
                  {page.slug === breadcrumbs.slug ? (
                    <span className="breadcrumbs__current">
                      {page[`title_${lang}`]}
                    </span>
                  ) : page.template === "sub_pages" ? (
                    <Link
                      className="breadcrumbs__link"
                      to={`/${lang}/${page.slug}/${page.current_sub_page.slug}/${utm}`}
                    >

                      {page[`title_${lang}`]}
                    </Link>
                  ) : (
                    <Link
                      className="breadcrumbs__link"
                      to={`/${lang}/${page.slug}/${utm}`}
                    >
                      {page[`title_${lang}`]}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

export default Breadcrumbs
