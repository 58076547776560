import React from "react"
import { useEffect } from "react"

const ReCaptcha = ({ lang, sitekey, callback, getResponse }) => {
  useEffect(() => {
    let script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=${lang}`
    script.setAttribute("async", "async")
    script.setAttribute("defer", "defer")

    let firstScriptTag = document.getElementsByTagName("script")[0]
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag)

    window.onloadCallback = function () {
      window.grecaptcha.render("grecaptcha", {
        sitekey: sitekey,
        callback: response => {
          if (getResponse) getResponse(response)
          callback(true)
        },
        "expired-callback": () => {
          callback(false)
        },
        "error-callback": () => {
          callback(false)
        },
      })
    }
  }, [])
  return <div className="g-recaptcha" id="grecaptcha"></div>
}

export default ReCaptcha
