import React from "react"
import { useEffect } from "react"
import LightLayoutPage from "../components/LayoutPage/LightLayoutPage"
import ContactLine from "../components/ContactLine/ContactLine"
import DForm from "../components/Products/DedicatedServer/DForm/DForm"
import DBreadcrumbs from "../components/Products/DedicatedServer/DBreadcrumbs/DBreadcrumbs"
import { navigate } from "gatsby"

const Checkout = ({ pageContext, location }) => {
  useEffect(() => {
    if (!location.state || !location.state.name) {
      navigate(`/${pageContext.lang}/${pageContext.slug}/`)
    }
  })
  let breadcrumbs = (
    <DBreadcrumbs
      lang={pageContext.lang}
      current="checkout"
      slug={pageContext.slug}
    />
  )

  return (
    <LightLayoutPage
      lang={pageContext.lang}
      slug={`${pageContext.slug}/step-3`}
      context={location.state}
      breadcrumbs={breadcrumbs}
      data={pageContext.metaData}
    >
      <div className="d-form">
        {location.state && location.state.name && (
          <DForm
            lang={pageContext.lang}
            slug={pageContext.slug}
            data={pageContext.metaData}
          />
        )}
        <ContactLine lang={pageContext.lang} data={pageContext.metaData} />
      </div>
    </LightLayoutPage>
  )
}
export default Checkout
