export default {
  ru: {
    units: {
      mb: "МБ",
      gb: "ГБ",
      tb: "ТБ",
      mbps: "Mбит/c",
      gbps: "Гбит/c",
      tbps: "Тбит/c",
      mbit: "Mбит",
      gbit: "Гбит",
      tbit: "Тбит",
    },
    cpu: "ЦПУ",
    ram: "ОЗУ",
    disk: "Диски",
    disk_limit: "(максимум $VALUE$)",
    without_disk: "Диски не выбраны",
    raid: "Рэйд (программный рэйд)",
    without_raid: "Без RAID-карты",
    management: "Администрирование",
    network: "Подключение",
    bandwidth: "Трафик",
    bandwidth_overusage: "Превышение",
    ssd: "SSD",
    hdd: "HDD",
    ddr4: "DDR4",
    ddr3: "DDR3",
    currency: {
      us: "USD",
      eu: "EUR",
    },
    price: {
      us: "$VALUE$ $",
      eu: "$VALUE$ €",
    },
    country: {
      us: "США",
      eu: "Нидерланды",
    },
    per_month: "/месяц",
    per_hour: "/час",
    from: "От ",
    order: "Заказать",
    get: "Получить",
    more: "Ещё $VALUE$ конфигураций",
    more_one: "Ещё одна конфигурация",
    continue: "Продолжить",
    sale: "Бесплатно",
    bestseller: "POPULAR",
    or: "или",
    of_traffic: "трафика",
    disk_space: "места под файлы",
    unlimited: "Неограниченно",
    amount: "Количество",
    domains: "доменов",
    free: "Бесплатно ",
    month: ["месяц", "месяца", "месяцев"],
    day: ["день", "дня", "дней"],
    breadcrumbs: [
      "1. Выделенные серверы",
      "2. Настройка сервера",
      "3. Оформление заказа",
    ],
    dedicated_server: "Выделенные серверы",
  },
  en: {
    units: {
      mb: "MB",
      gb: "GB",
      tb: "TB",
      mbps: "Mbit/s",
      gbps: "Gbit/s",
      tbps: "Tbit/s",
      mbit: "Mbit",
      gbit: "Gbit",
      tbit: "Tbit",
    },
    cpu: "CPU",
    ram: "RAM",
    disk: "Drives ",
    disk_limit: "(maximum $VALUE$)",
    without_disk: "No discs selected",
    raid: "Raid (software raid)",
    without_raid: "Without a RAID card",
    management: "Administration",
    network: "Connectivity",
    bandwidth: "Traffic",
    bandwidth_overusage: "Overusage",
    ssd: "SSD",
    hdd: "HDD",
    ddr4: "DDR4",
    ddr3: "DDR3",
    currency: {
      us: "USD",
      eu: "EUR",
    },
    price: {
      us: "$$VALUE$",
      eu: "€$VALUE$",
    },
    country: {
      us: "USA",
      eu: "Netherlands",
    },
    per_month: "/month",
    per_hour: "/hour",
    from: "From ",
    order: "Order",
    get: "Get",
    more: "$VALUE$ more configurations",
    more_one: "One more configuration",
    continue: "Continue",
    sale: "Free",
    bestseller: "POPULAR",
    or: "or",
    of_traffic: "of traffic",
    disk_space: "disk usage",
    unlimited: "Unlimited",
    amount: "Amount",
    domains: "domains",
    free: "Free",
    month: ["month", "months", "months"],
    day: ["day", "days", "days"],
    breadcrumbs: [
      "1. Dedicated servers",
      "2. Server configuration",
      "3. Ordering",
    ],
    dedicated_server: "Dedicated Servers",
  },
  new: {
    units: {
      mb: "MB",
      gb: "GB",
      tb: "TB",
      mbps: "Mbps",
      gbps: "Gbps",
      tbps: "Tbps",
      mbit: "Mbit",
      gbit: "Gbit",
      tbit: "Tbit",
    },
    title_ru: "Не нашли подходящий тариф? - Напишите нам!",
    title_en: "Didn't find a suitable tariff? - Write to us!",
    button_ru: "Написать сообщение",
    button_en: "Send message",
  }
}
