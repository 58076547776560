import React, { useEffect, useState } from "react"
import "./ScrollTop.css"
import Icon from "../Sprite/Icon"
import scrollKey from "../../language/scrollKey"

const ScrollTop = ({ lang }) => {
  let [isShow, setIsShow] = useState(false)

  useEffect(() => {
    let oldShow = null
    const scrollingButton = () => {
      let show = window.pageYOffset > window.innerHeight

      if (oldShow !== show) {
        oldShow = show
        setIsShow(show)
      }
    }
    scrollingButton()
    window.document.addEventListener("scroll", scrollingButton)
    return () => {
      window.document.removeEventListener("scroll", scrollingButton)
    }
  }, [])

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })

  return (
    <>
      {isShow && (
        <button className="scroll-top" onClick={scrollTop}>
          <Icon iconId="back" classes="scroll-top__icon" />
          <span className="scroll-top__text">{scrollKey[lang].scroll_top}</span>
        </button>
      )}
    </>
  )
}

export default ScrollTop
