import { gsap } from "./esm"
import {
  TimelineMax,
  Back,
  Power1,
  Power2,
  Power3,
  Power4,
  Elastic,
  Circ,
  SlowMo,
  CustomEase,
  Sine,
} from "./esm/all"

gsap.registerPlugin(CustomEase, SlowMo)

export default {
  //anycast-cdn.svg
  anycast_cdn(stopCallBack) {
    const anim = new TimelineMax()
      .from("#cloud-red", 1, {
        ease: Back.easeOut.config(1.2),
        autoAlpha: 0,
        x: 90,
      })
      .from(
        "#cloud-pink",
        1.5,
        { ease: Back.easeOut.config(1.5), autoAlpha: 0, x: 40 },
        "-=1"
      )
      .from("#cloud-pink-2", 1, { autoAlpha: 0, x: 30 }, "-=1.5")
      .from("#cloud-white", 1, { autoAlpha: 0, x: 60 }, "-=1.5")
      .from(
        "#birds",
        1.5,
        { ease: Power2.easeOut, autoAlpha: 0, x: -50 },
        "-=1"
      )
      .from(
        "#car",
        2,
        {
          ease: Back.easeOut.config(1.2),
          transform: "matrix(1.15, 0, 0, 1.15, -35, 10)",
        },
        "-=2"
      )
      .from(
        "#trees",
        4,
        {
          ease: Elastic.easeOut,
          skewX: 8,
          width: 288,
          x: 0,
          transformOrigin: "center bottom",
          immediateRender: false,
          onComplete: stopCallBack,
        },
        "-=2"
      )
      .from("#smoke", 1, { scale: 0, transformOrigin: "0px 25px" }, "-=4")
      .from("#lines", 1.5, { autoAlpha: 0 }, "-=3")
      .from("#lamp-light-1", 1, { autoAlpha: 0 }, "-=3.5")
      .from("#lamp-light-2", 1, { autoAlpha: 0 }, "-=3.5")
    return anim
  },

  //dedicated-servers.svg
  dedicated_servers(stopCallBack) {
    const anim = new TimelineMax()
      .fromTo(
        "#right-servers3",
        1.5,
        { x: 7 },
        {
          ease: Back.easeInOut.config(1),
          x: 0,
          transformOrigin: "right bottom",
          immediateRender: false,
          onComplete: stopCallBack,
        }
      )
      .fromTo(
        "#right-servers2",
        1.5,
        { x: 15 },
        {
          ease: Back.easeInOut.config(1),
          x: 0,
          transformOrigin: "right bottom",
          immediateRender: false,
        },
        "-=1.5"
      )
      .fromTo(
        "#right-servers1",
        1.5,
        { x: 27 },
        {
          ease: Back.easeInOut.config(1),
          x: 0,
          transformOrigin: "right bottom",
          immediateRender: false,
        },
        "-=1.5"
      )
      .fromTo(
        "#left-servers3",
        1.5,
        { x: -7 },
        {
          ease: Back.easeInOut.config(1),
          x: 0,
          transformOrigin: "right bottom",
          immediateRender: false,
        },
        "-=1.5"
      )
      .fromTo(
        "#left-servers2",
        1.5,
        { x: -15 },
        {
          ease: Back.easeInOut.config(1),
          x: 0,
          transformOrigin: "right bottom",
          immediateRender: false,
        },
        "-=1.5"
      )
      .fromTo(
        "#left-servers1",
        1.5,
        { x: -27 },
        {
          ease: Back.easeInOut.config(1),
          x: 0,
          transformOrigin: "right bottom",
          immediateRender: false,
        },
        "-=1.5"
      )
      .from(
        "#cloud-green1",
        1,
        { ease: Back.easeOut.config(3), autoAlpha: 0, x: 10 },
        "-=1.5"
      )
      .from(
        "#cloud-green2",
        1,
        { ease: Back.easeOut.config(3), autoAlpha: 0, x: -20 },
        "-=1.5"
      )
      .fromTo(
        "#ufo",
        0.8,
        { autoAlpha: 0, transform: "matrix(0, 0.0000001, 0, 0, 410, 120)" },
        {
          autoAlpha: 1,
          ease: Power3.easeIn,
          transform: "matrix(0.19, 0.025, 0, 0.2, 200, 50)",
        },
        "-=1.3"
      )
      .fromTo(
        "#ufo",
        1,
        { transform: "matrix(0.19, 0.025, 0, 0.2, 200, 50)" },
        {
          ease: Back.easeOut.config(1.4),
          transform: "matrix(1, 0, 0, 1, 0, -1)",
          immediateRender: false,
        },
        "-=0.5"
      )
      .from(
        "#step1",
        1.1,
        {
          ease: Back.easeOut.config(2),
          scaleY: 0,
          transformOrigin: "center top",
        },
        "-=0.5"
      )
      .from(
        "#step2",
        1.1,
        {
          ease: Back.easeOut.config(2),
          scale: 0,
          transformOrigin: "right top",
        },
        "-=1"
      )
      .from(
        "#step3",
        1.1,
        { ease: Back.easeOut.config(2), scale: 0, transformOrigin: "left top" },
        "-=1"
      )
      .from(
        "#pilot",
        1,
        {
          ease: Power4.easeOut,
          scale: 0,
          autoAlpha: 0,
          transformOrigin: "center bottom",
        },
        "-=1.5"
      )
      .from("#light1", 1, { autoAlpha: 0 }, "-=1.5")
      .from("#light2", 1, { autoAlpha: 0 }, "-=1.5")
      .fromTo(
        "#server-on-sdw",
        1,
        { autoAlpha: 0.6, display: "block" },
        { autoAlpha: 0, display: "block" },
        "-=1.4"
      )
      .fromTo(
        "#server-on-light",
        1,
        { fill: "rgb(55,71,79)", scaleX: 0, display: "block" },
        {
          ease: Back.easeOut.config(1),
          fill: "rgb(255,82,82)",
          scaleX: 1,
          display: "block",
          transformOrigin: "left bottom",
        },
        "-=1.3"
      )
    return anim
  },

  //anycast-dns.svg
  anycast_dns(stopCallBack) {
    const anim = new TimelineMax()
      .from("#sputnik-small", 1, {
        ease: Circ.easeOut,
        rotation: -20,
        svgOrigin: "238.5 238.5",
        onComplete: stopCallBack,
      })
      .from("#sputnik", 1, { rotation: -20, svgOrigin: "238.5 238.5" }, "-=1")
      .from(
        "#cosmonaut",
        2,
        {
          ease: Back.easeOut.config(1.5),
          scale: 0,
          rotation: -20,
          transformOrigin: "left bottom",
        },
        "-=1"
      )
      .from(
        "#continents",
        2,
        {
          ease: Back.easeOut.config(1.5),
          transform: "matrix(1.1, 0, 0, 1, -5, 0)",
        },
        "-=2"
      )
      .from(
        "#continents-mask",
        2,
        {
          ease: Back.easeOut.config(1.5),
          transform: "matrix(0.96, 0, 0, 1, -14, 0)",
        },
        "-=2"
      )
      .from(
        "#antenna",
        2,
        {
          ease: Power3.easeOut,
          scale: 0.7,
          x: -20,
          transformOrigin: "right center",
        },
        "-=2"
      )
      .from("#star", 2, { ease: Back.easeOut.config(1.5), x: -5 }, "-=2")
      .from(
        "#starmask",
        2,
        { ease: Back.easeOut.config(1.5), x: 3, y: -2 },
        "-=2"
      )
      .from(
        "#dot1",
        2,
        { ease: Power3.easeOut, rotation: -2, svgOrigin: "238.5 238.5" },
        "-=2"
      )
      .from(
        "#dot2",
        2,
        { ease: Power3.easeOut, rotation: -1, svgOrigin: "238.5 238.5" },
        "-=2"
      )
      .from(
        "#dot3",
        2,
        { ease: Power3.easeOut, rotation: -1, svgOrigin: "238.5 238.5" },
        "-=2"
      )
      .from(
        "#dot4",
        2,
        { ease: Power3.easeOut, rotation: 2, svgOrigin: "238.5 238.5" },
        "-=2"
      )
      .from(
        "#dot5",
        2,
        { ease: Power3.easeOut, rotation: 3, svgOrigin: "238.5 238.5" },
        "-=2"
      )
      .from(
        "#cloud-1",
        2,
        {
          ease: Back.easeOut.config(1.5),
          rotation: -20,
          svgOrigin: "238.5 238.5",
        },
        "-=2"
      )
      .from(
        "#cloud-2",
        2,
        { ease: Power3.easeOut, rotation: 10, svgOrigin: "238.5 238.5" },
        "-=2"
      )
      .from(
        "#cloud-3",
        2,
        { ease: Power3.easeOut, rotation: -15, svgOrigin: "238.5 238.5" },
        "-=2"
      )
      .from(
        "#cloud-4",
        2,
        { ease: Power3.easeOut, rotation: 10, svgOrigin: "238.5 238.5" },
        "-=2"
      )
      .from("#sputnik-waves", 1, { autoAlpha: 0 }, "-=1")
      .from("#antenna-waves-1", 1, { autoAlpha: 0 }, "-=1")
      .from("#antenna-waves-2", 1, { autoAlpha: 0 }, "-=1")

    return anim
  },
  //domains.svg
  domains(stopCallBack) {
    const anim = new TimelineMax()
      .from("#dom-car", 1.5, {
        ease: Back.easeOut.config(1),
        transform: "matrix(1.17, 0, 0.01, 1.17, -10, -30)",
        onComplete: stopCallBack,
      })
      .from(
        "#dom-city",
        1.5,
        { ease: Power2.easeOut, transform: "matrix(1.1, 0, 0, 1.1, -30, -30)" },
        "-=1.5"
      )
      .from(
        ["#box-1", "#box-2", "#transporter"],
        2,
        { ease: Back.easeOut.config(1.5), x: 50 },
        "-=1.5"
      )
      .from(
        "#dom-curtain",
        1.5,
        {
          ease: Back.easeOut.config(2),
          scaleX: 0.4,
          skewX: 2,
          skewY: -5,
          rotation: 3,
          transformOrigin: "left bottom",
        },
        "-=2"
      )
      .from(
        "#shed",
        1,
        {
          ease: Back.easeOut.config(2),
          scaleY: 0.8,
          skewX: 5,
          skewY: -5,
          rotation: 5,
          transformOrigin: "left top",
        },
        "-=2"
      )
      .from(
        "#dom-support",
        1,
        {
          ease: Back.easeOut.config(2),
          scaleY: 1.5,
          rotation: -5,
          transformOrigin: "left bottom",
        },
        "-=2"
      )
      .from(
        "#dom-market",
        1,
        {
          ease: Back.easeOut.config(2),
          y: -15,
          transformOrigin: "left bottom",
        },
        "-=2"
      )
      .from(
        "#plate",
        2,
        { ease: Elastic.easeOut, rotation: 20, transformOrigin: "center top" },
        "-=1.5"
      )
    return anim
  },
  //colocation.svg
  colocation(stopCallBack) {
    const anim = new TimelineMax()
      .from("#umbrella", 0.5, {
        ease: SlowMo && SlowMo.ease.config(0.1, 0.4, false),
        y: 10,
        x: 20,
        scaleX: -0.2,
        scaleY: 2.5,
        rotation: 8,
        transformOrigin: "center top",
        onComplete: stopCallBack,
      })
      .from(
        "#pin",
        0.5,
        {
          ease: SlowMo && SlowMo.ease.config(0.1, 0.4, false),
          y: 8,
          x: 20,
          rotation: 7,
          transformOrigin: "center top",
        },
        "-=0.5"
      )
      .from(
        "#col-handle",
        1,
        {
          ease: Power1.easeInOut,
          y: 20,
          x: 8,
          rotation: 5,
          transformOrigin: "center top",
        },
        "-=0.5"
      )
      .from(
        "#col-body",
        2,
        {
          ease: Back.easeOut.config(2),
          transform: "matrix(1, 0.05, -0.01, 1, -10, 10)",
        },
        "-=0.7"
      )
      .from(
        "#col-head",
        1,
        {
          ease: Power2.easeOut,
          rotation: -3,
          transformOrigin: "center bottom",
        },
        "-=1"
      )
      .from(
        "#col-lines",
        1,
        {
          ease: Back.easeOut.config(0.9),
          scaleX: 0.3,
          transformOrigin: "left bottom",
        },
        "-=2"
      )
      .from(
        "#vent",
        2,
        {
          ease: Back.easeOut.config(2),
          rotation: 360,
          transformOrigin: "center center",
        },
        "-=2"
      )
      .from(
        "#col-server-3",
        1,
        { ease: Back.easeOut.config(1), x: 5, transformOrigin: "right bottom" },
        "-=1.8"
      )
      .from(
        "#col-server-2",
        1,
        {
          ease: Back.easeOut.config(1),
          x: 12,
          transformOrigin: "right bottom",
        },
        "-=1.8"
      )
      .from(
        "#col-server-1",
        1,
        {
          ease: Back.easeOut.config(1),
          x: 18,
          transformOrigin: "right bottom",
        },
        "-=1.8"
      )
      .from(
        "#col-server-6",
        1,
        { ease: Back.easeOut.config(1), x: -5, transformOrigin: "left bottom" },
        "-=1.8"
      )
      .from(
        "#col-server-5",
        1,
        {
          ease: Back.easeOut.config(1),
          x: -12,
          transformOrigin: "left bottom",
        },
        "-=1.8"
      )
      .from(
        "#col-server-4",
        1,
        {
          ease: Back.easeOut.config(1),
          x: -18,
          transformOrigin: "left bottom",
        },
        "-=1.8"
      )
      .fromTo(
        "#mouth",
        1,
        {
          scaleY: 0.7,
          scaleX: 0.94,
          y: -1,
          x: -0.5,
          rotation: 3,
          fill: "rgb(255,82,82)",
          transformOrigin: "center center",
        },
        {
          ease: Back.easeOut.config(1),
          fill: "rgb(255,255,255)",
          scaleY: 1,
          scaleX: 1,
          y: 0,
          x: 0,
          rotation: 0,
          transformOrigin: "center center",
        },
        "-=0.5"
      )
      .from("#eyes", 1, { y: 1, transformOrigin: "center bottom" }, "-=1")
      .from(
        "#col-hat",
        1,
        { ease: Power4.easeOut, y: 1.5, transformOrigin: "center bottom" },
        "-=1"
      )
      .from(
        ["#eyebrow-1", "#eyebrow-2"],
        1,
        {
          ease: Back.easeOut.config(1),
          transform: "matrix(1, 0.009, 0, 1, 0, 0.5)",
        },
        "-=1"
      )
      .from(
        "#col-beard",
        1,
        {
          ease: Back.easeOut.config(1),
          transform: "matrix(1, 0.009, 0, 1, 0, -2.5)",
        },
        "-=1"
      )
      .fromTo(
        "#eyes",
        0.2,
        { autoAlpha: 0, scaleY: 0.1, transformOrigin: "center bottom" },
        {
          autoAlpha: 1,
          scaleY: 1.1,
          repeatDelay: 2,
          repeat: 2,
          immediateRender: false,
        },
        "-=1"
      )
    return anim
  },
  //ip-transit.svg
  ip_transit(stopCallBack) {
    const anim = new TimelineMax()
      .from("#ip-cloud-3", 1.5, {
        ease: Back.easeOut.config(2),
        x: 70,
        transformOrigin: "left bottom",
        onComplete: stopCallBack,
      })
      .from(
        "#ip-cloud-2",
        1,
        {
          ease: Back.easeOut.config(1.5),
          x: 30,
          transformOrigin: "left bottom",
        },
        "-=1.5"
      )
      .from(
        "#ip-cloud-1",
        2,
        { ease: Power2.easeOut, x: 50, transformOrigin: "left bottom" },
        "-=1.5"
      )
      .from(
        "#ip-waves",
        1.5,
        {
          ease: Power2.easeOut,
          scaleY: 0.8,
          scaleX: 0.5,
          transformOrigin: "center top",
        },
        "-=2"
      )
      .from(
        "#ip-cargo",
        2.5,
        {
          ease: Back.easeOut.config(1),
          x: 100,
          transformOrigin: "left bottom",
        },
        "-=2"
      )
      .from(
        ["#ip-wheel-1", "#ip-wheel-2"],
        2.5,
        {
          ease: Back.easeOut.config(1),
          rotation: 720,
          transformOrigin: "center center",
        },
        "-=2.5"
      )
      .from(
        "#ip-knife",
        1.7,
        { ease: Power2.easeInOut, y: 60, transformOrigin: "left bottom" },
        "-=2.5"
      )
      .from(
        "#ip-crane",
        2,
        { ease: Power2.easeOut, x: 15, transformOrigin: "left bottom" },
        "-=2.5"
      )
      .from(
        "#ip-container",
        2,
        { ease: Power2.easeOut, x: 20, y: 23, transformOrigin: "left top" },
        "-=2.5"
      )
      .from(
        "#ip-engine",
        2,
        { ease: Power2.easeOut, x: 20, transformOrigin: "left top" },
        "-=2.5"
      )

    return anim
  },
  //administration.svg
  administration(stopCallBack) {
    const anim = new TimelineMax()
      .from("#admin", 1.5, {
        ease: Back.easeInOut.config(1),
        transform: "matrix(0.99, 0.03, -0.03, 0.99, 0, 5)",
        transformOrigin: "right bottom",
        onComplete: stopCallBack,
      })
      .from(
        "#manipulator",
        1.5,
        {
          ease: Back.easeInOut.config(0.8),
          y: -80,
          transformOrigin: "right bottom",
        },
        "-=1.5"
      )
      .from(
        "#arm2",
        1,
        {
          ease: Back.easeOut.config(2),
          rotation: 30,
          transformOrigin: "right top",
        },
        "-=0.5"
      )
      .from(
        "#claw1",
        0.5,
        {
          ease: Back.easeInOut.config(3),
          rotation: 20,
          y: 3,
          repeat: 1,
          transformOrigin: "right top",
          immediateRender: false,
        },
        "-=0.5"
      )
      .from(
        "#claw2",
        0.5,
        {
          ease: Back.easeInOut.config(3),
          rotation: -20,
          y: -3,
          repeat: 1,
          transformOrigin: "right bottom",
          immediateRender: false,
        },
        "-=1"
      )
      .from(
        "#body",
        2,
        {
          ease: Power3.easeOut,
          transform: "matrix(0.95, 0, 0, 1, 20, -4)",
          transformOrigin: "right bottom",
        },
        "-=1.5"
      )
      .from(
        "#head",
        2,
        {
          ease: Power3.easeOut,
          x: 12,
          y: -4,
          scaleY: 0.96,
          rotation: 3,
          transformOrigin: "right bottom",
        },
        "-=2"
      )
      .from(
        "#hand",
        1,
        {
          ease: Power3.easeOut,
          transform: "matrix(1, 0, 0, 1, 8, -5)",
          transformOrigin: "right bottom",
        },
        "-=2"
      )
      .from(
        "#line1",
        1.5,
        {
          ease: Power3.easeOut,
          scaleX: 0.2,
          skewX: -4,
          x: 2,
          transformOrigin: "left top",
        },
        "-=3"
      )
      .from(
        "#line4",
        1.5,
        {
          ease: Power3.easeOut,
          scaleX: 0.2,
          skewX: -4,
          x: 2,
          transformOrigin: "left top",
        },
        "-=3"
      )
      .from(
        "#line3",
        1.5,
        {
          ease: Back.easeInOut.config(1),
          scaleX: 0.2,
          skewX: -4,
          x: 2,
          transformOrigin: "left top",
        },
        "-=3"
      )
      .from(
        "#but-admin1",
        0.5,
        {
          ease: Power3.easeInOut,
          fill: "rgb(255,82,82)",
          opacity: 0.8,
          repeat: 1,
          immediateRender: false,
        },
        "-=1.5"
      )
      .from(
        "#but-admin2",
        0.5,
        {
          ease: Power3.easeInOut,
          fill: "rgb(77,182,172)",
          opacity: 0.8,
          repeat: 1,
          immediateRender: false,
        },
        "-=2"
      )
      .from(
        "#but-admin3",
        0.5,
        {
          ease: Power3.easeInOut,
          fill: "rgb(255,82,82)",
          opacity: 0.8,
          immediateRender: false,
        },
        "-=1"
      )
      .from(
        "#but-admin4",
        0.5,
        {
          ease: Power3.easeInOut,
          fill: "rgb(77,182,172)",
          opacity: 0.8,
          immediateRender: false,
        },
        "-=1.5"
      )
      .from(
        "#but-admin5",
        0.5,
        {
          ease: Power3.easeInOut,
          fill: "rgb(255,82,82)",
          opacity: 0.8,
          repeat: 1,
          immediateRender: false,
        },
        "-=2"
      )
      .from(
        "#but-admin6",
        0.5,
        {
          ease: Power3.easeInOut,
          fill: "rgb(77,182,172)",
          opacity: 0.8,
          immediateRender: false,
        },
        "-=1"
      )

    return anim
  },
  //vds.svg
  vds(stopCallBack) {
    const anim = new TimelineMax()
      .fromTo(
        "#vds-cloud4",
        1.5,
        {
          scale: 1.3,
          y: 20,
          ease: Power4.easeOut,
          transformOrigin: "center bottom",
          onComplete: stopCallBack,
        },
        { scale: 1, y: 0 }
      )
      .from(
        "#vds-server-main",
        1.5,
        {
          ease: Back.easeOut.config(0.5),
          y: 60,
          transformOrigin: "center bottom",
        },
        "-=1.5"
      )
      .from(
        "#vds-mask2",
        1.5,
        {
          ease: Back.easeOut.config(0.5),
          y: -10,
          scaleY: 0.5,
          transformOrigin: "center center",
        },
        "-=1.5"
      )
      .from(
        "#vds-server-sdw",
        1.5,
        {
          ease: Back.easeOut.config(1),
          y: 20,
          autoAlpha: 0,
          transformOrigin: "center bottom",
        },
        "-=1.5"
      )
      .from(
        "#vds-cloud-big",
        1.5,
        { scale: 0.8, ease: Power1.easeOut, transformOrigin: "center bottom" },
        "-=1.5"
      )
      .from(
        "#vds-server4",
        2,
        { y: 20, ease: Power4.easeOut, transformOrigin: "center bottom" },
        "-=1.5"
      )
      .from(
        "#vds-cloud7",
        2,
        {
          y: 20,
          scaleY: 0.8,
          ease: Power2.easeOut,
          transformOrigin: "center bottom",
        },
        "-=2"
      )
      .from(
        "#vds-server5",
        2,
        { y: 20, ease: Power3.easeOut, transformOrigin: "center bottom" },
        "-=1.8"
      )
      .from(
        [
          "#vds-cloud2",
          "#vds-cloud3",
          "#vds-cloud5",
          "#vds-cloud6",
          "#vds-cloud8",
          "#vds-cloud9",
          "#vds-cloud10",
        ],
        2,
        {
          y: 10,
          scale: 0.9,
          ease: Power2.easeOut,
          transformOrigin: "center bottom",
        },
        "-=2"
      )
      .from(
        ["#vds-server6", "#vds-server7"],
        3,
        { y: 40, ease: Power4.easeOut, transformOrigin: "center bottom" },
        "-=2"
      )
    return anim
  },
  //video-cdn.svg
  video_cdn(stopCallBack) {
    const anim = new TimelineMax()
      .from(["#video-cdn-speedometr", "#video-cdn-wire1"], 1, {
        y: 20,
        ease:
          window &&
          CustomEase &&
          CustomEase.create(
            "custom",
            "M0,0 C0.084,0.038 0.2,0.06 0.358,0.208 0.55,0.388 0.366,1 1,1"
          ),
        transformOrigin: "center bottom",
        onComplete: stopCallBack,
      })
      .from(
        "#video-cdn-wire3",
        1,
        {
          y: -90,
          x: 45,
          ease:
            window &&
            CustomEase &&
            CustomEase.create(
              "custom",
              "M0,0 C0.084,0.038 0.2,0.06 0.358,0.208 0.55,0.388 0.366,1 1,1"
            ),
          transformOrigin: "right bottom",
        },
        "-=1"
      )
      .from(
        "#video-cdn-wire2",
        1,
        {
          y: -90,
          x: -45,
          ease:
            window &&
            CustomEase &&
            CustomEase.create(
              "custom",
              "M0,0 C0.084,0.038 0.2,0.06 0.358,0.208 0.55,0.388 0.366,1 1,1"
            ),
          transformOrigin: "left bottom",
        },
        "-=0.8"
      )
      .fromTo(
        "#video-cdn-arrow",
        1,
        { rotation: -45, transformOrigin: "center bottom" },
        {
          rotation: 45,
          ease:
            window &&
            CustomEase &&
            CustomEase.create(
              "custom",
              "M0,0 C0.286,0.372 0.108,0.031 0.432,0.036 0.694,0.04 0.448,1 1,1"
            ),
        },
        "-=1"
      )
      .fromTo(
        "#video-cdn-arrow-sdw",
        1,
        { rotation: -45, x: -10, y: 5, transformOrigin: "center bottom" },
        {
          rotation: 45,
          x: -3,
          y: 5,
          ease:
            window &&
            CustomEase &&
            CustomEase.create(
              "custom",
              "M0,0 C0.286,0.372 0.108,0.031 0.432,0.036 0.694,0.04 0.448,1 1,1"
            ),
        },
        "-=1"
      )
    return anim
  },
  //static-cdn.svg
  static_cdn(stopCallBack) {
    const anim = new TimelineMax()
      .set(
        [
          "#puzzle-sdw1",
          "#puzzle-sdw2",
          "#puzzle-sdw3",
          "#puzzle-sdw4",
          "#puzzle-sdw5",
          "#puzzle-sdw6",
        ],
        { display: "block" }
      )

      .from("#puzzle1", 1, {
        y: -80,
        x: -30,
        rotation: -10,
        scale: 1.1,
        ease: Power4.easeOut,
        transformOrigin: "center center",
        onComplete: stopCallBack,
      })
      .fromTo(
        "#puzzle-sdw1",
        1,
        {
          y: 20,
          x: 10,
          scale: 1.3,
          autoAlpha: 0.1,
          transformOrigin: "center center",
        },
        { y: 0, x: 0, scale: 1, autoAlpha: 0.3, ease: Power4.easeOut },
        "-=1"
      )
      .from(
        "#puzzle6",
        1,
        {
          y: -80,
          x: 30,
          rotation: 2,
          scale: 1.3,
          ease: Power2.easeOut,
          transformOrigin: "center center",
        },
        "-=1"
      )
      .fromTo(
        "#puzzle-sdw6",
        1,
        {
          y: 20,
          x: 20,
          scale: 1.3,
          autoAlpha: 0.1,
          transformOrigin: "center center",
        },
        { y: 0, x: 0, scale: 1, autoAlpha: 0.3, ease: Power4.easeOut },
        "-=1"
      )
      .from(
        "#puzzle2",
        1,
        {
          y: -70,
          x: 30,
          rotation: 8,
          scale: 1.2,
          ease: Power3.easeOut,
          transformOrigin: "center center",
        },
        "-=1"
      )
      .fromTo(
        "#puzzle-sdw2",
        1,
        {
          y: 20,
          x: 40,
          scale: 1.3,
          autoAlpha: 0.1,
          transformOrigin: "center center",
        },
        { y: 0, x: 0, scale: 1, autoAlpha: 0.3, ease: Power4.easeOut },
        "-=1"
      )
      .from(
        "#puzzle3",
        1,
        {
          y: 60,
          x: -10,
          rotation: 3,
          scale: 1.3,
          ease: Power2.easeOut,
          transformOrigin: "center center",
        },
        "-=1"
      )
      .fromTo(
        "#puzzle-sdw3",
        1,
        {
          y: 110,
          x: 30,
          scale: 1.3,
          autoAlpha: 0.1,
          transformOrigin: "center center",
        },
        { y: 0, x: 0, scale: 1, autoAlpha: 0.3, ease: Power4.easeOut },
        "-=1"
      )
      .from(
        "#puzzle4",
        1,
        {
          y: 60,
          x: 10,
          rotation: 7,
          scale: 1.3,
          ease: Power4.easeOut,
          transformOrigin: "center center",
        },
        "-=1"
      )
      .fromTo(
        "#puzzle-sdw4",
        1,
        {
          y: 80,
          x: 50,
          scale: 1.3,
          autoAlpha: 0.1,
          transformOrigin: "center center",
        },
        { y: 0, x: 0, scale: 1, autoAlpha: 0.3, ease: Power4.easeOut },
        "-=1"
      )
      .from(
        "#puzzle5",
        1,
        {
          y: 50,
          x: 10,
          rotation: -8,
          scale: 1.2,
          ease: Power2.easeOut,
          transformOrigin: "center center",
        },
        "-=1"
      )
      .fromTo(
        "#puzzle-sdw5",
        1,
        {
          y: 80,
          x: 20,
          scale: 1.3,
          autoAlpha: 0.1,
          transformOrigin: "center center",
        },
        { y: 0, x: 0, scale: 1, autoAlpha: 0.3, ease: Power4.easeOut },
        "-=1"
      )
      .to(
        [
          "#puzzle-sdw1",
          "#puzzle-sdw2",
          "#puzzle-sdw3",
          "#puzzle-sdw4",
          "#puzzle-sdw5",
          "#puzzle-sdw6",
        ],
        1,
        { autoAlpha: 0, ease: Power4.easeOut, immediateRender: false },
        "-=0.5"
      )
    return anim
  },
  //ssl.svg
  ssl(stopCallBack) {
    const anim = new TimelineMax()
      .from("#ssl-case-all", 1, {
        scale: 0.705,
        ease: Back.easeInOut.config(1),
        transformOrigin: "143px -90px",
        onCompleteL: stopCallBack,
      })
      .fromTo(
        "#ssl-case-back",
        1,
        { scaleY: 0.45, scaleX: 0.755, transformOrigin: "center 0px" },
        { scaleY: 1, scaleX: 1, ease: Back.easeInOut.config(1) },
        "-=1"
      )
      .from(
        "#ssl-case-sdw",
        1,
        { x: -50, autoAlpha: 0, ease: Back.easeInOut.config(1) },
        "-=1"
      )
      .fromTo(
        "#ssl-folder1",
        1,
        { y: 20 },
        { y: 0, ease: Power2.easeInOut },
        "-=0.6"
      )
      .fromTo(
        "#ssl-folder2",
        1,
        { y: 50 },
        { y: 0, ease: Power2.easeInOut },
        "-=1"
      )
    return anim
  },
  //business-solutions.svg
  business_solutions(stopCallBack) {
    const anim = new TimelineMax()
      .from("#body", 1, {
        x: 150,
        skewX: 10,
        transformOrigin: "bottom center",
        ease: Power2.easeOut,
      })
      .from(
        "#hand",
        1.5,
        {
          x: 50,
          y: -10,
          rotation: -5,
          transformOrigin: "bottom left",
          ease: Back.easeInOut.config(3),
          onComplete: stopCallBack,
        },
        "-=1"
      )
      .from(
        "#case",
        1.5,
        {
          x: 65,
          y: -5,
          rotation: -4,
          transformOrigin: "bottom left",
          ease: Back.easeInOut.config(3),
        },
        "-=1.5"
      )
      .from("#caseSdw", 2, { autoAlpha: 0, ease: Power4.easeInOut }, "-=1.5")
    return anim
  },
  //cloud-storage.svg
  cloud_storage(stopCallBack) {
    const anim = new TimelineMax()
      .from("#cloud1", 1.5, {
        autoAlpha: 0,
        y: 30,
        ease: Power1.easeInOut,
        onComplete: stopCallBack,
      })
      .from(
        "#cloud2",
        1.5,
        { autoAlpha: 0.1, x: 30, ease: Power2.easeInOut },
        "-=1.5"
      )
      .from(
        "#cloud3",
        1.5,
        { autoAlpha: 0, x: -30, ease: Power1.easeInOut },
        "-=1.5"
      )
      .from(
        "#cloud4",
        1.5,
        { autoAlpha: 0.1, y: 20, ease: Power2.easeInOut },
        "-=1.5"
      )
      .from(
        "#cloud5",
        1.5,
        { autoAlpha: 0, y: 30, ease: Power2.easeInOut },
        "-=1.5"
      )
      .fromTo(
        "#data1",
        1,
        { autoAlpha: 1, y: 19, x: -21 },
        { autoAlpha: 1, y: -20, x: -21, ease: Power1.easeOut },
        "-=1"
      )
      .fromTo(
        "#data2",
        1,
        { autoAlpha: 1, y: 65 },
        { autoAlpha: 1, y: 0, ease: Power2.easeOut },
        "-=1"
      )
      .fromTo(
        "#data3",
        1,
        { autoAlpha: 1, y: 30.7, x: -10.4 },
        { autoAlpha: 1, y: 10.7, x: -10.4, ease: Power2.easeOut },
        "-=0.5"
      )
      .fromTo(
        "#data4",
        1,
        { autoAlpha: 1, y: 20.5, x: 10.4 },
        { autoAlpha: 1, y: -15, x: 10, ease: Power1.easeOut },
        "-=1"
      )
    return anim
  },
  //data-centers.svg
  data_centers(stopCallBack) {
    const anim = new TimelineMax()
      .to("#data-cent-car2", 2, { ease: Back.easeInOut.config(0.4), x: -220 })
      .fromTo(
        "#data-cent-car2",
        3,
        { x: 660 },
        {
          ease: Back.easeInOut.config(0.4),
          x: 0,
          immediateRender: false,
          onComplete: stopCallBack,
        }
      )
      .to(
        "#data-cent-car1",
        3,
        {
          ease: Back.easeInOut.config(0.4),
          x: 660,
          immediateRender: false,
        },
        "-=5"
      )
      .fromTo(
        "#data-cent-car1",
        2,
        { x: -220 },
        {
          ease: Back.easeOut.config(0.4),
          x: 0,
          immediateRender: false,
        },
        "-=2"
      )
      .to(
        "#data-cent-trees",
        1,
        {
          ease: Back.easeInOut.config(8),
          skewX: -5,
          transformOrigin: "center bottom",
          repeat: 3,
          yoyo: true,
        },
        "-=5"
      )
      .fromTo(
        "#data-cent-flag1",
        1,
        { scaleX: 1, skewX: 0, skewY: 0, x: 0, transformOrigin: "left bottom" },
        {
          ease: Power1.easeInOut,
          scaleX: 0.9,
          skewX: -1,
          skewY: -3,
          x: -0.5,
          transformOrigin: "left bottom",
          repeat: 3,
          yoyo: true,
        },
        "-=5"
      )
      .fromTo(
        "#data-cent-flag2",
        1,
        { scaleX: 1, skewX: 0, skewY: 0, x: 0, transformOrigin: "left bottom" },
        {
          ease: Power1.easeInOut,
          scaleX: 0.7,
          skewX: -2,
          skewY: -2,
          x: -0.5,
          transformOrigin: "left bottom",
          repeat: 3,
          yoyo: true,
        },
        "-=4"
      )
      .fromTo(
        "#data-cent-flag3",
        1,
        { scaleX: 1, skewX: 0, skewY: 0, x: 0, transformOrigin: "left bottom" },
        {
          ease: Power1.easeInOut,
          scaleX: 0.8,
          skewX: -5,
          skewY: -5,
          x: -1,
          transformOrigin: "left bottom",
          repeat: 3,
          yoyo: true,
        },
        "-=4"
      )
    return anim
  },
  //equipment.svg
  equipment(stopCallBack) {
    const anim = new TimelineMax()
      .from("#equip-vent1", 2, {
        ease: Back.easeInOut.config(1),
        rotation: 360,
        transformOrigin: "center center",
        onComplete: stopCallBack,
      })
      .from(
        "#equip-vent2",
        2,
        {
          ease: Back.easeInOut.config(1),
          rotation: 720,
          transformOrigin: "center center",
        },
        "-=1.5"
      )
      .from(
        "#equip-vent3",
        2,
        {
          ease: Back.easeInOut.config(1),
          rotation: 360,
          transformOrigin: "center center",
        },
        "-=1.5"
      )
      .from(
        "#equip-vent4",
        2,
        {
          ease: Back.easeInOut.config(2),
          rotation: 720,
          transformOrigin: "center center",
        },
        "-=2"
      )
      .fromTo(
        "#equip-lines",
        0.8,
        { scaleX: 1, transformOrigin: "left bottom" },
        {
          ease: Back.easeInOut.config(1),
          scaleX: 0.75,
          transformOrigin: "left bottom",
          yoyo: true,
          repeat: 3,
        },
        "-=3"
      )
      .fromTo(
        ["#equip-bulb2", "#equip-bulb4"],
        0.5,
        { autoAlpha: 1 },
        { autoAlpha: 0, repeat: 3, yoyo: true },
        "-=3"
      )
      .fromTo(
        ["#equip-bulb1", "#equip-bulb3"],
        0.7,
        { autoAlpha: 1 },
        { autoAlpha: 0, repeat: 3, yoyo: true },
        "-=3"
      )
    return anim
  },
  //individual-services.svg
  individual_services(stopCallBack) {
    const anim = new TimelineMax()
      .fromTo(
        "#individ-lego",
        1,
        { y: 0 },
        {
          ease: Back.easeInOut.config(0.5),
          y: -70,
          transformOrigin: "left top",
          yoyo: true,
          repeat: 1,
          delay: 0.5,
          onComplete: stopCallBack,
        }
      )
      .fromTo(
        "#individ-wire1",
        1,
        { skewX: 0, autoAlpha: 1 },
        {
          ease: Back.easeInOut.config(5),
          skewX: -10,
          autoAlpha: 0,
          transformOrigin: "left top",
          yoyo: true,
          repeat: 1,
          repeatDelay: 1,
        },
        "-=2.5"
      )
      .fromTo(
        "#individ-wire2",
        1,
        { x: 0, skewX: 0, autoAlpha: 1 },
        {
          ease: Back.easeInOut.config(3),
          x: -5,
          skewX: -5,
          autoAlpha: 0,
          transformOrigin: "left top",
          yoyo: true,
          repeat: 1,
          repeatDelay: 1,
        },
        "-=2.8"
      )
    return anim
  },
  //connecting-channels.svg
  connecting_channels(stopCallBack) {
    const anim = new TimelineMax()
      .fromTo(
        ["#connect-data1", "#connect-data3"],
        2,
        { x: 0 },
        { ease: Back.easeOut.config(1), x: 82, onComplete: stopCallBack }
      )
      .fromTo(
        "#connect-data2",
        2,
        { x: 0 },
        {
          ease: Back.easeOut.config(1),
          x: -87,
        },
        "-=2"
      )
    return anim
  },
  //trust.svg
  trust(stopCallBack) {
    const anim = new TimelineMax()
      .from(["#trust-knob", "#trust-knob-sdw"], 4, {
        ease: Back.easeInOut.config(5),
        rotation: 360,
        transformOrigin: "center center",
        onComplete: stopCallBack,
      })
      .fromTo(
        "#trust-bracket",
        2,
        { scale: 1 },
        {
          ease: Back.easeInOut.config(2),
          scale: 0.8,
          transformOrigin: "right center",
          yoyo: true,
          repeat: 1,
        },
        "-=4"
      )
      .fromTo(
        "#trust-but6",
        0.5,
        {
          fill: "rgb(255,82,82)",
          scale: 1.3,
          transformOrigin: "center center",
        },
        { fill: "rgb(55,71,79)", scale: 1, delay: 1, immediateRender: false },
        "-=5"
      )
      .from(
        "#trust-but5",
        0.5,
        {
          fill: "rgb(255,82,82)",
          scale: 1.3,
          delay: 1,
          transformOrigin: "center center",
          immediateRender: false,
        },
        "-=4.5"
      )
      .from(
        "#trust-but4",
        0.5,
        {
          fill: "rgb(255,82,82)",
          scale: 1.3,
          delay: 1,
          transformOrigin: "center center",
          immediateRender: false,
        },
        "-=4"
      )
      .from(
        "#trust-but3",
        0.5,
        {
          fill: "rgb(255,82,82)",
          scale: 1.3,
          delay: 1,
          transformOrigin: "center center",
          immediateRender: false,
        },
        "-=3.5"
      )
      .from(
        "#trust-but2",
        0.5,
        {
          fill: "rgb(255,82,82)",
          scale: 1.3,
          delay: 1,
          transformOrigin: "center center",
          immediateRender: false,
        },
        "-=3"
      )
      .from(
        "#trust-but1",
        0.5,
        {
          fill: "rgb(255,82,82)",
          scale: 1.3,
          delay: 1,
          transformOrigin: "center center",
          immediateRender: false,
        },
        "-=2.5"
      )
      .fromTo(
        "#trust-but6",
        0.5,
        { fill: "rgb(55,71,79)" },
        { fill: "rgb(255,82,82)", delay: 2, immediateRender: false },
        "-=2"
      )
    return anim
  },
  //expert-service.svg
  expert_service(stopCallBack) {
    const anim = new TimelineMax()
      .fromTo(
        "#expert-objects",
        2,
        { x: 0 },
        {
          ease: Power1.easeInOut,
          x: 550,
          yoyo: true,
          repeat: 1,
          onComplete: stopCallBack,
        }
      )
      .fromTo(
        [
          "#expert-roll1",
          "#expert-roll2",
          "#expert-roll3",
          "#expert-roll4",
          "#expert-roll5",
        ],
        2,
        { rotation: -1440 },
        {
          ease: Power1.easeInOut,
          rotation: 0,
          transformOrigin: "center center",
          yoyo: true,
          repeat: 1,
        },
        "-=4"
      )
      .fromTo(
        "#expert-head",
        2,
        { y: 0 },
        {
          ease: Back.easeInOut.config(2),
          rotation: -2,
          transformOrigin: "center bottom",
          yoyo: true,
          repeat: 1,
        },
        "-=4"
      )
      .fromTo(
        "#expert-mouth",
        1,
        {
          fill: "rgb(255,255,255)",
          scaleY: 1,
          scaleX: 1,
          y: 0,
          x: 0,
          rotation: 0,
        },
        {
          ease: Back.easeOut.config(1),
          scaleY: 0.7,
          scaleX: 0.9,
          y: -1,
          x: -0.5,
          rotation: 3,
          fill: "rgb(255,82,82)",
          transformOrigin: "center center",
          yoyo: true,
          repeat: 1,
        },
        "-=4"
      )
      .from(
        "#expert-eyes",
        2,
        {
          y: 1,
          transformOrigin: "center bottom",
          yoyo: true,
          repeat: 1,
          immediateRender: false,
        },
        "-=4"
      )
      .from(
        ["#expert-eyebrow1", "#expert-eyebrow2"],
        2,
        {
          ease: Back.easeOut.config(1),
          transform: "matrix(1, 0.009, 0, 1, 0, 0.5)",
          yoyo: true,
          repeat: 1,
        },
        "-=4"
      )
      .from(
        "#expert-beard",
        2,
        {
          ease: Back.easeOut.config(2),
          transform: "matrix(1, 0.009, 0, 1, 0, -1.5)",
          yoyo: true,
          repeat: 1,
        },
        "-=4"
      )
      .fromTo(
        "#expert-hat",
        2,
        { y: 0, scaleX: 1 },
        {
          ease: Back.easeOut.config(5),
          y: -1,
          scaleX: 0.995,
          transformOrigin: "center bottom",
          yoyo: true,
          repeat: 1,
        },
        "-=4"
      )
      .fromTo(
        "#expert-eyes",
        0.2,
        { autoAlpha: 0, scaleY: 0.1, transformOrigin: "center bottom" },
        { autoAlpha: 1, scaleY: 1.1, repeatDelay: 2, repeat: 2 },
        "-=4"
      )
    return anim
  },
  //rocket.svg
  rocket(stopCallBack) {
    const anim = new TimelineMax()
      .from("#rocket-body", 1.5, {
        ease: Back.easeInOut.config(10),
        y: 10,
        transformOrigin: "center bottom",
        onComplete: stopCallBack,
      })
      .from(
        "#rocket-smoke",
        1.5,
        {
          ease: Back.easeInOut.config(10),
          scaleY: 0.9,
          transformOrigin: "center bottom",
          immediateRender: false,
        },
        "-=1.5"
      )
      .from(
        "#rocket-fire",
        0.2,
        {
          ease: Back.easeOut.config(5),
          scaleY: 1.1,
          scaleX: 0.9,
          autoAlpha: 0.25,
          repeat: 10,
          transformOrigin: "center top",
        },
        "-=1"
      )
    return anim
  },
  //mail.svg
  mail(stopCallBack) {
    const anim = new TimelineMax()
      .from("#mail-flag", 1, {
        ease: Back.easeInOut.config(10),
        rotation: 10,
        x: -1.5,
        y: -2,
        transformOrigin: "left bottom",
        onComplete: stopCallBack,
      })
      .from(
        "#mail-flag-sdw",
        1,
        {
          ease: Back.easeInOut.config(10),
          rotation: 2,
          skewX: -6,
          scaleY: 1.01,
          x: -1,
          y: -1,
          transformOrigin: "left bottom",
        },
        "-=1"
      )
      .from(
        "#mail-cap",
        1,
        {
          ease: Back.easeInOut.config(2),
          rotation: 7,
          skewX: -4,
          x: 3,
          transformOrigin: "right top",
        },
        "-=1"
      )
      .from(
        "#mail-body1",
        1,
        {
          ease: Back.easeInOut.config(2),
          rotation: -3,
          x: -16,
          transformOrigin: "center center",
        },
        "-=1"
      )
      .from(
        "#mail-body2",
        1,
        {
          ease: Back.easeInOut.config(2),
          rotation: -5,
          x: -12,
          transformOrigin: "center center",
        },
        "-=1"
      )
      .from(
        "#mail-body3",
        1,
        {
          ease: Back.easeInOut.config(2),
          rotation: -8,
          x: -8,
          transformOrigin: "center center",
        },
        "-=1"
      )
    return anim
  },
  //cap.svg
  cap(event) {
    const moveParallax = Math.round(
      (event.clientX - window.innerWidth / 2) / ((window.innerWidth / 774) * 20)
    )
    const capParallaxTl = new TimelineMax()
      .to(["#cap-shuttle", "#cap-smoke-block"], 0.5, {
        ease: Sine.easeOut,
        transform: "matrix(1, 0, 0, 1, " + moveParallax + ", 0)",
      })
      .to(
        "#cap-rockets",
        0.5,
        {
          ease: Sine.easeOut,
          transform: "matrix(1, 0, 0, 1, " + moveParallax + ", 0)",
        },
        "-=0.495"
      )
      .to(
        "#cap-start",
        0.6,
        {
          ease: Sine.easeOut,
          transform: "matrix(1, 0, 0, 1, " + moveParallax + ", 0)",
        },
        "-=0.495"
      )
      .to(
        "#cap-clouds",
        1.5,
        {
          ease: Sine.easeOut,
          transform: "matrix(1, 0, 0, 1, " + -moveParallax + ", 0)",
        },
        "-=0.5"
      )
    return capParallaxTl
  },
}
