import React from "react"
import "./LargeText.css"
import ReactMarkdown from "react-markdown"
import ContentImage from "../../ContentImage/ContentImage"

const LargeText = ({ body, lang, utm = "", data }) => {
  const Image = ({ alt, src }) => (
    <ContentImage alt={alt} src={src} allFile={data.allFile} />
  )
  const renderers = {
    image: Image,
  }
  return (
    <div className="large-text container">
      <div className="large-text__body">
        <ReactMarkdown
          source={body[`content_${lang}`]}
          renderers={renderers}
          transformLinkUri={url =>
            url &&
            (url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&"))
          }
        />
      </div>
    </div>
  )
}

export default LargeText
