import React from "react"
import "./Wiki.css"
import WikiNav from "./WikiNav/WikiNav"
import WikiBody from "./WikiBody/WikiBody"
import Responsive from "../Responsive/Responsive"
import ContentNav from "../ContentNav/ContentNav"

const Wiki = ({ lang, title, body, currentSlug, utm = "", data }) => {
  return (
    <div className="container wiki">
      <Responsive options={["desktop"]}>
        <nav className="wiki__nav">
          <WikiNav
            currentSlug={currentSlug}
            lang={lang}
            utm={utm}
            data={data}
          />
        </nav>

        <WikiBody title={title} body={body} utm={utm} data={data} />
      </Responsive>

      <Responsive options={["tab", "mobile"]}>
        <ContentNav
          navlist={
            <WikiNav
              currentSlug={currentSlug}
              lang={lang}
              utm={utm}
              data={data}
            />
          }
          content={<WikiBody title={title} body={body} utm={utm} data={data} />}
        />
      </Responsive>
    </div>
  )
}

export default Wiki
