import React, { useState } from "react";
import "./Subscribe.css";
import FormSubscribe from "./FormSubscribe/FormSubscribe";
import Animation from "../Animation/Animation";
import Responsive from "../Responsive/Responsive";

const Subscribe = ({ lang, data }) => {
  const { strapiSubscribe } = data;
  let [isPlay, setPlay] = useState(false);
  let toggleAnim = () => {
    setPlay(!isPlay);
  };

  return (
    <div
      className="subscribe"
      onMouseEnter={toggleAnim}
      onMouseLeave={toggleAnim}
    >
      <div className="container subscribe__body">
        <FormSubscribe
          isPlay={isPlay}
          form_title={strapiSubscribe[`title_${lang}`]}
          label_name={strapiSubscribe[`label_name_${lang}`]}
          error_name={strapiSubscribe[`error_name_${lang}`]}
          label_email={strapiSubscribe[`label_email_${lang}`]}
          error_email={strapiSubscribe[`error_email_${lang}`]}
          button_text={strapiSubscribe[`button_text_${lang}`]}
          api_key={strapiSubscribe.esputnik_api_key}
          api_group={strapiSubscribe[`esputnik_group_${lang}`]}
          message_success_subscribe={
            strapiSubscribe[`message_success_subscribe_${lang}`]
          }
          message_subscribe_was_already={
            strapiSubscribe[`message_subscribe_was_already_${lang}`]
          }
        />
        <Responsive options={["desktop", "tab"]}>
          <div className="subscribe__illustration">
            <Animation isAnim={isPlay} illustration_id="mail" />
          </div>
        </Responsive>
      </div>
    </div>
  );
};

export default Subscribe;
