import React, { useEffect, useState } from "react"
import "./Header.css"
import Menu from "./Menu/Menu"
import MyAccount from "./MyAccount/MyAccount"
import Logo from "./Logo/Logo"
import MobileMenu from "./MobileMenu/MobileMenu"
import Responsive from "../Responsive/Responsive"
import HeaderTop from "./HeaderTop/HeaderTop"
import { connect } from "react-redux"

const Header = ({
  lang,
  slug,
  isHome = false,
  context,
  head,
  utm = "",
  data,
}) => {
  const { strapiHeader } = data
  let [isMenuActive, setIsMenuActive] = useState(false)
  let [scrollState, setScrollState] = useState("top-start")

  useEffect(() => {
    let scrollFirst = 0,
      scrollLast = null,
      hide = null

    function lastPos() {
      scrollLast = scrollFirst
    }

    function firstPos() {
      lastPos()
      scrollFirst = window.pageYOffset || window.document.body.scrollTop

      if (scrollFirst <= scrollLast) {
        //scroll to top
        if (scrollFirst <= 55) {
          setScrollState("top-start")
          hide = false
        } else {
          setScrollState("enable")
        }
      } else {
        //scroll to bottom
        if (scrollFirst <= 140) {
          setScrollState("top-end")
        } else {
          if (!hide) {
            hide = true
            setScrollState("disable-no-anim")
          } else setScrollState("disable")
        }
      }
    }

    window.document.addEventListener("scroll", firstPos)
    return () => {
      window.document.removeEventListener("scroll", firstPos)
    }
  }, [])

  return (
    <header
      className={`header ${
        isHome &&
        !isMenuActive &&
        (scrollState === "top-start" || scrollState === "top-end")
          ? "header--home"
          : ""
      } ${!isHome ? "header--static" : ""}
      header--${scrollState}`}
    >
      <Responsive options={["desktop", "tab"]}>
        <HeaderTop
          strapiHeader={strapiHeader}
          lang={lang}
          slug={slug}
          head={head}
          utm={utm}
          context={context}
          data={data}
        />
      </Responsive>

      <div className="header-bottom">
        <div className="header-bottom__body container">
          <Logo
            lang={lang}
            className="header-bottom__side-left"
            isHome={isHome}
            utm={utm}
          />
          <Responsive options={["desktop"]}>
            <Menu
              data={data}
              lang={lang}
              slug={slug}
              head={head}
              utm={utm}
              onToggleMenu={setIsMenuActive}
              headerState={scrollState}
            />
          </Responsive>
          <MyAccount
            utm={utm}
            text={strapiHeader[`login_text_${lang}`]}
            link={strapiHeader[`login_link_${lang}`]}
            className="header-bottom__side-right"
          />
          <Responsive options={["tab", "mobile"]}>
            <MobileMenu
              data={data}
              utm={utm}
              lang={lang}
              slug={slug}
              head={head}
              onToggleMenu={setIsMenuActive}
            />
          </Responsive>
        </div>
      </div>
    </header>
  )
}

let mapStateToProps = state => ({
  utm: state.utm.utm,
})

export default connect(mapStateToProps)(Header)
