import React from 'react';
import './ColumnFeatures.css';
import ColumnFeaturesItem from "./ColumnFeaturesItem";

const ColumnFeatures = ({body, lang}) => {
  return (
   <div className={`column-features ${
     body.style ? "column-features--overlay-" + body.style : ""
   }`}>
     <div className="container">
       <h2 className="section-title column-features__title">
         {body[`title_${lang}`]}
       </h2>
       <div className="column-features__container">
         {body.left_column &&
           <div className="column-features__column">
             {body.left_column.map((item, n) =>
               <ColumnFeaturesItem key={n}
                                   title={item[`name_${lang}`]}
                                   iconId={item.icon}
                                   description={item[`description_${lang}`]}/>
             )}
           </div>}
         {body.right_column &&
           <div className="column-features__column">
             {body.right_column.map((item, n) =>
               <ColumnFeaturesItem key={n}
                                   title={item[`name_${lang}`]}
                                   iconId={item.icon}
                                   description={item[`description_${lang}`]}/>
             )}
           </div>
         }
       </div>
     </div>
   </div>
  )
};

export default ColumnFeatures;