export default {
  ru: {
    service: {
      support: "Техническая поддержка",
      feedback: "Форма обратной связи",
    },
    home: "На главную",
    more: "Написать еще",
    order_more: "Заказать еще",
    close_btn: "Закрыть"
  },
  en: {
    service: {
      support: "Technical support",
      feedback: "Feedback form",
    },
    home: "Home",
    more: "Write more",
    order_more: "Order more",
    close_btn: "Close",
  },
}
