import React from 'react';
import './Reviews.css';
import Img from "gatsby-image";

const Review = ({item, lang}) => {
  return (
    <div  className="review__item">
      <div className="review__header">
        <Img className="review__image" fluid={item.image.childImageSharp.fluid} />
        <div className="review__info">
          <h2>{item[`name_${lang}`]}</h2>
          <p>{item[`description_${lang}`]}</p>
        </div>
      </div>
      <div className="review__body">
        <p className="review__text">{item[`text_${lang}`]}</p>
      </div>
    </div>
  )
};

export default React.memo(Review);