import React, { useEffect, useState } from "react"
import "./Input.css"

const Input = ({
  type = "text",
  placeholder,
  classes,
  errorText = "",
  value = "",
  label = "",
  onChangeValue,
  isError,
}) => {
  const [activeClass, setActiveClass] = useState("")
  const [error, setError] = useState(isError)

  useEffect(() => {
    setError(isError)
    isError && setActiveClass("")
  }, [isError])

  useEffect(() => {
    if (value.length) {
      setActiveClass(" ui-input__field--active")
    } else {
      setActiveClass("")
    }
  }, [value])

  const changeField = e => {
    setError(false)
    onChangeValue(e.target.value)
  }

  return (
    <label className={"ui-input " + classes}>
      {label}
      <input
        className={
          "ui-input__field " +
          (error ? " ui-input__field--error" : "") +
          activeClass
        }
        type={type}
        placeholder={placeholder}
        onChange={changeField}
        value={value}
      />
      {error && <span className={"ui-input__error-text"}>{errorText}</span>}
    </label>
  )
}

export default Input
