import React from "react";
import "swiper/swiper.min.css";
import "./CDNGroupSlider.css";
import Swiper from "react-id-swiper";
import CDNItemSlider from "./CDNItemSlider/CDNItemSlider";

const CdnGroupSlider = ({ list, lang, utm = "", slides = 1, body }) => {
  const params = {
    slidesPerView: slides,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    spaceBetween: 0,
    loop: false,
  };

  return (
    <div className="cdn-group-slider">
      <Swiper {...params}>
        {list.map((item, n) => (
          <div key={n} className="cdn-item-slider">
            <CDNItemSlider body={body} item={item} lang={lang} utm={utm} />
          </div>
        ))}
      </Swiper>
    </div>
  );
};

export default CdnGroupSlider;
