const SET_MAP_DATA = "SET-MAP-DATA"
const SET_MAP_FIlTER = "SET-MAP-FILTER"
const SET_MAP_ACTIVE_POINT = "SET-MAP-ACTIVE-POINT"
const CLEAR_MAP_ACTIVE_POINT = "CLEAR-MAP-ACTIVE-POINT"

const getTypesArray = a => {
  let r = {}
  return a
    .filter(i => (r.hasOwnProperty(i.type) ? !1 : (r[i.type] = !0)))
    .map(i => i.type)
}

const initialState = {
  filter: {
    value: false,
    options: [],
  },
  items: [],
  active: null,
}

const mapRaducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAP_DATA: {
      return {
        ...state,
        filter: {
          value: false,
          options: getTypesArray(action.items),
        },
        active: null,
        items: action.items.map((item, n) => ({
          ...item,
          id: n,
          coordinates: [
            Number(item.coordinates.split(", ")[1]),
            Number(item.coordinates.split(", ")[0]),
          ],
        })),
      }
    }

    case SET_MAP_FIlTER: {
      return {
        ...state,
        active: null,
        filter: {
          options: state.filter.options,
          value: action.filter,
        },
      }
    }
    case SET_MAP_ACTIVE_POINT: {
      return {
        ...state,
        active:
          action.activeId === null
            ? null
            : state.items.filter(i => i.id === action.activeId)[0],
      }
    }
    case CLEAR_MAP_ACTIVE_POINT: {
      return {
        ...state,
        active: null,
      }
    }
    default:
      return state
  }
}

export const setMapData = items => ({ type: SET_MAP_DATA, items })
export const setMapFilter = filter => ({ type: SET_MAP_FIlTER, filter })
export const setMapActivePoint = activeId => ({
  type: SET_MAP_ACTIVE_POINT,
  activeId,
})
export const clearMapActivePoint = () => ({ type: CLEAR_MAP_ACTIVE_POINT })

export default mapRaducer
