import React from "react"
import ReactMarkdown from "react-markdown"
import mapKey from "../../../../language/mapKey"
import Icon from "../../../Sprite/Icon"
import "./NetworkMobileList.css"

const NetworkMobileList = ({
  mapItems,
  lang,
  utm = "",
  changeActivePoint,
  filter,
  title,
  description,
  showMap,
  pageHead,
}) => {
  return (
    <div className="nm-list">
      <div className="nm-list__head">
        <h1 className="nm-list__head-title">{pageHead}</h1>
      </div>
      <div className="nm-list__content">
        <h2 className="nm-list__title">{title}</h2>
        <div className="nm-list__description">
          <ReactMarkdown
            source={description}
            transformLinkUri={url =>
              url &&
              (url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&"))
            }
          />
        </div>
        <div className="nm-list__points">
          {filter.options.map(i => (
            <div key={i} className="nm-list__points-group">
              <h3 className="nm-list__points-title">{mapKey[lang][i]}</h3>
              <ul className="nm-list__points-list">
                {mapItems
                  .filter(item => item.type === i)
                  .map(i => (
                    <li key={i.id} className="nm-list__point">
                      {i[`description_${lang}`] === null ? (
                        <span
                          className={`nm-list__point nm-list__point--${i.type}`}
                        >
                          <b className="nm-list__point-name">
                            {i[`title_${lang}`]}
                          </b>
                          {i[`address_${lang}`]}
                        </span>
                      ) : (
                        <button
                          onClick={() => changeActivePoint(i.id)}
                          className={`nm-list__point  nm-list__point--active nm-list__point--${i.type}`}
                        >
                          <b className="nm-list__point-name">
                            {i[`title_${lang}`]}
                          </b>
                          {i[`address_${lang}`]}
                          <Icon
                            iconId="arrow"
                            height={10}
                            width={10}
                            classes={"nm-list__point-arrow"}
                          />
                        </button>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>

        <button className="nm-list__map-button" onClick={() => showMap()}>
          {mapKey[lang].view_on_map}
        </button>
      </div>
    </div>
  )
}

export default NetworkMobileList
