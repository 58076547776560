import React from "react"
import "./MyAccount.css"
import Icon from "../../Sprite/Icon"

//My account
const MyAccount = ({ utm = "", text, link, className }) => {
  link = link.indexOf("?") < 0 ? link + utm : link + utm.replace("?", "&")



  return (
    <a className={`my-account ${className}`} href={link} target="_blank" rel="noreferrer noopener" 
      onClick={() => window?.dataLayer?.push({event: 'login'})}>
      <Icon classes="my-account__icon" iconId="user" />
      {text}
    </a>
  )
}
//

export default MyAccount
