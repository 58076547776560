import React from "react"
import "./Checkbox.css"

const CheckboxError = ({ classes, errorText, isError }) => {
  return (
    <>
      {isError ? (
        <div className={`ui-checkbox__error ${classes}`}>{errorText}</div>
      ) : null}
    </>
  )
}

export default CheckboxError
