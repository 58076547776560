export async function getContactByEmail(email, apiKey) {
  let headers = new Headers()
  headers.append("Authorization", "Basic " + window.btoa(`:${apiKey}`))
  headers.append("Content-Type", "application/json")

  let requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  }

  let res = await fetch(
    `https://esputnik.com/api/v1/contacts?email=${email}`,
    requestOptions
  )
  let data = await res.json()
  return data
}

export async function addContact(email, name, groupName, apiKey) {
  let headers = new Headers()
  headers.append("Authorization", "Basic " + window.btoa(`:${apiKey}`))
  headers.append("Content-Type", "application/json")

  let body = JSON.stringify({
    contact: { firstName: name, channels: [{ type: "email", value: email }] },
    groups: [groupName],
  })

  let requestOptions = {
    method: "POST",
    headers: headers,
    body: body,
    redirect: "follow",
  }

  let res = await fetch(
    "https://esputnik.com/api/v1/contact/subscribe",
    requestOptions
  )
  let data = await res.json()
  return data
}
