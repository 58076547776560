import React from "react"
import "./CDN.css"
import CdnGroup from "./CDNGroup/CDNGroup"
import CdnGroupSlider from "./CDNGroupSilder/CDNGroupSlider"
import Responsive from "../../Responsive/Responsive"
import { graphql, useStaticQuery } from "gatsby"
import Icon from "../../Sprite/Icon";
import productKey from "../../../language/productKey"

const Cdn = ({ lang, cdn, body, utm = "" }) => {
  const { strapiProductCdn } = useStaticQuery(graphql`
    query {
      strapiProductCdn {
        video_list {
          stoke
          name
          type
          traffic_amount
          traffic_amount_unit
          traffic_speed
          traffic_speed_unit
          storage
          storage_unit
          unlimited_domains
          free_amount
          free_unit
          price_old
          price
          link_as_amount
          link_as_speed
          tariff_type
        }
        static_list {
          stoke
          name
          type
          traffic_amount
          traffic_amount_unit
          traffic_speed
          traffic_speed_unit
          storage
          storage_unit
          unlimited_domains
          free_amount
          free_unit
          price_old
          price
          link_as_amount
          link_as_speed
          tariff_type
        }
      }
    }
  `)
  let list =
    cdn === "video"
      ? strapiProductCdn.video_list
      : cdn === "static"
      ? strapiProductCdn.static_list
      : null
  return (
    <>
      {list && (
        <div className={`container cdn ${body.style ? "cdn--" + body.style : ""}`}>
          <Responsive options={["mobile"]}>
            <CdnGroupSlider body={body} list={list} lang={lang} utm={utm} />
          </Responsive>
          <Responsive options={["tab"]}>
            <CdnGroupSlider body={body} list={list} lang={lang} utm={utm} slides={2} />
          </Responsive>
          <Responsive options={["desktop"]}>
            <CdnGroup body={body} list={list} lang={lang} utm={utm} />
          </Responsive>
        </div>
      )}
    </>
  )
}

export default Cdn
