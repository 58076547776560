import { applyMiddleware, combineReducers, createStore } from "redux"
import page from "./pageReducer"
import subscribe from "./subscribeReducer"
import map from "./mapReducer"
import dedicated from "./dedicatedReducer"
import utm from "./utmReducer"

import thunkMiddleware from "redux-thunk"

let reducer = combineReducers({
  page,
  subscribe,
  map,
  dedicated,
  utm,
})

export default preloadedState => {
  return createStore(reducer, preloadedState, applyMiddleware(thunkMiddleware))
}
