import React from "react"
import GlobalStyle from "../GlobalStyle/GlobalStyle"
import Seo from "../Seo/Seo"
import Header from "../Header/Header"
import Responsive from "../Responsive/Responsive"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import Sprite from "../Sprite/Sprite"
import NetworkMap from "../NetworkMap/NetworkMap"
import Utm from "../Utm/Utm"
import { connect } from "react-redux"
import NetworkMobile from "../NetworkMap/NetworkMobile/NetworkMobile"
import ScrollTop from "../ScrollTop/ScrollTop"
import { graphql, useStaticQuery } from "gatsby"
import CookieNotice from "../CookieNotice/CookieNotice";

const NetworkMapTemplate = ({ slug, lang, title, seo, utm = "", data }) => {
  const { strapiMapData } = useStaticQuery(graphql`
    query {
      strapiMapData {
        title_ru
        title_en
        description_en
        description_ru
        map_item {
          address_en
          address_ru
          coordinates
          description_en
          description_ru
          title_en
          title_ru
          type
          photo {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="network-map__layout">
      <GlobalStyle />
      <Utm />
      <ScrollTop lang={lang} />
      <Seo lang={lang} seo={seo} slug={slug} defaultTitle={title} data={data} />
      <Header lang={lang} slug={slug} utm={utm} data={data} />
      <Responsive options={["desktop"]}>
        <Breadcrumbs lang={lang} slug={slug} utm={utm} data={data} />
        <NetworkMap lang={lang} utm={utm} strapiMapData={strapiMapData} />
      </Responsive>
      <Responsive options={["tab"]}>
        <Breadcrumbs lang={lang} slug={slug} utm={utm} data={data} />
      </Responsive>
      <Responsive options={["tab", "mobile"]}>
        <NetworkMobile
          lang={lang}
          utm={utm}
          title={title}
          strapiMapData={strapiMapData}
        />
      </Responsive>
      <CookieNotice lang={lang} data={data}/>
      <Sprite />
    </div>
  )
}

let mapStateToProps = state => ({ utm: state.utm.utm })

export default connect(mapStateToProps)(NetworkMapTemplate)
