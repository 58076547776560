import React from "react";
import "./BlogListPagination.css";
import Pagination from "rc-pagination";
import { Link } from "gatsby";

const BlogListPagination = ({ blogs, pageLimit, params, currentPage }) => {
  const paginationItem = (current, type, element) => {
    let pageParams = new URLSearchParams(params.toString());
    pageParams.set("page", current);
    if (type === "next") return null;
    if (type === "prev") return null;
    if (type === "page")
      return currentPage === current ? (
        <span className="rc-pagination-item__active rc-pagination-item__link">
          {current}
        </span>
      ) : (
        <Link
          className="rc-pagination-item__other rc-pagination-item__link"
          to={`?${pageParams.toString()}`}
        >
          {current}
        </Link>
      );
  };

  return (
    <div className="container">
      {blogs.length > pageLimit && (
        <Pagination
          total={blogs.length}
          pageSize={pageLimit}
          itemRender={paginationItem}
          current={params.get("page")}
        />
      )}
    </div>
  );
};

export default BlogListPagination;
