exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../node_modules/css-loader/index.js??ref--12-oneOf-1-1!../node_modules/postcss-loader/src/index.js??postcss-2!./css/variable.css"), "");
exports.i(require("-!../node_modules/css-loader/index.js??ref--12-oneOf-1-1!../node_modules/postcss-loader/src/index.js??postcss-2!./css/base.css"), "");
exports.i(require("-!../node_modules/css-loader/index.js??ref--12-oneOf-1-1!../node_modules/postcss-loader/src/index.js??postcss-2!./css/typography.css"), "");

// module
exports.push([module.id, "/* global */\n", "", {"version":3,"sources":["/var/www/frontend/src/style.css"],"names":[],"mappings":"AAAA,YAAW","file":"style.css","sourcesContent":["/* global */\n@import \"./css/variable.css\";\n@import \"./css/base.css\";\n@import \"./css/typography.css\";\n"],"sourceRoot":""}]);

// exports
