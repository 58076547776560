import React from "react"
import "./DFormBody.css"
import { useState } from "react"
import ReactMarkdown from "react-markdown"
import Input from "../../../../UI/Input/Input"
import Textarea from "../../../../UI/Textarea/Textarea"
import ReCaptcha from "../../../../PageContent/ContactForm/ReCaptcha/ReCaptcha"
import Checkbox from "../../../../UI/Checkbox/Checkbox"
import CheckboxError from "../../../../UI/Checkbox/CheckboxError"
import Button from "../../../../UI/Button/Button"
import { connect } from "react-redux"
import productKey from "../../../../../language/productKey"
import { orderRequests } from "../../../../../api/websa"

const DFormBody = ({ body, lang, dedicated, setSuccess, data }) => {
  const { allSite } = data
  const {
    edges: [
      {
        node: {
          siteMetadata: { websaToken },
        },
      },
    ],
  } = allSite
  let [name, setName] = useState("")
  let changeName = newName => setName(newName)
  let [email, setEmail] = useState("")
  let changeEmail = newEmail => setEmail(newEmail)
  let [promo, setPromo] = useState("")
  let changePromo = newPromo => setPromo(newPromo)
  let [technical, setTechnical] = useState("")
  let changeTechnical = newTechnical => setTechnical(newTechnical)
  let [checkAgree, setCheckAgree] = useState(false)
  let changeCheckAgree = newCheck => setCheckAgree(newCheck)
  let [checkPrivat, setcheckPrivat] = useState(false)
  let changeCheckPrivat = newCheck => setcheckPrivat(newCheck)
  let [formError, setFormError] = useState({
    name: false,
    email: false,
    check: false,
  })
  let [reCaptcha, setReCaptcha] = useState(!body.re_captcha)
  let [reCaptchaResponse, setReCaptchaResponse] = useState("")

  //======================= Calc Result
  let { currentItem, priceCode } = dedicated
  const options = [
    "cpu",
    "ram",
    "motherboard",
    "colocations",
    "disk",
    "disk_additional",
    "raid",
    "network",
    "management",
    "bandwidth",
  ]
  const getCurrent = item => {
    let current = {}
    options.forEach(i => {
      current[i] = item[i] && item[i].filter(i => i.current)[0]
    })
    return current
  }
  const getPrice = (current, currentItem, priceCode) => {
    const disks = currentItem.disk && currentItem.disk.filter(i => i.current)
    let price = 0
    options.forEach(i => {
      if (i === "disk") {
        disks &&
          disks.forEach(i => {
            price += i[`price_${priceCode}`]
          })
      } else {
        price += current[i] ? current[i][`price_${priceCode}`] : 0
      }
    })
    return price
  }
  let current = currentItem !== {} && getCurrent(currentItem)
  let price = currentItem !== {} && getPrice(current, currentItem, priceCode)
  let discount = currentItem.discount ? (100 - currentItem.discount) / 100 : 0
  price = discount !== 0 ? price * discount : price

  let cpu = currentItem.cpu.filter(i => i.current)[0]
  let ram = currentItem.ram.filter(i => i.current)[0]
  let raid = currentItem.raid.filter(i => i.current)[0]
  let management = currentItem.management.filter(i => i.current)[0]
  let network = currentItem.network.filter(i => i.current)[0]
  let bandwidth = currentItem.bandwidth.filter(i => i.current)[0]
  let disk = currentItem.disk.filter(i => i.current)
  let disk_additional = currentItem.disk_additional.filter(i => i.current)[0]
  disk = disk_additional ? [...disk, disk_additional] : disk

  let order = {
    service: "dedicated", //done
    service_name: productKey[lang].dedicated_server, //done
    product_type: currentItem.group[`title_${lang}`], //done
    location: productKey.en.country[priceCode], //done
    name: `${currentItem.name} ${currentItem.category}`, //done
    description: currentItem.motherboard.filter(i => i.current)[0].name,
    language: lang, //done
    currency: productKey.en.currency[priceCode], //done
    promocode: promo, //done
    period: "month", //done
    price: price, //done
    user_name: name, //done
    user_email: email, //done
    notes: technical, //done
    user_ip: "", //done
    user_country: "", //done
    user_city: "", //done
    user_application: window.navigator.userAgent, //done
    products: [
      {
        type: "cpu",
        name: cpu.name,
        description: cpu.model,
      },
      {
        type: "memory",
        name: `${ram.amount} ${productKey[lang].units[ram.unit]}`,
        description: productKey[lang][ram.ddr],
      },
      {
        type: "raid",
        name: raid.model
          ? `${raid.type} ${raid.model}`
          : productKey[lang].without_raid,
      },
      {
        type: "admin",
        name: management[`name_${lang}`],
      },
      {
        type: "connectivity",
        name: `${network.amount} ${productKey[lang].units[network.unit]}`,
        description: network.name,
      },
      {
        type: "traffic",
        name: `${bandwidth.speed} ${productKey[lang].units[bandwidth.unit]}`,
      },
    ],
  }
  disk.forEach(disk => {
    order.products.push({
      type: "disk",
      name: `${disk.amount} ${productKey[lang].units[disk.unit]}`,
      description: disk.model,
    })
  })
  //======================= Submit Form
  let submitForm = async () => {
    let validStatus = {
      name: !(name !== ""),
      email: !(email !== "" && email.indexOf("@") > 0),
      check: !(checkAgree && checkPrivat),
    }

    if (
      validStatus.name ||
      validStatus.email ||
      validStatus.check ||
      !reCaptcha
    ) {
      setFormError(validStatus)
    } else {
      order.promocode = promo
      order.user_name = name
      order.user_email = email
      order.notes = technical

      let data = await orderRequests(websaToken, order, reCaptchaResponse)
      setSuccess(data.success)
      if (data.success) {
        setName("")
        setEmail("")
        setTechnical("")
        setPromo("")
        setCheckAgree(false)
        setcheckPrivat(false)
        setFormError({
          name: false,
          email: false,
          check: false,
        })
      }
    }
  }
  return (
    <div className="d-form-body">
      <h2 className="d-form-body__title">{body[`title_${lang}`]}</h2>
      <div className="d-form-body__before-text">
        {body[`before_text_${lang}`]}
      </div>
      <form action="" onSubmit={e => e.preventDefault()}>
        <div className="d-form-body__input-grid">
          <Input
            placeholder={body[`label_name_${lang}`]}
            errorText={body[`error_name_${lang}`]}
            onChangeValue={changeName}
            value={name}
            isError={formError.name}
            classes="d-form-body__input"
          />
          <Input
            type="mail"
            placeholder={body[`label_email_${lang}`]}
            errorText={body[`error_email_${lang}`]}
            onChangeValue={changeEmail}
            value={email}
            isError={formError.email}
            classes="d-form-body__input"
          />
        </div>
        <p className="d-form-body__inner-text">
          {body[`before_technical_text_${lang}`]}
        </p>
        <Textarea
          placeholder={body[`label_technical_${lang}`]}
          onChangeValue={changeTechnical}
          value={technical}
          classes="d-form-body__textarea"
        />
        <p className="d-form-body__inner-text">
          {body[`before_promo_text_${lang}`]}
        </p>
        <div className="d-form-body__input-grid">
          <Input
            placeholder={body[`label_promo_${lang}`]}
            onChangeValue={changePromo}
            value={promo}
            classes="d-form-body__input"
          />
        </div>

        {body.re_captcha ? (
          <div className="d-form-body__recaptcha">
            <ReCaptcha
              sitekey={body.re_captcha_sitekey}
              lang={lang}
              callback={setReCaptcha}
              getResponse={setReCaptchaResponse}
            />
          </div>
        ) : null}
        <div className="d-form-body__after-text">
          <ReactMarkdown source={body[`after_text_${lang}`]} />
        </div>
        <div className="d-form-body__check-area">
          <Checkbox
            check={checkAgree}
            changeCheck={changeCheckAgree}
            label={body[`label_check_agree_${lang}`]}
          />
          <Checkbox
            check={checkPrivat}
            changeCheck={changeCheckPrivat}
            label={body[`label_check_privat_${lang}`]}
          />
          <CheckboxError
            classes="d-form-body__check-area-error"
            errorText={body[`error_check_${lang}`]}
            isError={formError.check}
          />
        </div>
        <div className="d-form-body__button-area">
          <Button
            text={body[`button_text_${lang}`]}
            classes={"btn--red btn--large"}
            onPress={submitForm}
          />
        </div>
      </form>
    </div>
  )
}

let mapStateToProps = state => ({
  dedicated: state.dedicated,
})

export default connect(mapStateToProps)(DFormBody)
