import React from "react";
import { connect } from "react-redux";
import "./DCalc.css";
import { setCurrentItem } from "../../../../state/dedicatedReducer";
import { useEffect } from "react";
import CalcGroup from "./CalcGroup/CalcGroup";
import productKey from "../../../../language/productKey";
import CalcResult from "./CalcResult/CalcResult";
import { graphql, Link, useStaticQuery } from "gatsby";
import Responsive from "../../../Responsive/Responsive";

const DCalc = ({ dedicated, setCurrentItem, lang, item, slug }) => {
  const { strapiProductDedicatedServer } = useStaticQuery(graphql`
    query {
      strapiProductDedicatedServer {
        calculation {
          description_en
          description_ru
          title_en
          title_ru
        }
      }
    }
  `);
  let units = {
    mbps: 1,
    gbps: 1000,
    tbps: 1000000,
  };

  useEffect(() => {
    if (item) {
      if (!item.disk_one_of_list) {
        const getAmount = (amount) => {
          const reg = /\d+/;
          return parseInt(amount.match(reg)[0]);
        };
        item.disk_select = getAmount(
          item.disk.filter((i) => i.current)[0].amount
        );
        item.disk.forEach((i) => {
          i.amountValue = getAmount(i.amount);
          i.disabled = item.disk_limit - item.disk_select < i.amountValue;
        });
      }

      let network = item.network.filter((i) => i.current)[0];
      let raid = item.raid.filter((i) => i.current)[0];

      if (raid.network_limit > 0) {
        item.network.forEach((i) => {
          i.disabled =
            i.amount * units[i.unit] >
            raid.network_limit * units[raid.network_limit_unit];
        });
      }
      item.raid.forEach((i) => {
        i.disabled =
          network.amount * units[network.unit] >
          i.network_limit * units[i.network_limit_unit];
      });

      setCurrentItem(item);
    }
  }, []);

  let { currentItem, priceCode } = dedicated;

  const getPriceStringify = (value) =>
    productKey[lang].price[priceCode].replace("$VALUE$", value);

  let discount = currentItem.discount ? (100 - currentItem.discount) / 100 : 0;

  const cpu = {
    title: productKey[lang].cpu,
    code: "cpu",
    list: currentItem.cpu,
    nameGetter(i) {
      return i.name;
    },
    subNameGetter(i) {
      return i.model;
    },
    priceGetter(i) {
      return getPriceStringify(
        discount ? i[`price_${priceCode}`] * discount : i[`price_${priceCode}`]
      );
    },
    change(n) {
      currentItem.cpu.forEach((i, index) => (i.current = index === n));
      setCurrentItem(currentItem);
    },
  };
  const ram = {
    title: productKey[lang].ram,
    code: "ram",
    list: currentItem.ram,
    nameGetter(i) {
      return `${i.amount} ${productKey[lang].units[i.unit]}`;
    },
    subNameGetter(i) {
      return productKey[lang][i.ddr];
    },
    priceGetter(i) {
      return getPriceStringify(
        discount ? i[`price_${priceCode}`] * discount : i[`price_${priceCode}`]
      );
    },
    change(n) {
      currentItem.ram.forEach((i, index) => (i.current = index === n));
      setCurrentItem(currentItem);
    },
  };
  const disk = {
    title: (
      <>
        {currentItem.disk_select === 0
          ? productKey[lang].without_disk
          : productKey[lang].disk}{" "}
        {currentItem.disk_limit > 0 && (
          <span>
            {productKey[lang].disk_limit.replace(
              "$VALUE$",
              currentItem.disk_limit
            )}
          </span>
        )}
      </>
    ),
    code: "disk",
    isCheckbox: !currentItem.disk_one_of_list,
    list: currentItem.disk,
    nameGetter(i) {
      return `${i.amount} ${productKey[lang].units[i.unit]}`;
    },
    subNameGetter(i) {
      return `${i.model} ${productKey[lang][i.type]}`;
    },
    priceGetter(i) {
      return getPriceStringify(
        discount ? i[`price_${priceCode}`] * discount : i[`price_${priceCode}`]
      );
    },
    change(n) {
      if (currentItem.disk_one_of_list) {
        currentItem.disk.forEach((i, index) => (i.current = index === n));
        setCurrentItem(currentItem);
      } else {
        currentItem.disk[n].current = !currentItem.disk[n].current;
        currentItem.disk_select = currentItem.disk
          .filter((i) => i.current)
          .reduce((acc, i) => acc + i.amountValue, 0);
        currentItem.disk.forEach((i) => {
          i.disabled =
            currentItem.disk_limit - currentItem.disk_select < i.amountValue;
        });
        setCurrentItem(currentItem);
      }
    },
    error: currentItem.disk_select === 0,
  };
  const disk_additional = {
    code: "disk_additional",
    list: currentItem.disk_additional,
    nameGetter(i) {
      return `${i.amount} ${productKey[lang].units[i.unit]}`;
    },
    subNameGetter(i) {
      return `${i.model} ${productKey[lang][i.type]}`;
    },
    priceGetter(i) {
      return getPriceStringify(
        discount ? i[`price_${priceCode}`] * discount : i[`price_${priceCode}`]
      );
    },
    change(n) {
      currentItem.disk_additional.forEach(
        (i, index) => (i.current = index === n)
      );
      setCurrentItem(currentItem);
    },
  };
  const raid = {
    title: productKey[lang].raid,
    code: "raid",
    list: currentItem.raid,
    nameGetter(i) {
      return i.type && i.model ? i.type : productKey[lang].without_raid;
    },
    subNameGetter(i) {
      return i.model;
    },
    priceGetter(i) {
      return getPriceStringify(
        discount ? i[`price_${priceCode}`] * discount : i[`price_${priceCode}`]
      );
    },
    change(n) {
      currentItem.raid.forEach((i, index) => (i.current = index === n));
      if (currentItem.raid[n].network_limit > 0) {
        currentItem.network.forEach((i) => {
          i.disabled =
            i.amount * units[i.unit] >
            currentItem.raid[n].network_limit *
              units[currentItem.raid[n].network_limit_unit];
        });
      } else {
        currentItem.network.forEach((i) => {
          i.disabled = false;
        });
      }
      setCurrentItem(currentItem);
    },
  };
  const management = {
    title: productKey[lang].management,
    code: "management",
    list: currentItem.management,
    nameGetter(i) {
      return i[`name_${lang}`];
    },
    subNameGetter(i) {
      return i.day;
    },
    priceGetter(i) {
      return getPriceStringify(
        discount ? i[`price_${priceCode}`] * discount : i[`price_${priceCode}`]
      );
    },
    change(n) {
      currentItem.management.forEach((i, index) => (i.current = index === n));
      setCurrentItem(currentItem);
    },
  };
  const network = {
    title: productKey[lang].network,
    code: "network",
    list: currentItem.network,
    nameGetter(i) {
      return `${i.amount} ${productKey[lang].units[i.unit]}`;
    },
    subNameGetter(i) {
      return i.name;
    },
    priceGetter(i) {
      return getPriceStringify(
        discount ? i[`price_${priceCode}`] * discount : i[`price_${priceCode}`]
      );
    },
    change(n) {
      currentItem.network.forEach((i, index) => (i.current = index === n));
      currentItem.raid.forEach((i) => {
        if (i.network_limit > 0)
          i.disabled =
            currentItem.network[n].amount * units[currentItem.network[n].unit] >
            i.network_limit * units[i.network_limit_unit];
      });
      setCurrentItem(currentItem);
    },
  };
  const bandwidth = {
    title: productKey[lang].bandwidth,
    code: "bandwidth",
    list: currentItem.bandwidth,
    nameGetter(i) {
      return `${i.speed} ${productKey[lang].units[i.unit]}`;
    },
    subNameGetter(i) {
      return `${getPriceStringify(
        discount ? i[`upto_${priceCode}`] * discount : i[`upto_${priceCode}`]
      )}/${productKey[lang].units[i.upto_unit]}`;
    },
    priceGetter(i) {
      return getPriceStringify(
        discount ? i[`price_${priceCode}`] * discount : i[`price_${priceCode}`]
      );
    },
    change(n) {
      currentItem.bandwidth.forEach((i, index) => (i.current = index === n));
      setCurrentItem(currentItem);
    },
    table: true,
    title2: productKey[lang].bandwidth_overusage,
  };

  return (
    <div className="d-calc__body container">
      <div className="d-calc__content">
        <h2 className="d-calc__title">
          {strapiProductDedicatedServer.calculation[`title_${lang}`]}
        </h2>
        <p className="d-calc__description">
          {strapiProductDedicatedServer.calculation[`description_${lang}`]}
        </p>
        <div className="d-calc__list">
          <CalcGroup {...cpu} />
          <CalcGroup {...ram} />
          <CalcGroup {...disk} />
          {currentItem.disk_additional &&
            currentItem.disk_additional.length > 0 && (
              <CalcGroup {...disk_additional} />
            )}
          <CalcGroup {...raid} />
          <CalcGroup {...management} />
          <CalcGroup {...network} />
          <CalcGroup {...bandwidth} />
        </div>
        <Responsive options={["mobile"]}>
          <div className="d-calc__result">
            <CalcResult lang={lang} />
          </div>
        </Responsive>
        <div className="d-calc__button-row">
          <Responsive options={["tab"]}>
            <div className="d-calc__result">
              <CalcResult lang={lang} />
            </div>
          </Responsive>
          <Link
            to={currentItem.disk_select === 0 ? "" : `/${lang}/${slug}/step-3`}
            className={`d-calc__button ${
              currentItem.disk_select === 0 ? "d-calc__button--disabled" : ""
            }`}
            state={currentItem}
          >
            {productKey[lang].continue}
          </Link>
        </div>
      </div>

      <Responsive options={["desktop"]}>
        <div className="d-calc__result">
          <CalcResult lang={lang} />
        </div>
      </Responsive>
    </div>
  );
};

let mapStateToProps = (state) => ({
  dedicated: state.dedicated,
});

export default connect(mapStateToProps, { setCurrentItem })(DCalc);
