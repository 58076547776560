import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setUtm } from "../../state/utmReducer"

const Utm = ({ utm = "", setUtm }) => {
  useEffect(() => {
    let newUtm = window ? window.location.search : ""
    if (newUtm !== "" && newUtm !== utm) setUtm(newUtm)
  })

  return <></>
}

let mapStateToProps = state => ({ utm: state.utm.utm })

export default connect(mapStateToProps, { setUtm })(Utm)
