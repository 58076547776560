import React from "react"
import "./NetworkMobileModal.css"
import ReactMarkdown from "react-markdown"
import NetworkMobileActiveMap from "./NetworkMobileActiveMap/NetworkMobileActiveMap"
import Icon from "../../../Sprite/Icon"
import Img from "gatsby-image"

const NetworkMobileModal = ({ toBack, activePoint, lang, utm = "" }) => {
  return (
    <div className="nm-modal">
      <div className="nm-modal__head">
        <button className="nm-modal__button" onClick={() => toBack()}>
          <Icon iconId="back" classes="nm-modal__button-icon" />
        </button>
        <h2 className="nm-modal__title">{activePoint[`title_${lang}`]}</h2>
      </div>
      {activePoint.photo && (
        <Img
          className="nm-modal__image"
          fluid={activePoint.photo.childImageSharp.fluid}
          alt={activePoint[`title_${lang}`]}
        />
      )}
      <div className="nm-modal__description">
        <ReactMarkdown
          source={activePoint[`description_${lang}`]}
          transformLinkUri={url =>
            url &&
            (url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&"))
          }
        />
      </div>
      <NetworkMobileActiveMap lang={lang} item={activePoint} />
    </div>
  )
}

export default NetworkMobileModal
