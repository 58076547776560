import React from "react"
import AnycastCdn from "../../svg/anycast-cdn"
import DedicatedServers from "../../svg/dedicated-servers"
import AnycastDns from "../../svg/anycast-dns"
import Domains from "../../svg/domains"
import Colocation from "../../svg/colocation"
import IpTransit from "../../svg/ip-transit"
import Administration from "../../svg/administration"
import Vds from "../../svg/vds"
import VideoCdn from "../../svg/video-cdn"
import StaticCdn from "../../svg/static-cdn"
import Ssl from "../../svg/ssl"
import BusinessSolutions from "../../svg/business-solutions"
import CloudStorsge from "../../svg/cloud-storage"
import DataCenter from "../../svg/data-centers"
import Equipment from "../../svg/equipment"
import IndividualServices from "../../svg/individual-services"
import ConnectingChannels from "../../svg/connecting-channels"
import Trust from "../../svg/trust"
import ExpertService from "../../svg/expert-service"
import Rocket from "../../svg/rocket"
import Mail from "../../svg/mail"
import Cap from "../../svg/cap"
import RocketHome from "../../svg/rocket_home"
import UAflag from "../../svg/uaflag"

const Illustrations = ({ illustration_id }) => {
  const illustration = id => {
    switch (id) {
      case "anycast_cdn": {
        return <AnycastCdn />
      }
      case "dedicated_servers": {
        return <DedicatedServers />
      }
      case "anycast_dns": {
        return <AnycastDns />
      }
      case "domains": {
        return <Domains />
      }
      case "colocation": {
        return <Colocation />
      }
      case "ip_transit": {
        return <IpTransit />
      }
      case "administration": {
        return <Administration />
      }
      case "vds": {
        return <Vds />
      }
      case "video_cdn": {
        return <VideoCdn />
      }
      case "static_cdn": {
        return <StaticCdn />
      }
      case "ssl": {
        return <Ssl />
      }
      case "business_solutions": {
        return <BusinessSolutions />
      }
      case "cloud_storage": {
        return <CloudStorsge />
      }
      case "data_centers": {
        return <DataCenter />
      }
      case "equipment": {
        return <Equipment />
      }
      case "individual_services": {
        return <IndividualServices />
      }
      case "connecting_channels": {
        return <ConnectingChannels />
      }
      case "trust": {
        return <Trust />
      }
      case "expert_service": {
        return <ExpertService />
      }
      case "rocket": {
        return <Rocket />
      }
      case "mail": {
        return <Mail />
      }
      case "cap": {
        return <Cap />
      }
      case "rocket_home": {
        return <RocketHome />
      }
      case "uaflag": {
        return <UAflag />
      }
    }
  }
  return <>{illustration(illustration_id)}</>
}

export default Illustrations
