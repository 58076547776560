const UPDATE_PAGES = "UPDATE-PAGES"
const TEST_THUNK = "TEST-THUNK"

const initialState = {
  isThunk: false,
  pages: [
    {
      title: "Initial title",
      id: "initial-slug",
    },
  ],
}

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAGES: {
      return {
        ...state,
        pages: action.pages,
      }
    }
    case TEST_THUNK: {
      return {
        ...state,
        isThunk: true,
      }
    }
    default:
      return state
  }
}

export const testThunkAC = () => ({ type: TEST_THUNK })
export const updatePages = pages => ({ type: UPDATE_PAGES, pages })

export const testThunk = () => async dispatch => {
  dispatch(testThunkAC())
}

export default pageReducer
