import React, { useEffect, useRef, useState } from "react"
import "./Menu.css"
import MenuItem from "./MenuItem/MenuItem"
import Responsive from "../../Responsive/Responsive"

const Menu = ({
  lang,
  slug,
  onToggleMenu,
  headerState,
  head,
  utm = "",
  data,
}) => {
  const { mainMenu } = data
  const ref = useRef(null)
  let [activeMenu, setActiveMenu] = useState(mainMenu.map(() => false))

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActiveMenu(mainMenu.map(() => false))
        onToggleMenu(false)
      }
    }
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [ref])

  useEffect(() => {
    if (headerState === "disable") {
      setActiveMenu(mainMenu.map(() => false))
      onToggleMenu(false)
    }
  }, [headerState])

  const toggleMenuByIndex = index => {
    if (activeMenu[index]) {
      setActiveMenu(mainMenu.map(() => false))
      onToggleMenu(false)
    } else {
      setActiveMenu(activeMenu.map((i, n) => n === index))
      onToggleMenu(true)
    }
  }

  return (
    <Responsive options={["desktop"]}>
      <nav ref={ref} className={"menu"}>
        <ul className={"menu__list"}>
          {mainMenu.map((item, index) => (
            <MenuItem
              key={index}
              lang={lang}
              head={head}
              utm={utm}
              slug={slug}
              isActiveSubMenu={activeMenu[index]}
              onToggle={() => toggleMenuByIndex(index)}
              name={item[`name_${lang}`]}
              pages={item.pages}
              description={item[`description_${lang}`]}
              subcategories={item.subcategories}
            />
          ))}
        </ul>
      </nav>
    </Responsive>
  )
}

export default Menu
