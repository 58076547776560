import React from "react"
import "./SubMenu.css"
import { Link } from "gatsby"

const SubMenu = ({
  lang,
  slug,
  head,
  utm = "",
  pages,
  subcategories,
  name,
  description,
}) => {
  return (
    <div className="submenu">
      <div className="submenu__body container">
        <div
          className={`submenu__text ${
            subcategories ? "submenu__text--subcategories" : ""
          }`}
        >
          <h3 className="submenu__name">{name}</h3>
          <p className="submenu__description">{description}</p>
        </div>
        <div className="submenu__nav-box">
          {subcategories &&
            subcategories.map(subcategory => (
              <div
                key={subcategory[`name_${lang}`]}
                className="submenu__subcategory"
              >
                <h4 className="submenu__subcategory-name">
                  {subcategory[`name_${lang}`]}
                </h4>
                <ul className="submenu__pages">
                  {subcategory.pages.map(page => (
                    <li key={page.slug} className="submenu__page-item">
                      {page.template === "sub_pages" ? (
                        head === page.slug ? (
                          <span className="submenu__page-current">
                            {page[`title_${lang}`]}
                          </span>
                        ) : (
                          <Link
                            to={`/${lang}/${page.slug}/${page.current_sub_page.slug}/${utm}`}
                            className="submenu__page-link"
                          >
                            {page[`title_${lang}`]}
                          </Link>
                        )
                      ) : page.slug === slug ? (
                        <span className="submenu__page-current">
                          {page[`title_${lang}`]}
                        </span>
                      ) : (
                        <Link
                          to={`/${lang}/${page.slug}/${utm}`}
                          className="submenu__page-link"
                        >
                          {page[`title_${lang}`]}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          {pages && (
            <ul className="submenu__pages">
              {pages.map(page => (
                <li key={page.slug} className="submenu__page-item">
                  {page.template === "sub_pages" ? (
                    head === page.slug ? (
                      <span className="submenu__page-current">
                        {page[`title_${lang}`]}
                      </span>
                    ) : (
                      <Link
                        to={`/${lang}/${page.slug}/${page.current_sub_page.slug}/${utm}`}
                        className="submenu__page-link"
                      >
                        {page[`title_${lang}`]}
                      </Link>
                    )
                  ) : page.slug === slug ? (
                    <span className="submenu__page-current">
                      {page[`title_${lang}`]}
                    </span>
                  ) : (
                    <Link
                      to={`/${lang}/${page.slug}/${utm}`}
                      className="submenu__page-link"
                    >
                      {page[`title_${lang}`]}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default SubMenu
