import React, { useEffect, useRef, useState } from "react"
import "./Advantages.css"
import AdvantageItem from "./AdvantageItem/AdvantageItem"

const Advantages = ({ title, items }) => {
  let [actives, setActives] = useState(items.map(i => false))
  const ref = useRef(null)

  let toggleActiveByIndex = index => {
    if (!actives[index]) setActives(actives.map((i, n) => index === n))
    else setActives(actives.map(() => false))
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActives(actives.map(() => false))
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, actives])
  return (
    <div className="advantages container">
      <h2 className="section-title advantages__title">{title}</h2>
      <div className="advantages__grid" ref={ref}>
        {items.map((i, n) => (
          <AdvantageItem
            key={i.name}
            active={actives[n]}
            toggle={() => toggleActiveByIndex(n)}
            name={i.name}
            description={i.description}
            illustration={i.illustration}
            grid_position={i.grid_position}
          />
        ))}
      </div>
    </div>
  )
}

export default Advantages
