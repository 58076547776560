const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/var/www/develop/.cache/dev-404-page.js"))),
  "component---src-pages-404-js": hot(preferDefault(require("/var/www/develop/src/pages/404.js"))),
  "component---src-pages-icons-js": hot(preferDefault(require("/var/www/develop/src/pages/icons.js"))),
  "component---src-pages-index-js": hot(preferDefault(require("/var/www/develop/src/pages/index.js"))),
  "component---src-templates-calc-en-js": hot(preferDefault(require("/var/www/develop/src/templates/calc.en.js"))),
  "component---src-templates-checkout-en-js": hot(preferDefault(require("/var/www/develop/src/templates/checkout.en.js"))),
  "component---src-templates-index-en-js": hot(preferDefault(require("/var/www/develop/src/templates/index.en.js"))),
  "component---src-templates-page-en-js": hot(preferDefault(require("/var/www/develop/src/templates/page.en.js"))),
  "component---src-templates-sub-page-en-js": hot(preferDefault(require("/var/www/develop/src/templates/sub_page.en.js"))),
  "component---src-templates-wiki-en-js": hot(preferDefault(require("/var/www/develop/src/templates/wiki.en.js")))
}

