import React from "react"
import Img from "gatsby-image"

const ContentImage = ({ alt, src, allFile }) => {
  let nameReg = /\/uploads\/(.*)\./g
  let name = nameReg.exec(src)[1]
  let image = allFile.edges.filter(({ node }) => node.name === name)
  image = image[0] ? image[0].node.childImageSharp.fluid : null

  return <Img fluid={image} alt={alt} />
}

export default ContentImage
