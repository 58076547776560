const SET_UTM = "SET-UTM"

const initialState = {
  utm: "",
}

const utmReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UTM: {
      return {
        ...state,
        utm: action.utm,
      }
    }
    default:
      return state
  }
}

export const setUtm = utm => ({ type: SET_UTM, utm })

export default utmReducer
