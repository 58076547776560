import React, { useEffect, useState } from "react"
import "./MobileMenu.css"
import Icon from "../../Sprite/Icon"
import SubMenu from "../../MenuList/MenuList"
import Responsive from "../../Responsive/Responsive"
import { lockScroll, unlockScroll } from "../../../methods/scroll"

const MobileMenu = ({ lang, slug, head, utm = "", onToggleMenu, data }) => {
  const { mainMenu } = data

  let [activeMenu, setActiveMenu] = useState(false)

  useEffect(() => {
    unlockScroll()
  }, [])

  let toggleMobileMenu = () => {
    if (activeMenu) {
      unlockScroll()
      setActiveMenu(!activeMenu)
      onToggleMenu(false)
    } else {
      lockScroll()
      setActiveMenu(!activeMenu)
      onToggleMenu(true)
    }
  }

  return (
    <div className="mobile-menu">
      <button className="mobile-menu__btn" onClick={toggleMobileMenu}>
        {activeMenu ? (
          <Icon iconId="close_big" classes="mobile-menu__icon" />
        ) : (
          <Icon iconId="menu" classes="mobile-menu__icon" />
        )}
      </button>
      {activeMenu && (
        <div className="mobile-submenu container">
          <Responsive options={["tab", "mobile"]}>
            <div className="mobile-submenu__control">
              <span
                className="mobile-submenu__close-btn"
                onClick={toggleMobileMenu}
              >
                <Icon iconId="close_big" classes="mobile-submenu__close-icon" />
              </span>
            </div>
          </Responsive>
          {mainMenu.map((item, index) => (
            <SubMenu
              classes="mobile-submenu"
              key={index}
              lang={lang}
              slug={slug}
              head={head}
              utm={utm}
              name={item[`name_${lang}`]}
              pages={item.pages}
              subcategories={item.subcategories}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default MobileMenu
