import React from "react"
import GlobalStyle from "../GlobalStyle/GlobalStyle"
import LayoutPage from "../LayoutPage/LayoutPage"
import Seo from "../Seo/Seo"
import Hero from "../Header/Hero/Hero"
import ContactLine from "../ContactLine/ContactLine"
import Utm from "../Utm/Utm"
import { connect } from "react-redux"
import WikiLayout from "../Wiki/WikiLayout/WikiLayout"
import ScrollTop from "../ScrollTop/ScrollTop"

const WikiListTemplate = ({
  lang,
  slug,
  utm = "",
  head,
  title,
  seo,
  hero,
  content,
  isContactLine,
  isSubscribe,
  data,
}) => {
  return (
    <>
      <GlobalStyle />
      <Utm />
      <ScrollTop lang={lang} />
      <LayoutPage lang={lang} slug={slug} head={head} utm={utm} data={data} isSubscribe={isSubscribe}>
        <Seo
          lang={lang}
          seo={seo}
          slug={slug}
          defaultTitle={title}
          data={data}
        />
        {hero && <Hero lang={lang} hero={hero} utm={utm} />}
        <WikiLayout
          content={content}
          lang={lang}
          slug={slug}
          head={head}
          utm={utm}
          data={data}
        />
        {isContactLine && (
          <ContactLine
            lang={lang}
            utm={utm}
            withNav={
              content &&
              content.length &&
              content[content.length - 1].type === "large_text_with_nav"
            }
            data={data}
          />
        )}
      </LayoutPage>
    </>
  )
}

let mapStateToProps = state => ({ utm: state.utm.utm })

export default connect(mapStateToProps)(WikiListTemplate)
