import React from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import "./NetworkMobileActiveMap.css"

mapboxgl.accessToken =
  "pk.eyJ1IjoiZnJvbnRib25kIiwiYSI6ImNqMHc5bjdveTAwMDYzM21vNnRpZmtnNHEifQ.DesrH_FjshLanftsGwwm8w"

class NetworkMobileActiveMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lng: 5,
      lat: 34,
      zoom: 2,
      colors: {
        cdn: "#4db6ac",
        host: "#e54949",
        mix: "#7391a0",
      },
      style: {
        ru: "mapbox://styles/frontbond/cj1hlf124002r2rmtpzz1btov",
        en: "mapbox://styles/frontbond/cj3vjjw8d075j2smjuubz48to",
      },
    }
  }

  componentDidMount() {
    this.mapStore = function () {
      return {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              id: this.props.item.id,
              title: this.props.item[`title_${this.props.lang}`],
              address: this.props.item[`address_${this.props.lang}`],
              type: this.props.item.type,
            },
            geometry: {
              type: "Point",
              coordinates: this.props.item.coordinates,
            },
          },
        ],
      }
    }.bind(this)

    this.colorByType = [
      "case",
      ["==", ["get", "type"], "cdn"],
      this.state.colors.cdn,
      ["==", ["get", "type"], "host"],
      this.state.colors.host,
      this.state.colors.host,
    ]

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: this.state.style[this.props.lang],

      center: [this.props.item.coordinates[0], this.props.item.coordinates[1]],
      zoom: 5,
    })

    this.map.on(
      "load",
      function () {
        this.map.addSource("mapData", {
          type: "geojson",
          data: this.mapStore(),
          cluster: true,
          clusterMaxZoom: 12,
          clusterRadius: 18,
          clusterProperties: {
            cdn: ["+", ["case", ["==", ["get", "type"], "cdn"], 1, 0]],
            host: ["+", ["case", ["==", ["get", "type"], "host"], 1, 0]],
          },
        })

        this.map.addLayer({
          id: "mapOne",
          type: "circle",
          source: "mapData",
          filter: ["!has", "point_count"],
          paint: {
            "circle-color": this.colorByType,
            "circle-radius": 12.5,
            "circle-stroke-width": 1,
            "circle-stroke-color": this.colorByType,
          },
        })

        this.map.addLayer({
          id: "mapGroup",
          type: "circle",
          source: "mapData",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "case",
              [">", ["get", "cdn"], ["get", "host"]],
              this.state.colors.cdn,
              ["<", ["get", "cdn"], ["get", "host"]],
              this.state.colors.host,
              ["==", ["get", "cdn"], ["get", "host"]],
              this.state.colors.mix,
              this.state.colors.mix,
            ],
            "circle-radius": 22.5,
            "circle-stroke-width": 0,
          },
        })

        //preparing special layers
        this.map.addLayer({
          id: "mapOneActive",
          type: "circle",
          source: "mapData",
          filter: ["==", "id", this.props.item.id],
          paint: {
            "circle-color": "#fff",
            "circle-radius": 12.5,
            "circle-stroke-width": 1,
            "circle-stroke-color": this.colorByType,
          },
        })
        this.map.addLayer({
          id: "mapOneActiveDot",
          type: "circle",
          source: "mapData",
          filter: ["==", "id", this.props.item.id],
          paint: {
            "circle-color": this.colorByType,
            "circle-radius": 9.5,
          },
        })

        this.map.addLayer({
          id: "mapSelectActive",
          type: "circle",
          source: "mapData",
          filter: ["==", "id", this.props.item.id],
          paint: {
            "circle-color": "#fff",
            "circle-radius": 15,
            "circle-stroke-width": 1,
            "circle-stroke-color": this.colorByType,
          },
        })
        this.map.addLayer({
          id: "mapSelectActiveDot",
          type: "circle",
          source: "mapData",
          filter: ["==", "id", this.props.item.id],
          paint: {
            "circle-color": this.colorByType,
            "circle-radius": 9.5,
          },
        })
        //=======
      }.bind(this)
    )
  }

  componentDidUpdate(prevProps) {
    if (this.map.loaded()) {
      this.map.getSource("mapData") &&
        this.map.getSource("mapData").setData(this.mapStore())
    } else {
      this.map.on(
        "load",
        function () {
          this.map.getSource("mapData") &&
            this.map.getSource("mapData").setData(this.mapStore())
        }.bind(this)
      )
    }
  }

  render() {
    return (
      <div className="network-mobile-active-map">
        <div ref={el => (this.mapContainer = el)} className="mapContainer" />
      </div>
    )
  }
}

export default NetworkMobileActiveMap
