import React from "react"
import "./CDNGroup.css"
import CdnItem from "./CDNItem/CDNItem"
import CdnItemNew from "./CDNItemNew/CDNItemNew"

const CdnGroup = ({ list, lang, utm = "", body }) => {
  return (
    <ul className={`cdn-group ${body?.style ? "cdn-group--" + body?.style : ""}`}>
      {list.map((item, n) => (
        body.style
        ? <CdnItemNew key={n} item={item} lang={lang} utm={utm} body={body}/>
        : <CdnItem key={n} item={item} lang={lang} utm={utm}/>
      ))}
    </ul>
  )
}

export default CdnGroup
