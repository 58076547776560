import React from "react"
import "./ContactList.css"
import ReactMarkdown from "react-markdown"
import Icon from "../../Sprite/Icon";

const ContactList = ({ body, lang, utm = "" }) => {
  return (
    <section className={`container contact-list ${body.theme ? "contact-list--" + body.theme : ""}`}>
      <h2 className="contact-list__title">{body[`title_${lang}`]}</h2>
      <div className={`contact-list__grid contact-list__grid--${body.column}`}>
        {body.contact_list_item.map((item, n) => (
          <div key={n} className="contact-list___grid-item">
            <h3 className="contact-list__name">
              {item.icon && body.theme ==="new" &&
              <Icon
                iconId={`contact_${item.icon}`}
                classes="contact-list__icon"
                width={26}
                height={26}
              />
              }
              {item[`name_${lang}`]}
            </h3>
            <div className="contact-list__info">
              <ReactMarkdown
                source={item[`info_${lang}`]}
                transformLinkUri={url =>
                  url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&")
                }
              />
            </div>
          </div>
        ))}
      </div>
      <div className="contact-list__after-text">
        <ReactMarkdown
          source={body[`after_text_${lang}`]}
          transformLinkUri={url =>
            url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&")
          }
        />
      </div>
    </section>
  )
}

export default ContactList
