import React from "react"
import "./COption.css"
import Icon from "../../../../../Sprite/Icon"

const COption = ({ icon, name, subname }) => {
  return (
    <li className="c-option">
      <Icon width={24} height={24} iconId={icon} classes="c-option__icon" />
      <span className="c-option__name">{name}</span>
      <span className="c-option__subname">{subname}</span>
    </li>
  )
}

export default COption
