import React, { useState } from "react";
import "./WikiNav.css";
import { Link } from "gatsby";

const WikiNavItem = ({
  item,
  childList,
  allList,
  currentSlug,
  lang,
  utm = "",
}) => {
  const getWikiSlug = (item, newSlug = "") => {
    newSlug = `/${item.slug}${newSlug}`;
    return item.head
      ? getWikiSlug(
          allList.edges.filter((i) => i.node.slug === item.head.slug)[0].node,
          newSlug
        )
      : newSlug;
  };

  const getActive = (item, allList, currentSlug) => {
    if (currentSlug !== null) {
      if (item.slug === currentSlug && !item.head) return true;
      let currentItem = allList.edges.filter(
        (i) => i.node.slug === currentSlug
      )[0];
      return item.slug === currentSlug
        ? true
        : currentItem.node.head
        ? getActive(item, allList, currentItem.node.head.slug)
        : false;
    } else return false;
  };

  let [active, setActive] = useState(getActive(item, allList, currentSlug));

  return (
    <li className="wiki-nav__item">
      {childList && (
        <button
          onClick={() => setActive(!active)}
          className={`wiki-nav__list-button ${
            active ? "wiki-nav__list-button--active" : ""
          }`}
        ></button>
      )}
      {currentSlug !== item.slug ? (
        <Link
          to={`/${lang}/wiki${getWikiSlug(item)}${utm}`}
          className="wiki-nav__link"
        >
          {item[`title_${lang}`]}
        </Link>
      ) : (
        <span className="wiki-nav__current">{item[`title_${lang}`]}</span>
      )}

      {childList && (
        <ul
          className={`wiki-nav__sub-menu ${
            active ? "wiki-nav__sub-menu--active" : ""
          }`}
        >
          {childList.sort((a,b) =>{
            return(a.node.position - b.node.position)
          }).map((i, n) => (
            <WikiNavItem
              key={n}
              utm={utm}
              currentSlug={currentSlug}
              item={i.node}
              childList={
                i.node.childs.length > 0
                  ? allList.edges.filter(
                      (wiki) =>
                        wiki.node.head && wiki.node.head.slug === i.node.slug
                    )
                  : null
              }
              allList={allList}
              lang={lang}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const WikiNav = ({ currentSlug, lang, utm = "", data }) => {
  const { allStrapiWiki } = data;
  return (
    <ul className="wiki-nav">
      {allStrapiWiki.edges
        .filter((headWiki) => !headWiki.node.head)
        .sort((a,b) =>{
          return(a.node.position - b.node.position)
        })
        .map((headWiki, n) => (
          <WikiNavItem
            key={n}
            utm={utm}
            currentSlug={currentSlug}
            item={headWiki.node}
            allList={allStrapiWiki}
            childList={
              headWiki.node.childs.length > 0
                ? allStrapiWiki.edges.filter(
                    (wiki) =>
                      wiki.node.head &&
                      wiki.node.head.slug === headWiki.node.slug
                  )
                : null
            }
            lang={lang}
          />
        ))}
    </ul>
  );
};

export default WikiNav;
