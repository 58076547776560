import React, { useEffect } from "react";

const ContactForm = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://webforms.pipedrive.com/f/loader";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="pipedriveWebForms"
      style={{ padding: 20, display: "flex", "justify-content": "center" }}
      data-pd-webforms="https://webforms.pipedrive.com/f/6N8hcZzylBy3chpDjtopDBdp1oHJJ6lZK5bzn4s69V16mqS0q9LEqwWspTGHYAAI9R"
    />
  );
};

export default ContactForm;
