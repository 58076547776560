import React from "react";
import "./Hero.css";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";
import Animation from "../../Animation/Animation";
import HeroImage from "./HeroImage";

// AHW-541
const calendly = "https://calendly.com/advanced-hosting/30min";
const tg = "https://advancedhosting.com/en/contact-us";

const Hero = ({ hero, lang, utm = "" }) => {

  const url1 = hero[`button_url_${lang}`] === "https://t.me/ahnick" ? tg : hero[`button_url_${lang}`];
  const url2 = hero[`second_button_url_${lang}`] === "https://calendly.com/nikolay-yavorovskyi" ? calendly : hero[`second_button_url_${lang}`];

  return (
    <div className={`hero ${hero.style ? "hero--" + hero.style : ""}`}>
      <div className="hero__body container">
        <div className="hero__text-box">
          {hero[`title_${lang}`] && (
            <h1 className="hero__title"> {hero[`title_${lang}`]} </h1>
          )}
          {hero[`description_${lang}`] && (
            <div className="hero__description">
              <ReactMarkdown source={hero[`description_${lang}`]} />
            </div>
          )}
          <div className="hero__button-block">

          </div>
          {hero[`button_text_${lang}`] &&
            <div className="hero__button-block">
              {(hero[`button_text_${lang}`] && url1 && (
                <a
                  className="btn btn--red hero__button"
                  href={
                    url1.indexOf("?") < 0
                      ? url1 + utm
                      : url1 + utm.replace("?", "&")
                  }
                  target="_blank"
                  rel= "noreferrer noopener"
                >
                  {hero[`button_text_${lang}`]}
                </a>
              )) ||
              (hero[`button_text_${lang}`] && hero.to_page.slug && (
                <Link
                  className="btn btn--red hero__button"
                  to={`/${lang}/${hero.to_page.slug}/${utm}`}
                >
                  {hero[`button_text_${lang}`]}
                </Link>
              ))}
              {(hero[`second_button_text_${lang}`] && url2 && (
                <a
                  className="btn btn--red btn--clear hero__button"
                  href={
                    url2.indexOf("?") < 0
                      ? url2 + utm
                      : url2 + utm.replace("?", "&")
                  }
                  target="_blank"
                  rel= "noreferrer noopener"
                >
                  {hero[`second_button_text_${lang}`]}
                </a>
              )) ||
              (hero[`second_button_text_${lang}`] && hero.to_page.slug && (
                <Link
                  className="btn btn--red btn--clear hero__button"
                  to={`/${lang}/${hero.to_second_page.slug}/${utm}`}
                >
                  {hero[`second_button_text_${lang}`]}
                </Link>
              ))}
            </div>}
        </div>

        <div className="hero__img">
          {hero.illustration.image && (
            <HeroImage image={hero.illustration.image} />
          )}
          {hero.illustration.illustration_id && (
            <Animation illustration_id={hero.illustration.illustration_id} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
