import React from "react"
import GlobalStyle from "../GlobalStyle/GlobalStyle"
import WikiBreadcrumbs from "../Wiki/WikiBreadcrumbs/WikiBreadcrumbs"
import LightLayoutPage from "../LayoutPage/LightLayoutPage"
import Wiki from "../Wiki/Wiki"
import Utm from "../Utm/Utm"
import { connect } from "react-redux"
import ScrollTop from "../ScrollTop/ScrollTop"
import Seo from "../Seo/Seo"

const WikiDefault = ({
  lang,
  slug,
  seo,
  title,
  body,
  currentSlug,
  utm = "",
  data,
}) => {
  const { allStrapiWiki } = data
  const getWikiSlug = (item, newSlug = "") => {
    newSlug = `/${item.slug}${newSlug}`
    return item
      ? item.head && item.head.published
        ? getWikiSlug(
            allStrapiWiki.edges.filter(i => i.node.slug === item.head.slug)[0]
              .node,
            newSlug
          )
        : newSlug
      : false
  }

  let currentWikiSlug = `wiki${getWikiSlug(
    allStrapiWiki.edges.filter(i => i.node.slug === currentSlug)[0].node
  )}`
  return (
    <>
      <GlobalStyle />
      <Utm />
      <ScrollTop lang={lang} />
      <Seo
        lang={lang}
        seo={seo}
        slug={currentWikiSlug}
        defaultTitle={title}
        data={data}
      />
      <LightLayoutPage
        slug={currentWikiSlug}
        lang={lang}
        utm={utm}
        breadcrumbs={
          <WikiBreadcrumbs
            currentSlug={currentSlug}
            lang={lang}
            utm={utm}
            data={data}
          />
        }
        data={data}
      >
        <Wiki
          title={title}
          body={body}
          currentSlug={currentSlug}
          lang={lang}
          utm={utm}
          data={data}
        />
      </LightLayoutPage>
    </>
  )
}

let mapStateToProps = state => ({
  utm: state.utm.utm,
})
export default connect(mapStateToProps)(WikiDefault)
