import React from 'react';
import './ColumnFeatures.css';
import Icon from "../../Sprite/Icon";

const ColumnFeaturesItem = ({ title, description, iconId}) => {
  return (
   <div className="column-features__item">
     <h3 className="column-features__item-title">
       {iconId &&
       <Icon
         iconId={iconId}
         classes={"column-features__item-icon"}
         width={44}
         height={44}
       />}
       {title}
     </h3>
     <p className="column-features__item-description">{description}</p>
   </div>
  )
};

export default ColumnFeaturesItem;