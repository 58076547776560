import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper.min.css";
import "./Slider.css";
import { Link } from "gatsby";
import Animation from "../Animation/Animation";
import Responsive from "../Responsive/Responsive";

const Slider = ({ items, utm }) => {
  let [currentSlide, setCurrentSlide] = useState(null);
  const params = {
    touchEventsTarget: "wrapper",
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    on: {
      init: function () {
        setCurrentSlide(this.activeIndex - 1);
      },
      slideChange: function () {
        if (this.activeIndex === 0) this.slideTo(items.length, 0);
        if (this.activeIndex === items.length + 1) this.slideTo(1, 0);
        setCurrentSlide(this.activeIndex - 1);
      },
    },
    spaceBetween: 0,
    loop: true,
  };

  return (
    <div className="slider rocket">
        <Swiper {...params} >
        {items.map((slide, n) => (
          <div key={slide.name} className="slide_item">
            <div className="container">
              <div
                className={`slider-img-block ${
                  slide.illustration_full_size
                    ? "slider-img-block--full-size"
                    : ""
                }`}
              >
                <Animation
                  illustration_id={slide.illustration}
                  isAnim={n === currentSlide}
                />
              </div>
              <div
                className={`slider-text-block ${
                  n === currentSlide ? "slider-text-block--anim" : ""
                }`}
              >
                <p className="slider-sub-title">{slide.name}</p>
                <h2 className="slider-title">{slide.title}</h2>
                <Responsive options={["desktop"]}>
                  <p className="slider-description">{slide.description}</p>
                  {slide.button_url && (
                    <a
                      href={slide.button_url}
                      className="btn btn--clear btn--large"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {slide.button_text}
                    </a>
                  )}
                  {!slide.button_url && slide.page_link && (
                    <Link
                      to={slide.page_link + utm}
                      className="btn btn--clear btn--large"
                    >
                      {slide.button_text}
                    </Link>
                  )}
                </Responsive>
              </div>
            </div>
          </div>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
