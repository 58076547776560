import React from "react"
import LargeText from "../PageContent/LargeText/LargeText"
import "./ContactLine.css"

const ContactLine = ({ lang, withNav, data }) => {
  const { strapiContactLine } = data
  return (
    <>
      {strapiContactLine && (
        <div className="contact-line container">
          <div
            className={`contact-line__body ${
              withNav ? "contact-line__body--with-nav" : ""
            }`}
          >
            <h3 className="title contact-line__title">
              {strapiContactLine[`title_${lang}`]}
            </h3>
            <LargeText lang={lang} body={strapiContactLine} />
          </div>
        </div>
      )}
    </>
  )
}

export default ContactLine
