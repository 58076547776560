import React from "react";
import Header from "../Header/Header";
import Subscribe from "../Subscribe/Subscribe";
import Footer from "../Footer/Footer";
import Sprite from "../Sprite/Sprite";
import "./LayoutPage.css";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Responsive from "../Responsive/Responsive";
import BreadcrumbsBlog from "../Breadcrumbs/BreadcrumbsBlog";
import CookieNotice from "../CookieNotice/CookieNotice";

const LayoutPage = ({ lang, slug, head, children, type, utm = "", data, isSubscribe = true }) => {
  return (
    <div className="layout-page">
      <Header lang={lang} slug={slug} head={head} utm={utm} data={data} />
      <Responsive options={["desktop", "tab"]}>
        {!type && (
          <Breadcrumbs
            lang={lang}
            slug={slug}
            head={head}
            utm={utm}
            data={data}
          />
        )}
        {type === "blog" && (
          <BreadcrumbsBlog lang={lang} slug={slug} utm={utm} data={data} />
        )}
      </Responsive>
      <div className="layout-page__body">{children}</div>
      {isSubscribe && <Subscribe lang={lang} data={data} />}
      <Footer lang={lang} head={head} slug={slug} utm={utm} data={data} />
      <CookieNotice lang={lang} data={data}/>
      <Sprite />
    </div>
  );
};

export default LayoutPage;
