import React from 'react';
import './AboutUsStatistics.css';
import Icon from "../../Sprite/Icon";
import AboutUsStatisticsFeatureItem from "./AboutUsStatisticsFeatureItem";

const AboutUsStatistics = ({body, lang}) => {
  return (
   <div className={`about-us-statistics ${body.theme ? "about-us-statistics--" + body.theme : ""}`}>
     {}
     <div className="container">
       <div className="about-us-statistics__top">
         {body[`title_${lang}`] &&
          <h2 className="about-us-statistics__title">{body[`title_${lang}`]}</h2>
         }
         {body[`description_${lang}`] &&
          <p className="about-us-statistics__descriptions">{body[`description_${lang}`]}</p>
         }
         <div className='about-us-statistics__features'>
           {body.feature_item.map((item, n) =>
             <AboutUsStatisticsFeatureItem key={n} data={item} lang={lang}/>
           )}
         </div>
       </div>
       {body.about_item.length ?
         <div className="about-us-statistics__bottom">
           {body.about_item.map((item, n) =>
             <div key={n} className="about-us-statistics__bottom-item">
               <Icon iconId={item.icon} classes="about-us-statistics__bottom-item-icon" width={100} height={100}/>
               <div className='about-us-statistics__bottom-text-block'>
                 <h3 className="about-us-statistics__bottom-item-title">{item[`name_${lang}`]}</h3>
                 <p className="about-us-statistics__bottom-item-description">{item[`description_${lang}`]}</p>
               </div>
             </div>
           )}
         </div>
         : null
       }
     </div>
   </div>
  )
};

export default AboutUsStatistics;