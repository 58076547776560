import React from 'react';
import './Reviews.css';
import Swiper from "react-id-swiper";

import Review from "./Review";

const Reviews = ({body, lang}) => {

  const params = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    spaceBetween: 20,
    loop: false,
    // on: {
    //   slideChange: function () {
    //     if (this.activeIndex === 0) this.slideTo(body.reviews_item.length, 0);
    //     if (this.activeIndex === body.reviews_item.length + 1) this.slideTo(1, 0);
    //   },
    // }
  };

  return (
   <div className="reviews">
     <div className="container">
       <h2 className="section-title reviews__title">{body[`title_${lang}`]}</h2>
       {body.reviews_item.length <= 1
         ? <div className="review__list">
             {body.reviews_item.map((item, n) =>
               <Review key={n} item={item} lang={lang}/>
             )}
           </div>
         : <Swiper {...params}>
            {body.reviews_item.map((item, n) =>
              <div key={n} className="review-slide">
                <Review  item={item} lang={lang}/>
              </div>
            )}
           </Swiper>
       }
     </div>
   </div>
  )
};

export default Reviews;