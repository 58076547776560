import React from "react";
import "./CDNItemNew.css";
import Icon from "../../../../Sprite/Icon";
import productKey from "../../../../../language/productKey";

const CDNItemNew = ({ item, lang, utm = "", body }) => {
  const getNumerator = (i) => {
    switch (i) {
      case 1:
        return 0;
      case 2:
      case 3:
      case 4:
        return 1;
      default:
        return 2;
    }
  };
  const getPriceStringify = (value) =>
    productKey[lang].price.us.replace("$VALUE$", value);
  return (
    <li className={`cdn-item ${ body.style ? "cdn-item--" + body.style : ""}`}>
      <h5 className="cdn-item__stoke">{productKey[lang][item.stoke]}</h5>
        {item.tariff_type ?
            <Icon
            width={150}
            height={35}
            iconId={`tariff_${item.tariff_type}`}
            classes={"cdn-item__tariff-icon"}
            />
        : ""}
      <h3 className="cdn-item__title">
        <span className="cdn-item__title-name">{item.name}</span>
        {item.tariff_type ? "" : <span className="cdn-item__title-text">{item.type}</span>}
      </h3>
      <ul className="cdn-item__feature-list">
        {Number(item.free_amount) > 0 && item.free_unit?.length > 0
          ? <li className="cdn-item__option">
            <Icon
              width={18}
              height={18}
              iconId={`free_${body.style}`}
              classes={"cdn-item__option-icon"}
            />
            <span className="cdn-item__option-name">{productKey[lang].free}</span>
            {`${item.free_amount} ${
              productKey[lang][item.free_unit][getNumerator(item.free_amount)]
            }`}
          </li>
          : null
        }
        <li className="cdn-item__option">
          <Icon
            width={18}
            height={18}
            iconId={`traffic_${body.style}`}
            classes={"cdn-item__option-icon"}
          />

          {item.traffic_speed > 0 && (
            <span className="cdn-item__option-name">{`${item.traffic_speed} ${
              productKey["new"].units[item.traffic_speed_unit]
            }`}</span>
          )}
          {item.traffic_speed > 0 &&
            item.traffic_amount > 0 &&
            productKey[lang].or}
          {item.traffic_amount > 0 && (
            <span className="cdn-item__option-name">{`${item.traffic_amount} ${
              productKey["new"].units[item.traffic_amount_unit]
            }`}</span>
          )}
          {productKey[lang].of_traffic}
        </li>
        <li className="cdn-item__option">
          <Icon
            width={18}
            height={18}
            iconId={`ssd_${body.style}`}
            classes={"cdn-item__option-icon"}
          />
          <span className="cdn-item__option-name">{`${item.storage} ${
            productKey["new"].units[item.storage_unit]
          }`}</span>
          {productKey[lang].disk_space}
        </li>
        {item.unlimited_domains && (
          <li className="cdn-item__option">
            <Icon
              width={18}
              height={18}
              iconId={`infinity_${body.style}`}
              classes={"cdn-item__option-icon"}
            />

            <span className="cdn-item__option-name domain_count">
              {productKey[lang].amount}
            </span>
            {productKey[lang].domains}
          </li>
        )}
      </ul>
      <div className="cdn-item__price">
        {item.price_old === 0 && (
          <>
            <span className="cdn-item__old-price">{getPriceStringify(item.price)}</span>
            <span className="cdn-item__price-free">{productKey[lang].free}</span>
          </>
        )}

        {item.price_old > 0 && (
          <span className="cdn-item__old-price">{getPriceStringify(item.price_old)}</span>
        )}

        {item.price_old > 0 &&
          getPriceStringify(item.price)
        }
      </div>

      {item.traffic_amount > 0 && item.link_as_amount && (
        <a
          className="cdn-item__button"
          href={
            item.link_as_amount.indexOf("?") < 0
              ? item.link_as_amount + utm
              : item.link_as_amount + utm.replace("?", "&")
          }
          target="_blank"
          rel="noreferrer noopener"
        > {productKey[lang].order}
        {/*  {`${productKey[lang].order}${*/}
        {/*  item.link_as_speed*/}
        {/*    ? " " + productKey[lang].units[item.traffic_amount_unit]*/}
        {/*    : ""*/}
        {/*}`}*/}
        </a>
      )}

      {/*{item.traffic_speed > 0 && item.link_as_speed && (*/}
      {/*  <a*/}
      {/*    className="cdn-item__button cdn-item__button--invert"*/}
      {/*    href={*/}
      {/*      item.link_as_speed.indexOf("?") < 0*/}
      {/*        ? item.link_as_speed + utm*/}
      {/*        : item.link_as_speed + utm.replace("?", "&")*/}
      {/*    }*/}
      {/*    target="_blank"*/}
      {/*    rel="noreferrer noopener"*/}
      {/*  >{`${productKey[lang].order} ${*/}
      {/*    productKey[lang].units[item.traffic_speed_unit]*/}
      {/*  }`}</a>*/}
      {/*)}*/}
    </li>
  );
};

export default CDNItemNew;
