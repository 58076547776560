import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import "./DForm.css"
import CalcResult from "../DCalc/CalcResult/CalcResult"
import { graphql, Link, useStaticQuery } from "gatsby"
import DFormBody from "./DFormBody/DFormBody"
import formKey from "../../../../language/formKey"

const DForm = ({ lang, data }) => {
  const { strapiProductDedicatedServer } = useStaticQuery(graphql`
    query {
      strapiProductDedicatedServer {
        form {
          after_text_en
          after_text_ru
          before_promo_text_en
          before_promo_text_ru
          before_technical_text_en
          before_technical_text_ru
          before_text_en
          before_text_ru
          button_text_en
          button_text_ru
          error_check_en
          error_check_ru
          error_email_en
          error_email_ru
          error_name_en
          error_name_ru
          label_check_agree_en
          label_check_agree_ru
          label_check_privat_en
          label_check_privat_ru
          label_email_en
          label_email_ru
          label_name_en
          label_name_ru
          label_promo_en
          label_promo_ru
          label_technical_en
          label_technical_ru
          re_captcha
          re_captcha_sitekey
          title_en
          title_ru
          success_title_ru
          success_title_en
          success_text_ru
          success_text_en
          success_postscript_ru
          success_postscript_en
        }
      }
    }
  `)
  let [success, setSuccess] = useState(false)
  let successBlock = useRef()

  const getURL = () => {
    if (window) {
      let url = window.location.pathname
      url = url.split("/")
      url = url.slice(0, 3)
      url = url.join("/")
      return url
    }
    return null
  }

  return (
    <div className="d-form__body container" ref={successBlock}>
      {success ? (
        <div className="d-form__content">
          <h2 className="d-form__success-title">
            {strapiProductDedicatedServer.form[`success_title_${lang}`]}
          </h2>
          <p className="d-form__success-text">
            {strapiProductDedicatedServer.form[`success_text_${lang}`]}
          </p>
          <p className="d-form__success-postscript">
            {strapiProductDedicatedServer.form[`success_postscript_${lang}`]}
          </p>
          <div className="d-form__success-button-row">
            <Link
              className="d-form__success-button d-form__success-button--transparent"
              to={`/${lang}/`}
            >
              {formKey[lang].home}
            </Link>
            <Link to={getURL()} className="d-form__success-button">
              {formKey[lang].order_more}
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="d-form__content">
            <DFormBody
              lang={lang}
              body={strapiProductDedicatedServer.form}
              setSuccess={value => {
                setSuccess(value)
                successBlock.current.scrollIntoView()
              }}
              data={data}
            />
          </div>

          <div className="d-form__result">
            <CalcResult lang={lang} notAnimation={true} />
          </div>
        </>
      )}
    </div>
  )
}

let mapStateToProps = state => ({
  dedicated: state.dedicated,
})

export default connect(mapStateToProps)(DForm)
