import React from "react"
import "./WikiList.css"
import { Link } from "gatsby"

const WikiList = ({ lang, data }) => {
  const { allStrapiWiki } = data
  const getWikiSlug = (item, newSlug) => {
    newSlug = `/${item.slug}${newSlug}`
    return item.head
      ? getWikiSlug(
          allStrapiWiki.edges.filter(i => i.node.slug === item.head.slug)[0]
            .node,
          newSlug
        )
      : newSlug
  }
  return (
    <div className="container">
      <ul className="wiki-list">
        {allStrapiWiki.edges
          .filter(i => !i.node.head)
          .map((wiki, n) => (
            <li key={n} className="wiki-list__item">
              <h3 className="wiki-list__head">
                <Link
                  className="wiki-list__link"
                  to={`/${lang}/wiki${getWikiSlug(wiki.node, "")}`}
                >
                  {wiki.node[`title_${lang}`]}
                </Link>
              </h3>
              {wiki.node.childs.length > 0 && (
                <ul className="wiki-list__sub-list">
                  {allStrapiWiki.edges
                    .filter(
                      i => i.node.head && i.node.head.slug === wiki.node.slug
                    )
                    .map((item, n) => (
                      <li key={n} className="wiki-list__sub-item">
                        <h4 className="wiki-list__sub-head">
                          <Link
                            className="wiki-list__link"
                            to={`/${lang}/wiki${getWikiSlug(item.node, "")}`}
                          >
                            {item.node[`title_${lang}`]}
                          </Link>
                        </h4>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
      </ul>
    </div>
  )
}

export default WikiList
