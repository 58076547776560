import React from "react"
import animations from "../../animations/animations"
import Illustrations from "../Illustrations/Illustrations"

class Animation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { animation: null, stop: true }
  }
  componentDidMount() {
    this.setState({
      animation:
        animations[this.props.illustration_id] &&
        animations[this.props.illustration_id](
          function () {
            this.setState({ stop: true })
          }.bind(this)
        ),
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isAnim !== prevProps.isAnim &&
      this.props.isAnim &&
      this.state.stop
    ) {
      this.setState({ stop: false })
      this.state.animation && this.state.animation.restart()
    }
  }

  render() {
    return <Illustrations illustration_id={this.props.illustration_id} />
  }
}

export default Animation
