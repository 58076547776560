import React from "react"

const HeaderTop = ({
  lang,
  slug,
  head,
  strapiHeader,
  context,
  utm = "",
  data,
}) => {
  return (
    <div className="header-top">
      <div className="header-top__content container">
        <p className="header-top__overhead">
          {strapiHeader[`overhead_${lang}`]}
        </p>
        {lang === "en" ?
          <p className="header-top__overhead">
            Updated&nbsp;
            <a href={"/" + lang + "/documents/legal-documents/"}
              target="_blank"
              rel="noopener noreferrer">
              Acceptable Use Policy.
            </a>
          </p> 
          : 
          <p className="header-top__overhead">
          Обновленная &nbsp;
          <a href={"/" + lang + "/documents/legal-documents/"}
            target="_blank"
            rel="noopener noreferrer">
            Политика допустимого использования.
          </a>
        </p>}
      </div>
    </div>
  )
}

export default HeaderTop
