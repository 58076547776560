import React from "react"
import "./Logo.css"
import Icon from "../../Sprite/Icon"
import { Link } from "gatsby"

const Logo = ({ lang, className, isHome, utm = "" }) => {
  return (
    <>
      {isHome ? (
        <div className={`header-logo header-logo--fix ${className} `}>
          <Icon
            classes="header-logo__icon"
            iconId="site_logo"
            width={131}
            height={45}
          />
        </div>
      ) : (
        <Link to={`/${lang}/${utm}`} className={`header-logo ${className}`}>
          <Icon
            classes="header-logo__icon"
            iconId="site_logo"
            width={131}
            height={45}
          />
        </Link>
      )}
    </>
  )
}

export default Logo
