export async function orderRequests(token, order, recaptcha) {
  let headers = new Headers()
  headers.append("Content-Type", "application/json")
  headers.append("X-Websa-Application", token)

  let request = {
    order_request: order,
  }
  if (recaptcha) request["g-recaptcha-response"] = recaptcha
  let body = JSON.stringify(request)

  let requestOptions = {
    method: "POST",
    headers: headers,
    body: body,
  }

  let res = await fetch(
    "https://api.websa.com/api/v1/order_requests",
    requestOptions
  )
  let data = await res.json()
  return { success: data.id !== "" }
}
