import React from 'react';
import './ImageFeatures.css';
import ImageFeaturesItem from "./ImageFeaturesItem";

const ImageFeatures = ({body, lang, ...props}) => {


  return (
   <div className="image-features">
     <div className="container">
       <h2 className="section-title image-features__title text--center">Что вы получите?</h2>
       <div className="image-features__body">
         {body.faq_item.map((item, n) =>
           <ImageFeaturesItem key={n}  description={item[`answer_${lang}`]} image={item.image.childImageSharp.fluid}/>
         )}
       </div>
     </div>
   </div>
  )
};

export default ImageFeatures;