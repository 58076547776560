import React from 'react';
import './ImageFeatures.css';
import Img from "gatsby-image";

const ImageFeaturesItem = ({description, image, ...props}) => {
  return (
   <div className="image-features-item">

     <div className="image-features-item__image-block">
       <Img className="image-features-item__image" fluid={image} />
     </div>
    <p className="text--center image-features-item__description">{description}</p>
   </div>
  )
};

export default ImageFeaturesItem;