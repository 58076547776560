import React, { useState } from "react"
import Icon from "../../Sprite/Icon"
import Animation from "../../Animation/Animation"
import Responsive from "../../Responsive/Responsive"

const AdvantageItem = ({
  name,
  description,
  illustration,
  grid_position,
  active,
  toggle,
}) => {
  let [isPlay, setPlay] = useState(false)
  let toggleAnim = () => {
    setPlay(!isPlay)
  }
  return (
    <div
      role="button"
      className={`advantages__item advantages__grid--${grid_position} 
        ${!illustration ? "advantages__item--text-fix" : ""} 
        ${active ? "advantages__item--active" : ""}`}
      onClick={() => toggle()}
      onMouseEnter={toggleAnim}
      onMouseLeave={toggleAnim}
    >
      <div className="advantages__item-illustration">
        <Animation illustration_id={illustration} isAnim={isPlay} />
      </div>

      {illustration ? (
        <h3 className="advantages__item-name">
          {name}
          {!active ? (
            <Icon iconId="more" classes="advantages__item-name-icon" />
          ) : (
            <Responsive options={["tab", "mobile"]}>
              <Icon iconId="close_small" classes="advantages__close-btn" />
            </Responsive>
          )}
        </h3>
      ) : (
        <Responsive options={["desktop"]}>
          <h3 className="advantages__item-name">{name}</h3>
        </Responsive>
      )}

      <p className="advantages__item-description">
        {description.split(/\n/).map((i, n) => (
          <span key={n}>{i}</span>
        ))}
      </p>
    </div>
  )
}

export default AdvantageItem
