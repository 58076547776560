import React from "react"
import "./Checkbox.css"
import ReactMarkdown from "react-markdown"
import Icon from "../../Sprite/Icon"

const Checkbox = ({ check, changeCheck, label, classes, utm = "" }) => {
  return (
    <label className={`ui-checkbox ${classes}`}>
      <input
        type="checkbox"
        className="ui-checkbox__input"
        onChange={() => changeCheck(!check)}
        checked={check}
      />
      <span className="ui-checkbox__icon">
        <Icon
          height="18"
          width="18"
          classes="ui-checkbox__icon-check"
          iconId="check"
        />
      </span>
      <span className="ui-checkbox__text">
        <ReactMarkdown
          source={label}
          transformLinkUri={url =>
            url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&")
          }
        />
      </span>
    </label>
  )
}

export default Checkbox
