import React from "react"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import Sprite from "../Sprite/Sprite"
import "./LayoutPage.css"
import Responsive from "../Responsive/Responsive"
import CookieNotice from "../CookieNotice/CookieNotice";

const LightLayoutPage = ({
  lang,
  slug,
  utm = "",
  children,
  breadcrumbs,
  context,
  data,
}) => {
  return (
    <div className="layout-page">
      <Header lang={lang} slug={slug} context={context} utm={utm} data={data} />
      <Responsive options={["desktop", "tab"]}>{breadcrumbs}</Responsive>
      <div className="layout-page__body">{children}</div>
      <Footer lang={lang} utm={utm} data={data} />
      <CookieNotice lang={lang} data={data}/>
      <Sprite />
    </div>
  )
}

export default LightLayoutPage
