import React from "react"
import "./DItem.css"
import { connect } from "react-redux"
import { Link } from "gatsby"
import DOption from "./DOption/DOption"
import productKey from "../../../../../language/productKey"

const DItem = ({ item, priceCode, lang, slug, group }) => {
  const options = [
    "cpu",
    "ram",
    "motherboard",
    "colocations",
    "disk",
    "disk_additional",
    "raid",
    "management",
    "network",
    "bandwidth",
  ]
  const getCurrent = item => {
    let current = {}
    options.forEach(i => {
      current[i] = item[i].filter(i => i.current)[0]
    })
    return current
  }
  const disks = item.disk.filter(i => i.current)
  const getPrice = (current, priceCode) => {
    let price = 0
    options.forEach(i => {
      if (i === "disk") {
        disks &&
          disks.forEach(i => {
            price += i[`price_${priceCode}`]
          })
      } else {
        price += current[i] ? current[i][`price_${priceCode}`] : 0
      }
    })
    return price
  }
  const getPriceStringify = value =>
    productKey[lang].price[priceCode].replace("$VALUE$", value)

  let current = getCurrent(item)
  let price = getPrice(current, priceCode)
  let discount = item.discount ? (100 - item.discount) / 100 : 0
  return (
    <li className="d-group__item d-item">
      <h3 className="d-item__title">
        {item.new && <span className="d-item__title-new">NEW</span>}
        <span className="d-item__title-category">{item.category}</span>
        {item.name}
      </h3>
      <h4 className="d-item__subtitle">{item.supmic}</h4>
      <ul className="d-item__feature-list">
        <DOption
          icon="cpu"
          name={current.cpu.name}
          subname={current.cpu.model}
        />
        <DOption
          icon="memory"
          name={`${current.ram.amount} ${
            productKey[lang].units[current.ram.unit]
          }`}
          subname={productKey[lang][current.ram.ddr]}
        />
        {disks.map((i, n) => (
          <DOption
            key={n}
            icon="ssd"
            name={`${i.amount} ${productKey[lang].units[i.unit]}`}
            subname={productKey[lang][i.type]}
          />
        ))}
        {current.disk_additional && (
          <DOption
            icon="ssd"
            name={`${current.disk_additional.amount} ${
              productKey[lang].units[current.disk_additional.unit]
            }`}
            subname={productKey[lang][current.disk_additional.type]}
          />
        )}
      </ul>

      <div className="d-item__price">
        {productKey[lang].from}
        {discount ? <span className="d-item__price--old">{price}</span> : null}
        {getPriceStringify(discount ? price * discount : price)}
        {productKey[lang].per_month}
      </div>
      <Link
        to={`/${lang}/${slug}/step-2`}
        state={{ ...item, group }}
        className="d-item__button"
      >
        {productKey[lang].order}
      </Link>
    </li>
  )
}

let mapStateToProps = state => ({ priceCode: state.dedicated.priceCode })

export default connect(mapStateToProps)(DItem)
