import React from "react"
import DedicatedServer from "../../Products/DedicatedServer/DedicatedServer"
import CloudServer from "../../Products/CloudServer/CloudServer"
import Cdn from "../../Products/CDN/CDN"
import "./Product.css"

const Product = ({ body, lang, slug, utm = "", data }) => {
  const products = type => {
    switch (type) {
      case "dedicated": {
        return <DedicatedServer lang={lang} slug={slug} data={data} />
      }
      case "cloud": {
        return <CloudServer lang={lang} utm={utm} data={data} />
      }
      case "video_cdn": {
        return <Cdn lang={lang} body={body} cdn="video" utm={utm} data={data} />
      }
      case "static_cdn": {
        return <Cdn lang={lang} body={body} cdn="static" utm={utm} data={data} />
      }
      default: {
        return <></>
      }
    }
  }

  return (
    <>
      {body[`title_${lang}`] && (
        <div className="container">
          <h2 className={`section-title product__title ${body.style ? "product-title--" + body.style : ""}`}>
            {body[`title_${lang}`]}
          </h2>
        </div>
      )}
      {products(body.product)}
    </>
  )
}

export default Product
