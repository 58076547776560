import React from "react"
import "./Faq.css"
import ReactMarkdown from "react-markdown"
import Img from "gatsby-image";

const Faq = ({ body, lang, utm = "" }) => {
  return (
    <div className={`faq container ${body.style ? "faq--" + body.style : ""}`}>
      <h2 className="section-title faq__title">{body[`title_${lang}`]}</h2>
      <div className="faq__list">
        {body.faq_item.map((item, n) => (
          <div key={n} className="faq__item">
            {/*{JSON.stringify(item.image)}*/}
            {body.style && item.image &&
             <div className="faq__item-image-box">
              <Img className="faq__item-image" fluid={item.image.childImageSharp.fluid} />
            </div>}
            <div className="faq__item-body">
              <h4 className="faq__question">{item[`question_${lang}`]}</h4>
              <div className="faq__answer">
                <ReactMarkdown
                  source={item[`answer_${lang}`]}
                  transformLinkUri={url =>
                    url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&")
                  }
                />
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq
