import React from "react";
import Home from "../components/PageTemplates/Home";
import { graphql } from "gatsby";

const IndexPage = ({ pageContext, data, ...props }) => {
  return (
    <Home
      lang={pageContext.lang}
      data={{ ...data, ...pageContext.metaData }}
      pageData={pageContext.pageData}
    />
  );
};

export default IndexPage;
// todo need clean page query
export const pageQuery = graphql`
  query {
    strapiHomePage {
      seo {
        head_title_en
        head_title_ru
        link_canonical_en
        link_canonical_ru
        meta_descripiton_ru
        meta_descripiton_en
        meta_robots_en
        meta_robots_ru
      }
      services {
        title_ru
        title_en
        service_item {
          icon {
            icon_id
          }
          description_ru
          description_en
          name_ru
          name_en
          to_page {
            slug
            published
          }
        }
      }
      advantages {
        title_ru
        title_en
        advantage_item {
          name_ru
          name_en
          description_ru
          description_en
          illustration {
            illustration_id
          }
          grid_position
        }
      }
      partners_section {
        title_ru
        title_en
        partner_item {
          icon_id
          link_url
        }
      }
      Slider {
        button_text_en
        button_text_ru
        description_en
        description_ru
        illustration {
          illustration_id
          full_size
        }
        name_ru
        name_en
        title_en
        title_ru
        to_page {
          slug
          published
        }
        button_url_en
        button_url_ru
      }
    }
  }
`;
