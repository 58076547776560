import React from "react"
import "./Breadcrumbs.css"
import { Link } from "gatsby"

const BreadcrumbsNesting = ({ lang, nestingList, utm = "" }) => {
  const getCurrentIndex = list => {
    let index = -1
    list.forEach((i, n) => {
      if (i.current) index = n
    })
    return index
  }
  let currentIndex = getCurrentIndex(nestingList)

  return (
    <div className="breadcrumbs">
      <div className="container breadcrumbs-container">
        <ul className="breadcrumbs__list">
          {nestingList.map((i, n) => {
            if (n < currentIndex) {
              return (
                <li
                  key={n}
                  className="breadcrumbs__item breadcrumbs__item--parent"
                >
                  <Link
                    className="breadcrumbs__parent"
                    to={`/${lang}/${i.slug}/${utm}`}
                    state={i.state}
                  >
                    {i[`name_${lang}`]}
                  </Link>
                </li>
              )
            } else if (n === currentIndex) {
              return (
                <li
                  key={n}
                  className="breadcrumbs__item breadcrumbs__item--current"
                >
                  <span className="breadcrumbs__current">
                    {i[`name_${lang}`]}
                  </span>
                </li>
              )
            } else {
              return (
                <li
                  key={n}
                  className="breadcrumbs__item breadcrumbs__item--child"
                >
                  <span className="breadcrumbs__child">
                    {i[`name_${lang}`]}
                  </span>
                </li>
              )
            }
          })}
        </ul>
      </div>
    </div>
  )
}

export default BreadcrumbsNesting
