import React from "react";
import "./BlogRow.css";
import BlogRowSlider from "./BlogRowSlider/BlogRowSlider";
import BlogRowGrid from "./BlogRowGrid/BlogRowGrid";
import Link from "gatsby-link";
import blogKey from "../../../language/blogKey";

const BlogRow = ({ body, lang, utm, data }) => {
  const currentBlogs = body.blogs
    .filter((i) => i.category === body.category.id)
    .map((i) => ({ ...i, category: body.category }));

  return (
    <div className={`blog-row  blog-row--${body.style} container`}>
      <h2 className="blog-row__headline">{body.category[`title_${lang}`]}</h2>
      {body.style === "one_post_in_slide" && (
        <BlogRowSlider blogs={currentBlogs} lang={lang} />
      )}
      {body.style === "post_grid" && (
        <>
          <BlogRowGrid blogs={currentBlogs} lang={lang} />
          <Link
            className="blog-row__button"
            to={`/${lang}/blog-list?category=${body.category.slug}`}
          >
            {blogKey[lang].show_all}
          </Link>
        </>
      )}
    </div>
  );
};

export default BlogRow;
