import React from 'react';
import Icon from "../../Sprite/Icon";

const AboutUsStatisticsFeatureItem = ({data, lang}) => {
  return (
    <div className={`about-us-statistics__feature 
    ${data[`description_${lang}`] ? "about-us-statistics__feature--large" : "no"}`}
    >
      <div className="about-us-statistics__feature-top">
        <Icon iconId={data.icon} width={44} height={44} classes="about-us-statistics__feature-icon"/>
        <span className="about-us-statistics__feature-name">{data[`name_${lang}`]}</span>
      </div>
      <div className="about-us-statistics__feature-bottom">
        {data[`description_${lang}`]}
      </div>
    </div>
  )
};

export default AboutUsStatisticsFeatureItem;