import React, { useEffect, useState } from "react";

const Responsive = ({ options, children }) => {
  let [media, setMedia] = useState("init");

  useEffect(() => {
    const resize = () => {
      if (window) {
        if (window.innerWidth >= 1024) {
          return setMedia("desktop");
        } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
          return setMedia("tab");
        } else if (window.innerWidth < 768) {
          return setMedia("mobile");
        }
      }
    };
    resize();

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <>
      {options &&
        options.map((option, n) => {
          if (option === media) return children;
          return null;
        })}
    </>
  );
};

export default Responsive;
