import React from "react"
import "./Footer.css"
import Icon from "../Sprite/Icon"
import MenuList from "../MenuList/MenuList"
import ReactMarkdown from "react-markdown"
import Responsive from "../Responsive/Responsive"

const Footer = ({ lang, head, slug, utm = "", data }) => {
  const { strapiFooter, mainMenu } = data
  let btnName = strapiFooter[`title_${lang}`].split('[').pop().split(']')[0]
   return (
    <footer className="footer">
      <div className="container footer-top">
        <div className="section-title-md">
          <ReactMarkdown
            source={strapiFooter[`title_${lang}`]}
            transformLinkUri={url =>
              url.indexOf("?") < 0 ? url + utm : url + utm.replace("?", "&")
            }
          />
          <a href={"/" + lang + "/contact-us"}
             className={`btn btn--green btn--clear `}
             target="_blank"
             rel="noopener noreferrer">
            <Icon classes="btn-icon" iconId="paper_plane"/>
            <div className="section-btn">{btnName}</div>
          </a>
        </div>

        <Responsive options={["desktop"]}>
          <div className="footer-menu">
            {mainMenu.map((item, index) => (
              <MenuList
                classes="footer-menu"
                key={index}
                lang={lang}
                head={head}
                slug={slug}
                utm={utm}
                name={item[`name_${lang}`]}
                pages={item.pages}
                subcategories={item.subcategories}
              />
            ))}
          </div>
        </Responsive>
      </div>

      <div className="footer-bottom container">
        <div className="footer-bottom__left">
          <div className="footer-copyright">
            <Responsive options={["desktop"]}>
              <Icon
                iconId={"ah"}
                width={32}
                height={32}
                classes="footer-copyright__icon"
              />
            </Responsive>
            <span className="footer-copyright__text">
              {strapiFooter.copyright}
            </span>
          </div>
        </div>
        <Responsive options={["desktop"]}>
          <div className="footer-bottom__center">
            <a
              className="footer-support"
              href={`mailto:${strapiFooter.support_email}`}
            >
              {strapiFooter.support_email}
            </a>
          </div>
        </Responsive>
        <div className="footer-bottom__right">
          <div className="footer-social">
            <a
              className={"footer-social__link"}
              href={strapiFooter.facebook_link}
              target="_blank"
              rel="noreferrer"
              title="facebook"
            >
              <Icon
                iconId={"facebook"}
                classes={"footer-social__icon"}
                width={30}
                height={30}
              />
            </a>
            <a
              className={"footer-social__link"}
              href={strapiFooter.linkedin_link}
              target="_blank"
              rel="noreferrer"
              title="linkedin"
            >
              <Icon
                iconId={"linkedin"}
                classes={"footer-social__icon"}
                width={30}
                height={30}
              />
            </a>
            <a
              className={"footer-social__link"}
              href={strapiFooter.twitter_link}
              target="_blank"
              rel="noreferrer"
              title="twitter"
            >
              <Icon
                iconId={"twitter"}
                classes={"footer-social__icon"}
                width={30}
                height={30}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
