import React from "react";
import "./Breadcrumbs.css";
import { Link } from "gatsby";

const BreadcrumbsBlog = ({ lang, slug, utm = "", data }) => {
  const { allStrapiBlog, allStrapiPage } = data;

  let blogRoot = allStrapiPage.edges.filter(
    ({ node }) => node.slug === "blog"
  )[0].node;

  let currentPost = allStrapiBlog.edges.filter(
    ({ node }) => node.slug === slug.split("/")[2]
  )[0].node;

  return (
    <div className="breadcrumbs">
      <div className="container breadcrumbs-container">
        <ul className="breadcrumbs__list">
          <li className="breadcrumbs__item breadcrumbs__item--parent">
            <Link
              className="breadcrumbs__parent"
              to={`/${lang}/${blogRoot.slug}`}
            >
              {blogRoot[`title_${lang}`]}
            </Link>
          </li>
          <li className="breadcrumbs__item">
            <Link
              className="breadcrumbs__title"
              to={`/${lang}/blog?category=${data.strapiBlog.category.slug}`}
            >
              {data.strapiBlog.category[`title_${lang}`]}
            </Link>
          </li>
          <li className="breadcrumbs__item">
            <span className="breadcrumbs__current">
              {currentPost[`title_${lang}`]}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default BreadcrumbsBlog;
