import React from "react"
import { graphql } from "gatsby"
import SubPagesTemplate from "../components/PageTemplates/SubPagesTemplate"

const Page = props => {
  return (
    <SubPagesTemplate
      slug={props.pageContext.slug}
      lang={props.pageContext.lang}
      head={props.pageContext.head}
      title={props.data.strapiPage.title_en}
      seo={props.data.strapiPage.seo}
      hero={props.data.strapiPage.hero}
      content={props.data.strapiPage.content}
      isContactLine={props.data.strapiPage.is_contact_line}
      isSubscribe={props.data.strapiPage.is_subscribe}
      data={{ ...props.data, ...props.pageContext.metaData }}
    />
  )
}

export default Page
export const pageQuery = graphql`
  query($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      title_en
      template
      is_contact_line
      is_subscribe
      seo {
        head_title_en
        link_canonical_en
        meta_descripiton_en
        meta_robots_en
      }
      hero {
        button_text_en
        button_url_en
        second_button_text_en
        second_button_url_en
        description_en
        title_en
        to_page {
          slug
        }
        to_second_page {
          slug
        }
        illustration {
          illustration_id
        }
      }
      content {
        type
        title_en
        content_en
        description_en
        filter_point
        theme
        icon
        faq_item {
          answer_en
          question_en
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        feature_item {
          name_en
          description_en
          icon
        }
        nav_list {
          nav_content_en
          nav_title_en
          nested
        }
        left_column {
          description_en
          icon
          id
          name_en
        }
        right_column {
          description_en
          icon
          id
          name_en
        }
        about_item {
          name_en
          description_en
          icon
        }
        column
        contact_list_item {
          info_en
          name_en
        }
        to_page {
          slug
        }
        reviews_item {
          description_en
          name_en
          text_en
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        before_text_en
        after_text_en
        label_check_agree_en
        label_check_privat_en
        label_email_en
        label_message_en
        label_name_en
        error_name_en
        error_message_en
        error_email_en
        error_check_en
        button_text_en
        re_captcha
        re_captcha_sitekey
        product
      }
    }
    allFile {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
