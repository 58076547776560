import React, {useEffect} from 'react';
import './CoverageMap.css';
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";

const CoverageMap = ({body, lang, slug}) => {

  const checkItemsCount = (count) => {
    if(count <= 10) {
      return 5
    } else {
      return Math.ceil(count / 2)
    }
  }

  useEffect(() => {
    let list = document.querySelectorAll(".coverage-map__city-list ul")
    let arrCountItems = []
    list.forEach(item => arrCountItems.push(item.childElementCount))
    let countItems = Math.max.apply(null, arrCountItems)
    list.forEach(item => item.style.cssText = (`grid-template-rows: repeat(${checkItemsCount(countItems)}, auto);`));
  }, [])

  return (
    <div className="coverage-map">
      <div className="container">
        <h2 className="title coverage-map__title">{body[`title_${lang}`]}</h2>
        <p className="coverage-map__description">{body[`description_${lang}`]}</p>
        <div className="coverage-map__body">
          {body.coverage_map_item.map((item, n) =>
          <div key={n} className="coverage-map__item">
            <h3 className="coverage-map__country">{item[`title_${lang}`]}</h3>
            <div className="coverage-map__city-list">
              <ReactMarkdown
                source={item[`content_${lang}`]}
              />
            </div>
          </div>
          )}
        </div>
        <Img className="coverage-map__image" fluid={body.image.childImageSharp.fluid}/>
      </div>
    </div>
  )
};

export default CoverageMap;