import React from "react"
import "./DedicatedServer.css"
import DGroup from "./DGroup/DGroup"
import DSwitcher from "./DSwitcher/DSwitcher"
import { graphql, useStaticQuery } from "gatsby"

const DedicatedServer = ({ lang, slug, body }) => {
  const { strapiProductDedicatedServer } = useStaticQuery(graphql`
    query {
      strapiProductDedicatedServer {
        title_en
        title_ru
        group_1_title_en
        group_1_title_ru
        group_1_show
        group_1_color
        group_1_list {
          name
          category
          supmic
          new
          discount
          cpu {
            price_us
            price_eu
            current
            name
            model
          }
          ram {
            price_us
            price_eu
            current
            amount
            unit
            ddr
            mhz
          }
          motherboard {
            price_us
            price_eu
            current
            name
          }
          colocations {
            price_us
            price_eu
            current
          }
          disk_one_of_list
          disk_limit
          disk {
            price_us
            price_eu
            current
            amount
            unit
            type
            model
          }
          disk_additional {
            price_us
            price_eu
            current
            amount
            unit
            type
            model
          }
          raid {
            price_us
            price_eu
            current
            type
            model
            network_limit
            network_limit_unit
          }
          management {
            price_us
            price_eu
            current
            name_ru
            name_en
            day
          }
          network {
            price_us
            price_eu
            current
            amount
            unit
            name
          }
          bandwidth {
            price_us
            price_eu
            current
            speed
            unit
            upto_us
            upto_eu
            upto_unit
          }
        }
        group_2_title_en
        group_2_title_ru
        group_2_show
        group_2_color
        group_2_list {
          name
          category
          supmic
          new
          discount
          cpu {
            price_us
            price_eu
            current
            name
            model
          }
          ram {
            price_us
            price_eu
            current
            amount
            unit
            ddr
            mhz
          }
          motherboard {
            price_us
            price_eu
            current
            name
          }
          colocations {
            price_us
            price_eu
            current
          }
          disk_one_of_list
          disk_limit
          disk {
            price_us
            price_eu
            current
            amount
            unit
            type
            model
          }
          disk_additional {
            price_us
            price_eu
            current
            amount
            unit
            type
            model
          }
          raid {
            price_us
            price_eu
            current
            type
            model
            network_limit
            network_limit_unit
          }
          management {
            price_us
            price_eu
            current
            name_ru
            name_en
            day
          }
          network {
            price_us
            price_eu
            current
            amount
            unit
            name
          }
          bandwidth {
            price_us
            price_eu
            current
            speed
            unit
            upto_us
            upto_eu
            upto_unit
          }
        }
        group_3_title_en
        group_3_title_ru
        group_3_show
        group_3_color
        group_3_list {
          name
          category
          supmic
          new
          discount
          cpu {
            price_us
            price_eu
            current
            name
            model
          }
          ram {
            price_us
            price_eu
            current
            amount
            unit
            ddr
            mhz
          }
          motherboard {
            price_us
            price_eu
            current
            name
          }
          colocations {
            price_us
            price_eu
            current
          }
          disk_one_of_list
          disk_limit
          disk {
            price_us
            price_eu
            current
            amount
            unit
            type
            model
          }
          disk_additional {
            price_us
            price_eu
            current
            amount
            unit
            type
            model
          }
          raid {
            price_us
            price_eu
            current
            type
            model
            network_limit
            network_limit_unit
          }
          management {
            price_us
            price_eu
            current
            name_ru
            name_en
            day
          }
          network {
            price_us
            price_eu
            current
            amount
            unit
            name
          }
          bandwidth {
            price_us
            price_eu
            current
            speed
            unit
            upto_us
            upto_eu
            upto_unit
          }
        }
      }
    }
  `)
  return (
    <div className="dedicated">
      <div className="dedicated__title-row container">
        <h2 className="dedicated__title">
          {strapiProductDedicatedServer[`title_${lang}`]}
        </h2>
        <DSwitcher lang={lang} />
      </div>
      <DGroup
        title={strapiProductDedicatedServer[`group_1_title_${lang}`]}
        group={{
          title_ru: strapiProductDedicatedServer.group_1_title_ru,
          title_en: strapiProductDedicatedServer.group_1_title_en,
        }}
        list={strapiProductDedicatedServer.group_1_list}
        color={strapiProductDedicatedServer.group_1_color}
        show={strapiProductDedicatedServer.group_1_show}
        lang={lang}
        slug={slug}
      />
      <DGroup
        title={strapiProductDedicatedServer[`group_2_title_${lang}`]}
        group={{
          title_ru: strapiProductDedicatedServer.group_2_title_ru,
          title_en: strapiProductDedicatedServer.group_2_title_en,
        }}
        list={strapiProductDedicatedServer.group_2_list}
        color={strapiProductDedicatedServer.group_2_color}
        show={strapiProductDedicatedServer.group_2_show}
        lang={lang}
        slug={slug}
      />
      <DGroup
        title={strapiProductDedicatedServer[`group_3_title_${lang}`]}
        group={{
          title_ru: strapiProductDedicatedServer.group_2_title_ru,
          title_en: strapiProductDedicatedServer.group_2_title_en,
        }}
        list={strapiProductDedicatedServer.group_3_list}
        color={strapiProductDedicatedServer.group_3_color}
        show={strapiProductDedicatedServer.group_3_show}
        lang={lang}
        slug={slug}
      />
    </div>
  )
}

export default DedicatedServer
