import React, { useState } from "react"
import "./LargeTextWithNav.css"
import ReactMarkdown from "react-markdown"
import { Link, Element } from "react-scroll"
import Responsive from "../../Responsive/Responsive"
import ContentImage from "../../ContentImage/ContentImage"
import ContentNav from "../../ContentNav/ContentNav"

const LargeTextWithNav = ({ body, lang, utm = "", data }) => {
  let [key] = useState(Math.random().toString(36).substring(7))
  const Image = ({ alt, src }) => (
    <ContentImage alt={alt} src={src} allFile={data.allFile} />
  )
  const renderers = {
    image: Image,
  }
  let nav = []

  body.nav_list.forEach((elem, i) => {
    let id = `id-${key}-${i}`
    elem.id = id
    if (!elem.nested) {
      nav.push({ text: elem[`nav_title_${lang}`], to: elem.id, items: [] })
    } else {
      !nav.length && nav.push({ text: null, to: null, items: [] })
      nav[nav.length - 1].items.push({
        text: elem[`nav_title_${lang}`],
        to: elem.id,
      })
    }
  })

  return (
    <div className="large-text-with-nav container" id={`id_${key}`}>
      <Responsive options={["desktop"]}>
        <div className="large-text-with-nav__nav-side nav-side">
          <ul className="nav-side__list">
            {nav.map(nav1 => (
              <li key={nav1.to} className="nav-side__item">
                {nav1.text && (
                  <Link
                    className="nav-side__link"
                    activeClass="nav-side__link--active"
                    to={nav1.to}
                    smooth={true}
                    spy={true}
                    offset={-100}
                  >
                    {nav1.text}
                  </Link>
                )}
                {nav1.items.length > 0 && (
                  <ul className="nav-side__sub-list">
                    {nav1.items.map(nav2 => (
                      <li key={nav2.to} className="nav-side__item">
                        <Link
                          className="nav-side__link"
                          activeClass="nav-side__link--active"
                          to={nav2.to}
                          smooth={true}
                          spy={true}
                          offset={-100}
                        >
                          {nav2.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="large-text large-text-with-nav__text-side">
          {body.nav_list.map(item => (
            <Element name={item.id} key={item.id}>
              <ReactMarkdown
                source={item[`nav_content_${lang}`]}
                renderers={renderers}
                transformLinkUri={url =>
                  url &&
                  (url.indexOf("?") < 0
                    ? url + utm
                    : url + utm.replace("?", "&"))
                }
              />
            </Element>
          ))}
        </div>
      </Responsive>
      <Responsive options={["tab", "mobile"]}>
        <ContentNav
          navlist={
            <ul className="nav-side__list">
              {nav.map(nav1 => (
                <li key={nav1.to} className="nav-side__item">
                  {nav1.text && (
                    <Link
                      className="nav-side__link"
                      activeClass="nav-side__link--active"
                      to={nav1.to}
                      smooth={true}
                      spy={true}
                      offset={-100}
                    >
                      {nav1.text}
                    </Link>
                  )}
                  {nav1.items.length > 0 && (
                    <ul className="nav-side__sub-list">
                      {nav1.items.map(nav2 => (
                        <li key={nav2.to} className="nav-side__item">
                          <Link
                            className="nav-side__link"
                            activeClass="nav-side__link--active"
                            to={nav2.to}
                            smooth={true}
                            spy={true}
                            offset={-100}
                          >
                            {nav2.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          }
          content={
            <div className="large-text large-text-with-nav__text-side">
              {body.nav_list.map(item => (
                <Element name={item.id} key={item.id}>
                  <ReactMarkdown
                    source={item[`nav_content_${lang}`]}
                    renderers={renderers}
                    transformLinkUri={url =>
                      url &&
                      (url.indexOf("?") < 0
                        ? url + utm
                        : url + utm.replace("?", "&"))
                    }
                  />
                </Element>
              ))}
            </div>
          }
        />
      </Responsive>
    </div>
  )
}

export default LargeTextWithNav
