import React from "react"
import "./Button.css"

const Button = ({ classes, text, onPress, errorText = "" }) => {
  return (
    <button className={"btn " + classes} onClick={() => onPress && onPress()}>
      {text}
    </button>
  )
}

export default Button
