import React from "react"
import "./SubPage.css"
import PageContent from "../PageContent/PageContent"
import SubPagesNav from "./SubPagesNav/SubPagesNav"
import Responsive from "../Responsive/Responsive"
import ContentNav from "../ContentNav/ContentNav"

const SubPage = ({ lang, slug, head, content, utm = "", data }) => {
  return (
    <div className="subpage container">
      <Responsive options={["desktop"]}>
        <div className="subpage__nav">
          <SubPagesNav
            lang={lang}
            slug={slug}
            head={head}
            utm={utm}
            data={data}
          />
        </div>
        <div className="subpage__content">
          {content && (
            <PageContent
              content={content}
              lang={lang}
              slug={slug}
              utm={utm}
              data={data}
            />
          )}
        </div>
      </Responsive>

      <Responsive options={["tab", "mobile"]}>
        <ContentNav
          navlist={
            <SubPagesNav
              lang={lang}
              slug={slug}
              head={head}
              utm={utm}
              data={data}
            />
          }
          content={
            content && (
              <PageContent
                content={content}
                lang={lang}
                slug={slug}
                utm={utm}
                data={data}
              />
            )
          }
        />
      </Responsive>
    </div>
  )
}

export default SubPage
