import React from "react"
import "./CalcItem.css"
import Icon from "../../../../../Sprite/Icon"
import { CSSTransition } from "react-transition-group"
import { useEffect, useState } from "react"

const CalcItem = ({ icon, name, subname, error, notAnimation }) => {
  let [inAnim, setAnim] = useState(notAnimation)
  useEffect(() => {
    setAnim(notAnimation)
  }, [name])
  useEffect(() => {
    setAnim(true)
  }, [inAnim])
  return (
    <CSSTransition in={inAnim} timeout={400} classNames="calc-item__anim">
      <li className={`calc-item ${error ? "calc-item__error" : ""}`}>
        <Icon width={24} height={24} iconId={icon} classes="calc-item__icon" />
        <span className="calc-item__name">{name}</span>
        <span className="calc-item__subname">{subname}</span>
      </li>
    </CSSTransition>
  )
}

export default CalcItem
