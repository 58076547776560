import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import "swiper/swiper-bundle.min.css";
import "./BlogRowSlider.css";
import BlogRowSliderItem from "./BlogRowSliderItem";

SwiperCore.use([Navigation, Pagination, A11y]);
const BlogRowSlider = ({ blogs, lang }) => {
  return (
    <div className="blog-row-slider">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        {blogs.map((i) => (
          <SwiperSlide key={i.slug}>
            <BlogRowSliderItem data={i} lang={lang} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BlogRowSlider;
