import React from "react"
import "./DOption.css"
import Icon from "../../../../../Sprite/Icon"

const DOption = ({ icon, name, subname, error }) => {
  return (
    <li className={`d-option ${error ? "d-option__error" : ""}`}>
      <Icon width={24} height={24} iconId={icon} classes="d-option__icon" />
      <span className="d-option__name">{name}</span>
      <span className="d-option__subname">{subname}</span>
    </li>
  )
}

export default DOption
