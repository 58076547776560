import React from "react";
import GlobalStyle from "../GlobalStyle/GlobalStyle";
import Features from "../Features/Features";
import Sprite from "../Sprite/Sprite";
import Advantages from "../Advantages/Advantages";
import Footer from "../Footer/Footer";
import Partners from "../Partners/Partners";
import Subscribe from "../Subscribe/Subscribe";
import Slider from "../Slider/Slider";
import Header from "../Header/Header";
import Seo from "../Seo/Seo";
import Responsive from "../Responsive/Responsive";
import Utm from "../Utm/Utm";
import { connect } from "react-redux";
import ScrollTop from "../ScrollTop/ScrollTop";
import CookieNotice from "../CookieNotice/CookieNotice";
import Swiper from "react-id-swiper";
// import loadable from "@loadable/component"

// const Slider = loadable(() => import("../Slider/Slider"))
// const Advantages = loadable(() => import("../Advantages/Advantages"))
// const Features = loadable(() => import("../Features/Features"))
// const ScrollTop = loadable(() => import("../ScrollTop/ScrollTop"))
// const Footer = loadable(() => import("../Footer/Footer"))
// const Partners = loadable(() => import("../Partners/Partners"))
// const Subscribe = loadable(() => import("../Subscribe/Subscribe"))

const Home = ({ lang, utm = "", data, pageData }) => {
  return (
    <div className="home">
      <GlobalStyle />
      <Utm />
      <ScrollTop lang={lang} />
      <Seo
        data={data}
        lang={lang}
        seo={data.strapiHomePage.seo}
        slug=""
        defaultTitle="Advanced Hosting"
      />
      <Header lang={lang} slug="" isHome data={data} />
      <Slider items={pageData.allSliders} utm={utm} />
      <Features {...pageData.allServices} utm={utm} />
      <Advantages {...pageData.allAdvantages} />
      <Subscribe lang={lang} data={data} />
      <Responsive options={["desktop", "mobile"]}>
        <Partners {...pageData.allPartners} />
      </Responsive>
      <Footer lang={lang} data={data} />
      <CookieNotice lang={lang} data={data}/>
      <Sprite />
    </div>
  );
};

let mapStateToProps = (state) => ({
  utm: state.utm.utm,
});
export default connect(mapStateToProps)(Home);
