import React from "react";
import "./CDNItemSlider.css";
import Icon from "../../../../Sprite/Icon";
import productKey from "../../../../../language/productKey";

const CDNItemSlider = ({ item, lang, utm = "", body }) => {
  const getNumerator = (i) => {
    switch (i) {
      case 1:
        return 0;
      case 2:
      case 3:
      case 4:
        return 1;
      default:
        return 2;
    }
  };
  const getPriceStringify = (value) =>
    productKey[lang].price.us.replace("$VALUE$", value);

  return (
    <div className={`cdn-item-slider__body ${body.style ? "cdn-item-slider__body--" + body.style : ""}`}>
      <h5 className="cdn-item-slider__stoke">{productKey[lang][item.stoke]}</h5>
      <h3 className="cdn-item-slider__title">
        <span className="cdn-item-slider__title-name">{item.name}</span>
        <span className="cdn-item-slider__title-text"> {item.type}</span>
      </h3>
      <ul className="cdn-item-slider__feature-list">
        <li className="cdn-item-slider__option">
          <Icon
            width={24}
            height={24}
            iconId="traffic"
            classes={"cdn-item-slider__option-icon"}
          />
          {item.traffic_amount > 0 && (
            <span className="cdn-item-slider__option-name">{`${
              item.traffic_amount
            } ${productKey[lang].units[item.traffic_amount_unit]}`}</span>
          )}
          {item.traffic_speed > 0 &&
            item.traffic_amount > 0 &&
            productKey[lang].or}
          {item.traffic_speed > 0 && (
            <span className="cdn-item-slider__option-name">{`${
              item.traffic_speed
            } ${productKey[lang].units[item.traffic_speed_unit]}`}</span>
          )}
          {productKey[lang].of_traffic}
        </li>
        <li className="cdn-item-slider__option">
          <Icon
            width={24}
            height={24}
            iconId="ssd"
            classes={"cdn-item-slider__option-icon"}
          />
          <span className="cdn-item-slider__option-name">{`${item.storage} ${
            productKey[lang].units[item.storage_unit]
          }`}</span>
          {productKey[lang].disk_space}
        </li>
        {item.unlimited_domains && (
          <li className="cdn-item-slider__option">
            <Icon
              width={24}
              height={24}
              iconId="domains_small"
              classes={"cdn-item-slider__option-icon"}
            />

            <span className="cdn-item-slider__option-name">
              {productKey[lang].unlimited}
            </span>
            {productKey[lang].domains}
          </li>
        )}

        <li className="cdn-item-slider__option">
          <Icon
            width={24}
            height={24}
            iconId="free"
            classes={"cdn-item-slider__option-icon"}
          />
          <span className="cdn-item-slider__option-name">
            {productKey[lang].free}
          </span>
          {`${item.free_amount} ${
            productKey[lang][item.free_unit][getNumerator(item.free_amount)]
          }`}
        </li>
      </ul>
      <div className="cdn-item-slider__price">
        {item.price_old > 0 && (
          <span className="cdn-item-slider__old-price">{item.price_old}</span>
        )}

        {`${getPriceStringify(item.price)}${productKey[lang].per_month}`}
      </div>

      {item.traffic_amount > 0 && item.link_as_amount && (
        <a
          className="cdn-item-slider__button"
          href={
            item.link_as_amount.indexOf("?") < 0
              ? item.link_as_amount + utm
              : item.link_as_amount + utm.replace("?", "&")
          }
          target="_blank"
          rel="noreferrer noopener"
        >{`${productKey[lang].order} ${
          item.link_as_speed
            ? " " + productKey[lang].units[item.traffic_amount_unit]
            : ""
        }`}</a>
      )}

      {item.traffic_speed > 0 && item.link_as_speed && (
        <a
          className="cdn-item-slider__button cdn-item-slider__button--invert"
          href={
            item.link_as_speed.indexOf("?") < 0
              ? item.link_as_speed + utm
              : item.link_as_speed + utm.replace("?", "&")
          }
          target="_blank"
          rel="noreferrer noopener"
        >{`${productKey[lang].order} ${
          productKey[lang].units[item.traffic_speed_unit]
        }`}</a>
      )}
    </div>
  );
};

export default CDNItemSlider;
