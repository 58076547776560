const SET_PRICE_CODE = "SET-PRICE-CODE"
const SET_CURRENT_ITEM = "SET-CURRENT-ITEM"

const initialState = {
  priceCode: "eu",
  currentItem: {},
}

const dedicatedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRICE_CODE: {
      return {
        ...state,
        priceCode: action.code,
      }
    }
    case SET_CURRENT_ITEM: {
      return {
        ...state,
        currentItem: action.item,
      }
    }
    default:
      return state
  }
}

export const setPriceCode = code => ({ type: SET_PRICE_CODE, code })
export const setCurrentItem = item => ({ type: SET_CURRENT_ITEM, item })

export default dedicatedReducer
