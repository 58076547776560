import React from "react";
import LargeText from "./LargeText/LargeText";
import LargeTextWithNav from "./LargeTextWithNav/LargeTextWithNav";
import PagesFeatures from "../Features/PagesFeatures";
import Faq from "./Faq/Faq";
import LightMapBox from "./LightMapBox/LightMapBox";
import ContactList from "./ContactList/ContactList";
import ContactForm from "./ContactForm/ContactForm";
import Product from "./Product/Product";
import WikiList from "./WikiList/WikiList";
import BlogRow from "./BlogRow/BlogRow";
import ContactDepartment from "./ContactDepartment/ContactDepartment";
import ColumnFeatures from "./ColumnFeatures/ColumnFeatures";
import AboutUsStatistics from "./AboutUsStatistics/AboutUsStatistics";
import Reviews from "./Reviews/Reviews";
import CallbackForm from "./CallbackForm/CallbackForm";
import CoverageMap from "./CoverageMap/CoverageMap";
import InfoBlock from "./InfoBlock/InfoBlock";
import Stages from "./Stages/Stages";
import QuestionForm from "./QuestionForm/QuestionForm";
import ImageFeatures from "./ImageFeatures/ImageFeatures";

const PageContent = ({ content, lang, slug, utm = "", data }) => {
  return (
    <div className="page-content">
      {content.map((component, n) => {
        switch (component.type) {
          case "large_text": {
            return (
              <LargeText
                key={n}
                body={component}
                lang={lang}
                utm={utm}
                data={data}
              />
            );
          }
          case "large_text_with_nav": {
            return (
              <LargeTextWithNav
                key={n}
                body={component}
                lang={lang}
                utm={utm}
                data={data}
              />
            );
          }
          case "feature": {
            return (
              <PagesFeatures
                key={n}
                body={component}
                lang={lang}
                utm={utm}
                data={data}
              />
            );
          }
          case "info_block": {
            return (
              <InfoBlock
                key={n}
                body={component}
                lang={lang}
              />
            );
          }
          case "callback_form": {
            return (
              <CallbackForm
                key={n}
                body={component}
                lang={lang}
                data={data}
              />
            );
          }
          case "column_features": {
            return (
              <ColumnFeatures
                key={n}
                body={component}
                lang={lang}
              />
            );
          }
          case "image_features": {
            return (
              <ImageFeatures
                key={n}
                body={component}
                lang={lang}
              />
            );
          }
          case "faq": {
            return (
              <Faq key={n} body={component} lang={lang} utm={utm} data={data} />
            );
          }
          case "stages": {
            return (
              <Stages key={n} body={component} lang={lang}/>
            );
          }
          case "map": {
            return (
              <LightMapBox key={n} body={component} lang={lang} data={data} />
            );
          }
          case "contact_department": {
            return (
              <ContactDepartment key={n} body={component} lang={lang} data={data} utm={utm}/>
            );
          }
          case "contact_list": {
            return (
              <ContactList
                key={n}
                body={component}
                lang={lang}
                utm={utm}
                data={data}
              />
            );
          }
          case "contact_form": {
            return (
              <ContactForm
                key={n}
                body={component}
                lang={lang}
                utm={utm}
                data={data}
              />
            );
          }
          case "question_form": {
            return (
              <QuestionForm
                key={n}
                body={component}
                lang={lang}
                data={data}
                utm={utm}
              />
            );
          }
          case "coverage_map": {
            return (
              <CoverageMap
                key={n}
                body={component}
                lang={lang}
                utm={utm}
                data={data}
              />
            );
          }
          case "product": {
            return (
              <Product
                key={n}
                body={component}
                lang={lang}
                slug={slug}
                utm={utm}
                data={data}
              />
            );
          }
          case "reviews": {
            return (
              <Reviews  body={component} key={n}  lang={lang}/>
            );
          }
          case "about_us_statistics": {
            return (
              <AboutUsStatistics
                key={n}
                body={component}
                lang={lang}
              />
            );
          }
          case "wiki_list": {
            return (
              <WikiList
                key={n}
                body={component}
                lang={lang}
                utm={utm}
                data={data}
              />
            );
          }
          case "blog_row": {
            return (
              <BlogRow
                key={n}
                body={component}
                lang={lang}
                utm={utm}
                data={data}
              />
            );
          }
          default: {
            return null;
          }
        }
      })}
    </div>
  );
};

export default PageContent;
