import React from "react"
import "./MenuItem.css"
import SubMenu from "../SubMenu/SubMenu"
import { Link } from "gatsby"

const MenuItem = ({
  lang,
  slug,
  head,
  utm = "",
  pages,
  subcategories,
  isActiveSubMenu,
  onToggle,
  name,
  description,
}) => {
  return (
    <>
      {pages.length || subcategories.length ? (
        <li className="menu__list-item">
          {pages && pages.length === 1 ? (
            pages[0].template === "sub_pages" ? (
              head === pages[0].slug ? (
                <span className="menu__list-name menu__list-name--current">
                  {name}
                </span>
              ) : (
                <Link
                  to={`/${lang}/${pages[0].slug}/${pages[0].current_sub_page.slug}/${utm}`}
                  className="menu__list-name"
                >
                  {name}
                </Link>
              )
            ) : pages[0].slug === slug ? (
              <span className="menu__list-name menu__list-name--current">
                {name}
              </span>
            ) : (
              <Link
                className="menu__list-name"
                to={`/${lang}/${pages[0].slug}`}
              >
                {name}
              </Link>
            )
          ) : (
            <>
              <button
                className={`menu__list-name ${
                  isActiveSubMenu ? "menu__list-name--active" : ""
                }`}
                onClick={onToggle}
              >
                {name}
              </button>
              {isActiveSubMenu && (
                <SubMenu
                  slug={slug}
                  head={head}
                  utm={utm}
                  pages={pages}
                  subcategories={subcategories}
                  lang={lang}
                  name={name}
                  description={description}
                />
              )}
            </>
          )}
        </li>
      ) : null}
    </>
  )
}

export default MenuItem
