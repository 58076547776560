import React, {useState} from 'react';
import './BlogSearch.css';
import Icon from "../../../Sprite/Icon";
import blogKey from "../../../../language/blogKey";

const BlogSearch = ({params, navigate, lang, ...props}) => {

  const [searchQuery, setSearchQuery] = useState('')

  const addSearchQuery = async () => {
    if(searchQuery.length) {
      await params.set("search", searchQuery)
      await params.delete("category");
      await params.delete("page");
      setSearchQuery("")
      navigate("?" + params.toString());
    } else if (!searchQuery.length && params.get("search")) {
      await params.delete("search")
      navigate("?" + params.toString());
    }
  }

  const handleKeyDown = (event) => {
    if(event.keyCode === 13) {
      addSearchQuery()
    }
  }

  return (
   <div className="blog-search">
       <input type="text" value={searchQuery}
              placeholder={blogKey[lang].search}
              className="blog-search__field"
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchQuery(e.target.value)}
       />
       <button className="btn blog-search__btn"
               onClick={addSearchQuery}
       >
         <Icon iconId="search" classes="blog-search__icon"/>
       </button>
   </div>
  )
};

export default BlogSearch;