import React, { useEffect, useState } from "react"
import "./Textarea.css"

const Textarea = ({
  placeholder,
  classes,
  errorText = "",
  value = "",
  label = "",
  onChangeValue,
  isError,
}) => {
  const [activeClass, setActiveClass] = useState("")
  const [error, setError] = useState(isError)

  useEffect(() => {
    setError(isError)
    isError && setActiveClass("")
  }, [isError])

  useEffect(() => {
    if (value.length) {
      setActiveClass(" ui-textarea__field--active")
    } else {
      setActiveClass("")
    }
  }, [value])

  const changeField = e => {
    setError(false)
    onChangeValue(e.target.value)
  }

  return (
    <label className={"ui-textarea " + classes}>
      {label}
      <textarea
        className={
          "ui-textarea__field " +
          (error ? " ui-textarea__field--error" : "") +
          activeClass
        }
        placeholder={placeholder}
        onChange={changeField}
        value={value}
      />
      {error && <span className={"ui-textarea__error-text"}>{errorText}</span>}
    </label>
  )
}

export default Textarea
