import React, { useEffect } from "react"
import { getUserLanguageURL } from "../language/language"
import { graphql, navigate } from "gatsby"

const IndexPage = ({ data }) => {
  let {
    allStrapiLanguage: {
      edges: [
        {
          node: { simple_language },
        },
      ],
    },
  } = data

  useEffect(() => {
    // navigate(getUserLanguageURL(simple_language) + window.location.search, {
     navigate("en" + window.location.search, {
      replace: true,
    })
  })

  return <></>
}
export default IndexPage

export const pageQuery = graphql`
  query {
    allStrapiLanguage {
      edges {
        node {
          simple_language {
            slug
            related_languages
          }
        }
      }
    }
  }
`
