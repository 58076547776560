import React, {useState} from 'react';
import './QuestionForm.css';
import Input from "../../UI/Input/Input";
import Textarea from "../../UI/Textarea/Textarea";
import Button from "../../UI/Button/Button";
import {orderRequests} from "../../../api/websa";
import formKey from "../../../language/formKey";

const QuestionForm = ({body, lang, data, utm}) => {

  const { allSite } = data;

  const {
    edges: [
      {
        node: {
          siteMetadata: { websaToken },
        },
      },
    ],
  } = allSite;

  let [name, setName] = useState("");
  let changeName = (newName) => setName(newName);
  let [lastName, setLastName] = useState("");
  let changeLastName = (newLastName) => setLastName(newLastName);
  let [email, setEmail] = useState("");
  let changeEmail = (newEmail) => setEmail(newEmail);
  let [message, setMessage] = useState("");
  let changeMessage = (newMessage) => setMessage(newMessage);

  let [success, setSuccess] = useState(false);

  let [formError, setFormError] = useState({
    name: false,
    lastName: false,
    email: false,
    message: false,
    check: false,
  });

  const showSuccessMessage = (data) => {
    setSuccess(data);
    setTimeout( () => {
      setSuccess(false)
    }, 3000)
  }


  let submitForm = async () => {
    let validStatus = {
      name: !(name !== ""),
      lastName: !(lastName !== ""),
      email: !(email !== "" && email.indexOf("@") > 0),
      message: !(message !== ""),
    };

    if (
      validStatus.name ||
      validStatus.lastName ||
      validStatus.email ||
      validStatus.message
    ) {
      setFormError(validStatus);
    } else {
      setName("");
      setLastName("");
      setEmail("");
      setMessage("");
      setFormError({
        name: false,
        lastName: false,
        email: false,
        message: false,
        check: false,
      });
      let data = await orderRequests(
        websaToken,
        {
          language: lang,
          name: formKey[lang].service[body.service],
          notes: message,
          service: body.service,
          service_name: formKey[lang].service[body.service],
          user_email: email,
          user_last_name: lastName,
          user_name: name,
        },
        false
      );
      showSuccessMessage(data.success);
    }
  }

  return (
    <div className="question-form">
      <div className="container">
        <h2 className="question-form__title">{body[`title_${lang}`]}</h2>
        <p className="question-form__description">{body[`description_${lang}`]}</p>

        <form action="" onSubmit={(e) => e.preventDefault()}>
          <div className="question-form__input-grid">
            <Input
              label={body[`label_name_${lang}`]}
              errorText={body[`error_name_${lang}`]}
              onChangeValue={changeName}
              value={name}
              isError={formError.name}
              classes="question-form__input"
            />
            <Input
              label={body[`label_last_name_${lang}`]}
              errorText={body[`error_last_name_${lang}`]}
              onChangeValue={changeLastName}
              value={lastName}
              isError={formError.lastName}
              classes="question-form__input"
            />
            <Input
              type="mail"
              label={body[`label_email_${lang}`]}
              errorText={body[`error_email_${lang}`]}
              onChangeValue={changeEmail}
              value={email}
              isError={formError.email}
              classes="question-form__input"
            />
            <Textarea
              label={body[`label_message_${lang}`]}
              placeholder={"Write some text"}
              errorText={body[`error_message_${lang}`]}
              onChangeValue={changeMessage}
              value={message}
              isError={formError.message}
              classes="question-form__textarea"
            />
          </div>
          <div className="question-form__button-area">
            <Button
              text={body[`button_text_${lang}`]}
              classes={"btn--large btn--green"}
              onPress={submitForm}
            />
          </div>
        </form>
      </div>
      {success ?
        <div className="question-form__success">
          <h3 className="question-form__success-title">
            {body[`success_title_${lang}`]}
          </h3>
          <p className="question-form__success-text">
            {body[`success_text_${lang}`]}
          </p>
          <button className="btn btn--red question-form__success-btn" onClick={() => setSuccess(false)}>
            {formKey[lang].close_btn}
          </button>
        </div>
      : null
      }
    </div>
  )
};

export default QuestionForm;