import React, { useEffect, useRef, useState } from "react"
import ContentMenu from "./ContentMenu/ContentMenu"
import "./ContentNav.css"

const ContentNav = ({ content, navlist }) => {
  let [isShow, setIsShow] = useState(false)
  const container = useRef(null)

  useEffect(() => {
    let oldShow = null
    const scrolling = () => {
      let rect = container.current.getBoundingClientRect()
      let show = rect.y + rect.height > 0 && rect.top - window.innerHeight < 0

      if (oldShow !== show) {
        oldShow = show
        setIsShow(show)
      }
    }
    scrolling()
    window.document.addEventListener("scroll", scrolling)
    return () => {
      window.document.removeEventListener("scroll", scrolling)
    }
  }, [container])

  return (
    <>
      <div className="content-nav" ref={container}>
        {content}
      </div>
      {isShow && <ContentMenu navlist={navlist} />}
    </>
  )
}

export default ContentNav
