import React from "react"
import "../../Footer/Footer.css"
import "./MapFooter.css"
import Icon from "../../Sprite/Icon"

const MapFooter = strapiFooter => {
  return (
    <footer className="footer map-footer">
      <div className="footer-bottom container">
        <div className="footer-bottom__left">
          <div className="footer-copyright">
            <span className="footer-copyright__text">
              {strapiFooter.copyright}
            </span>
          </div>
        </div>
        <div className="footer-bottom__right">
          <div className="footer-social">
            <a
              className={"footer-social__link"}
              href={strapiFooter.facebook_link}
              target="_blank"
              rel="noreferrer"
              title="facebook"
            >
              <Icon
                iconId={"facebook"}
                classes={"footer-social__icon"}
                width={30}
                height={30}
              />
            </a>
            <a
              className={"footer-social__link"}
              href={strapiFooter.linkedin_link}
              target="_blank"
              rel="noreferrer"
              title="linkedin"
            >
              <Icon
                iconId={"linkedin"}
                classes={"footer-social__icon"}
                width={30}
                height={30}
              />
            </a>
            <a
              className={"footer-social__link"}
              href={strapiFooter.twitter_link}
              target="_blank"
              rel="noreferrer"
              title="twitter"
            >
              <Icon
                iconId={"twitter"}
                classes={"footer-social__icon"}
                width={30}
                height={30}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default MapFooter
