export const lockScroll = () => {
  let div = document.createElement("div")
  div.style.overflowY = "scroll"
  div.style.width = "50px"
  div.style.height = "50px"
  div.style.visibility = "hidden"
  document.body.appendChild(div)
  let scrollWidth = div.offsetWidth - div.clientWidth
  document.body.removeChild(div)

  document.body.style.overflow = "hidden"
  document.body.style.borderRight = scrollWidth + "px solid #fff"
}

export const unlockScroll = () => {
  document.body.style.overflow = ""
  document.body.style.borderRight = ""
}
