import React from "react"
import GlobalStyle from "../components/GlobalStyle/GlobalStyle"
import Utm from "../components/Utm/Utm"

const NotFoundPage = () => (
  <div>
    <GlobalStyle />
    <Utm />
    <h1>404</h1>
  </div>
)

export default NotFoundPage
