import React, { useEffect, useRef } from "react"
import mapKey from "../../../language/mapKey"
import ReactMarkdown from "react-markdown"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import "./MapModal.css"
import Icon from "../../Sprite/Icon"
import Img from "gatsby-image"

const MapModal = ({
  mapItems,
  lang,
  utm = "",
  changeActivePoint,
  activePoint,
  filter,
  title,
  description,
  toBack,
  ...props
}) => {
  const bar = useRef(null)

  useEffect(() => {
    if (activePoint && activePoint[`description_${lang}`] !== null) {
      bar.current.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }, [activePoint])

  return (
    <div className="map-modal ">
      <SimpleBar
        scrollableNodeProps={{ ref: bar }}
        style={{ maxHeight: "100%" }}
        className="map-modal__scroll-wrapper"
        classNames={{
          content: "simplebar-content",
          scrollContent: "simplebar-scroll-content",
          scrollbar: "simplebar-scrollbar",
          track: "simplebar-track",
        }}
      >
        <div className="map-modal__body">
          <div className="map-modal__head">
            {activePoint === null ||
            activePoint[`description_${lang}`] === null ? (
              <>
                <h2 className="map-modal__title">{title}</h2>
                <div className="map-modal__description">
                  <ReactMarkdown
                    source={description}
                    transformLinkUri={url =>
                      url &&
                      (url.indexOf("?") < 0
                        ? url + utm
                        : url + utm.replace("?", "&"))
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="map-button__title-group">
                  <button
                    onClick={() => toBack()}
                    className="map-modal__back-button"
                  >
                    <Icon iconId="back" classes="map-modal__button-icon" />
                  </button>
                  <h2 className="map-modal__title">
                    {activePoint[`title_${lang}`]}
                  </h2>
                </div>
                {activePoint.photo && (
                  <Img
                    className="map-modal__image"
                    fluid={activePoint.photo.childImageSharp.fluid}
                    alt={activePoint[`title_${lang}`]}
                  />
                )}

                <div className="map-modal__description">
                  <ReactMarkdown
                    source={activePoint[`description_${lang}`]}
                    transformLinkUri={url =>
                      url &&
                      (url.indexOf("?") < 0
                        ? url + utm
                        : url + utm.replace("?", "&"))
                    }
                  />
                </div>
              </>
            )}
          </div>
          <div className="map-modal__points">
            {filter.value ? (
              <div className="map-modal__points-group">
                <h3 className="map-modal__points-title">
                  {mapKey[lang][filter.value]}
                </h3>
                <ul className="map-modal__points-list">
                  {mapItems
                    .filter(item => item.type === filter.value)
                    .map(i => (
                      <li key={i.id} className="map-modal__points-item">
                        {activePoint !== null && activePoint.id === i.id ? (
                          <span
                            className={`map-modal__point map-modal__point--${i.type} map-modal__point--active`}
                          >
                            <b className="map-modal__point-name">
                              {i[`title_${lang}`]}
                            </b>
                            {i[`address_${lang}`]}
                          </span>
                        ) : (
                          <button
                            onClick={() => changeActivePoint(i.id)}
                            className={`map-modal__point map-modal__point--${i.type}`}
                          >
                            <b className="map-modal__point-name">
                              {i[`title_${lang}`]}
                            </b>
                            {i[`address_${lang}`]}
                          </button>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            ) : (
              filter.options.map(i => (
                <div key={i} className="map-modal__points-group">
                  <h3 className="map-modal__points-title">{mapKey[lang][i]}</h3>
                  <ul className="map-modal__points-list">
                    {mapItems
                      .filter(item => item.type === i)
                      .map(i => (
                        <li key={i.id} className="map-modal__point">
                          {activePoint !== null && activePoint.id === i.id ? (
                            <span
                              className={`map-modal__point map-modal__point--${i.type} map-modal__point--active`}
                            >
                              <b className="map-modal__point-name">
                                {i[`title_${lang}`]}
                              </b>
                              {i[`address_${lang}`]}
                            </span>
                          ) : (
                            <button
                              onClick={() => changeActivePoint(i.id)}
                              className={`map-modal__point map-modal__point--${i.type}`}
                            >
                              <b className="map-modal__point-name">
                                {i[`title_${lang}`]}
                              </b>
                              {i[`address_${lang}`]}
                            </button>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              ))
            )}
          </div>
        </div>
      </SimpleBar>
    </div>
  )
}

export default MapModal
